import { FC } from "react";
import { useRouteMatch } from "react-router";
import { useQuery } from "../../../utils";
import { SideStepperProps } from "../../ui/navigation";
import { useWorkflowSnapshot } from "../workflowService";
import { JobboardWorkflowPage } from "./JobboardWorkflowPage";

export const JobboardWorkflow: FC = () => {
  // Routing
  let {
    url,
    params: { workflowId, organisationId },
  } = useRouteMatch<{ workflowId: string; organisationId: string }>();
  const query = useQuery();
  const currentStep = query.get("step") || "job";

  // Workflow
  const { document: workflow, isLoading } =
    useWorkflowSnapshot(organisationId)(workflowId);

  const steps: SideStepperProps["steps"] = [
    {
      name: "Job",
      to: `${url}?step=job`,
      status:
        !isLoading &&
        workflow?.params &&
        (workflow?.params as Shared.JobboardParams).job
          ? (workflow?.params as Shared.JobboardParams).job.status
          : currentStep === "job"
          ? "current"
          : "upcoming",
    },
    {
      name: "Target",
      to: `${url}?step=target`,
      status:
        !isLoading &&
        workflow?.params &&
        (workflow?.params as Shared.JobboardParams).target
          ? (workflow?.params as Shared.JobboardParams).target.status
          : currentStep === "target"
          ? "current"
          : "upcoming",
    },
    {
      name: "Output",
      to: `${url}?step=output`,
      status:
        !isLoading &&
        workflow?.params &&
        (workflow?.params as Shared.JobboardParams).output
          ? (workflow?.params as Shared.JobboardParams).output.status
          : currentStep === "output"
          ? "current"
          : "upcoming",
    },
    {
      name: "Settings",
      to: `${url}?step=settings`,
      status:
        !isLoading &&
        workflow?.params &&
        (workflow?.params as Shared.JobboardParams).settings
          ? (workflow?.params as Shared.JobboardParams).settings.status
          : currentStep === "settings"
          ? "current"
          : "upcoming",
    },
    {
      name: "Ready",
      to: `${url}?step=ready`,
      status:
        !isLoading && workflow?.enabled !== undefined
          ? "complete"
          : currentStep === "ready"
          ? "current"
          : "upcoming",
    },
  ];

  return (
    <JobboardWorkflowPage
      steps={steps}
      workflow={workflow as Shared.IWorkflow<Shared.JobboardParams>}
      isLoading={isLoading}
    />
  );
};
