import { FC } from "react";
import {
  UseFormRegister,
  RegisterOptions,
  DeepMap,
  FieldError,
} from "react-hook-form";
import { CameraIcon } from "@heroicons/react/solid";

type UploadAvatarProps = {
  className?: string;
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
  errors?: DeepMap<File[] | undefined, FieldError>;
  name: string;
  photoUrl?: string;
  selectedFiles?: File[];
};

export const UploadAvatar: FC<UploadAvatarProps> = ({
  className,
  register,
  registerOptions,
  errors,
  name,
  photoUrl,
  selectedFiles,
}) => {
  return (
    <div className={className}>
      <div className="mt-1 flex items-center">
        <span className="inline-block bg-gray-100 rounded-full overflow-hidden h-12 w-12">
          {photoUrl ? (
            <img
              className="inline-block h-12 w-12 rounded-full"
              src={photoUrl}
              alt="User"
            />
          ) : (
            <svg
              className="h-full w-full text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          )}
        </span>
        <input
          id={name}
          accept="image/*"
          type="file"
          {...register(name, registerOptions)}
          className="hidden"
        />
        <label
          className="inline-block relative text-sm font-medium text-gray-700"
          htmlFor={name}
        >
          <CameraIcon className="ml-5 h-8 w-8 text-gray-700 cursor-pointer" />
          {selectedFiles?.length === 1 && (
            <span className="absolute top-0 right-0 block h-1.5 w-1.5 rounded-full ring-2 ring-white bg-primary" />
          )}
        </label>
        {errors && (
          <p className="mt-2 text-xs text-left text-red-600">
            {Object.entries(errors).reduce((combinedError, [key, value]) => {
              return key === "message"
                ? combinedError === ""
                  ? value + ""
                  : combinedError + ", " + value
                : "";
            }, "")}
          </p>
        )}
      </div>
    </div>
  );
};
