import { FC } from "react";
import { Link } from "react-router-dom";

type Variant = "primary" | "secondary" | "white";
type Size = "xs" | "sm" | "base";

type RouterButtonProps = {
  text: string;
  to: string;
  variant?: Variant;
  size?: Size;
  fullwidth?: boolean;
};

const variants: Record<Variant, string> = {
  primary:
    "text-white border border-transparent bg-primary hover:bg-primary-dark focus:ring-primary-light",
  secondary:
    "text-gray-700 border border-transparent bg-secondary hover:bg-secondary-dark focus:ring-secondary-light",
  white:
    "text-gray-700 border border-gray-300 bg-white hover:bg-gray-50 focus:ring-primary-light",
};

const sizes: Record<Size, string> = {
  xs: "px-2.5 py-1.5 text-xs",
  sm: "px-4 py-2 text-sm",
  base: "px-6 py-3 text-base",
};

/**
 * Simple button with an anchor to an url
 * @param text The button text
 * @param url The url to redirect to
 * @param variant The button variant, default to primary
 * @returns
 */
export const RouterButton: FC<RouterButtonProps> = ({
  text,
  to,
  variant = "primary",
  size = "base",
  fullwidth,
}) => (
  <Link
    to={to}
    className={`${
      fullwidth && "w-full justify-center"
    } inline-flex items-center font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
      variants[variant]
    } ${sizes[size]}
    }`}
  >
    {text}
  </Link>
);
