import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { hasAccessFrom } from "../../../utils";
import { Button } from "../../ui/buttons";

export type SubscriptionDetailsProps = {
  currentUserRole: Shared.IUserRoles;
  leads_left?: number;
  subscription?: Shared.IOrganisation["subscription"];
  product?: Shared.IOrganisation["product"];
  pathToUpgradeSubscription: string;
};

export const SubscriptionDetails: FC<SubscriptionDetailsProps> = ({
  currentUserRole,
  leads_left,
  subscription,
  product,
  pathToUpgradeSubscription,
}) => {
  const { t } = useTranslation(["common", "subscription"]);
  if (subscription) {
    return (
      <section aria-labelledby="subscription-details">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2
                id="payment-details-heading"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {t("subscription:subscriptionDetails.title")}
              </h2>
              <p className="max-w-2xl text-sm text-gray-500">
                {t("subscription:subscriptionDetails.description")}
              </p>
            </div>
            <dl className="py-4 divide-y divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Status</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                  {t(
                    `subscription:status.${
                      subscription?.status || "incomplete"
                    }`
                  )}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Plan</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                  {product?.name}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                  {t("subscription:subscriptionDetails.leadsPerPeriod")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                  {product?.leads}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                  {t("subscription:subscriptionDetails.leadsRemaining")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                  {leads_left}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                  {t("subscription:subscriptionDetails.endOfPeriod")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                  {subscription?.current_period_end &&
                    t("subscription:subscriptionDetails.endOfPeriodDate", {
                      date: new Date(subscription?.current_period_end * 1000),
                    })}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                  {t("subscription:subscriptionDetails.maxLinkedin")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                  {product?.max_linkedin}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                  {t("subscription:subscriptionDetails.LimitedToOneWorkflow")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                  <FeatureEnableIcon
                    enabled={product?.limited_to_one_workflow}
                  />
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                  {t("subscription:subscriptionDetails.PremiumWorkflow")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                  <FeatureEnableIcon enabled={product?.premium_workflow} />
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                  Versioning
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                  <FeatureEnableIcon enabled={product?.versioning} />
                </dd>
              </div>
            </dl>
          </div>
          {hasAccessFrom(currentUserRole, "ADMIN") && (
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <Link
                to={{
                  pathname: pathToUpgradeSubscription,
                  state: { modal: true },
                }}
                children={
                  <Button
                    type="button"
                    variant="textPrimary"
                    size="xs"
                    children={t("subscription:subscriptionDetails.changePlan")}
                  />
                }
              />
            </div>
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section aria-labelledby="subscription-details">
        <Link
          to={{
            pathname: pathToUpgradeSubscription,
            state: { modal: true },
          }}
          children={
            <Button
              type="button"
              children={t("subscription:subscriptionDetails.choosePlan")}
            />
          }
        />
      </section>
    );
  }
};

const FeatureEnableIcon: FC<{ enabled?: boolean }> = ({ enabled }) =>
  enabled ? (
    <CheckCircleIcon className="text-green-500 h-5" />
  ) : (
    <XCircleIcon className="text-red-500 h-5" />
  );
