import { FC } from "react";
import { UseFormRegister, RegisterOptions } from "react-hook-form";

export type CheckboxProps = {
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
  error?: string;
  label: string;
  name: string;
  value?: string;
  required?: boolean;
  defaultChecked?: boolean;
  className?: string;
};

export const Checkbox: FC<CheckboxProps> = ({
  register,
  registerOptions,
  error,
  label,
  name,
  value,
  required = false,
  defaultChecked = false,
  className,
}) => (
  <div className={`flex items-center ${className}`}>
    <input
      defaultChecked={defaultChecked}
      id={name}
      value={value}
      {...register(name, registerOptions)}
      type="checkbox"
      className="h-4 w-4 rounded text-primary focus:ring-primary-light border-gray-300"
    />
    <label
      htmlFor={name}
      className={`ml-2 block text-xs ${
        error ? "text-red-600" : "text-gray-600"
      }`}
    >
      {label}
    </label>
  </div>
);
