import { FirestoreService } from "../firebase";
import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "@firebase/firestore";
import {
  createAddlHook,
  createGetHook,
  createListHook,
  createPagindatedListHook,
  createSnapshotDocumentHook,
  createSnapshotHook,
  createSoftDeletelHook,
  createUpdateHook,
} from "../firebase/firestoreService";
import { removeUndefined } from "../../utils";

export class LeadService extends FirestoreService<Shared.ILead> {
  constructor(public path: string) {
    super("leads", path);
  }

  getModelConverter() {
    const serverTimestamp = this.serverTimestamp;
    return {
      toFirestore(data: Shared.ILead): DocumentData {
        const cleanData = removeUndefined({
          companyData: data.companyData,
          contacts: data.contacts,
          events: data.events,
        });
        cleanData.createdAt = serverTimestamp;
        return cleanData;
      },
      fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
      ): Shared.ILead {
        const { createdAt, updatedAt, companyData, contacts, events } =
          snapshot.data(options);
        return {
          id: snapshot.id,
          createdAt,
          updatedAt,
          companyData,
          contacts,
          events,
        };
      },
    };
  }
}

export const useLeadsSnapshot = (organisationId: string) =>
  createSnapshotHook(new LeadService(`organisations/${organisationId}`));

export const useLeadSnapshot = (organisationId: string) =>
  createSnapshotDocumentHook(
    new LeadService(`organisations/${organisationId}`)
  );

export const useGetLead = (organisationId: string) =>
  createGetHook(new LeadService(`organisations/${organisationId}`));

export const useListLeads = (organisationId: string) =>
  createListHook(new LeadService(`organisations/${organisationId}`));

export const useAddLead = (organisationId: string) =>
  createAddlHook(new LeadService(`organisations/${organisationId}`));

export const useUpdateLead = (organisationId: string) =>
  createUpdateHook(new LeadService(`organisations/${organisationId}`));

export const useSoftDeleteLead = (organisationId: string) =>
  createSoftDeletelHook(new LeadService(`organisations/${organisationId}`));

export const usePaginatedList = (organisationId: string) =>
  createPagindatedListHook(new LeadService(`organisations/${organisationId}`));
