import { FC, useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import {
  classNames,
  sendUserInfoToExtension,
  sendIntegrationInfoToExtension,
} from "../../utils";
import { UrlButton } from "../ui/links";
import { Button } from "../ui/buttons";
import { Alert, AlertProps } from "../ui/alerts";
import { useAuth } from "../authentification";
import { useCurrentOrganisation } from "../organisation/organisationService";
import { Modal } from "../ui/overlay";
import { useTranslation } from "react-i18next";

export const LinkedinAddIntegrationModal: FC<{
  show: boolean;
  onClose: () => void;
}> = ({ show, onClose }) => {
  const { t } = useTranslation(["common", "integration"]);

  const {
    authState: { user, idToken },
  } = useAuth();

  const { addLinkedinIntegration } = useCurrentOrganisation();

  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    t("integration:linkeindModal.steps.install.name"),
    t("integration:linkeindModal.steps.sync.name"),
    t("integration:linkeindModal.steps.done.name"),
  ];
  const [alert, setAlert] = useState<AlertProps>();
  const [isLoading, setIsLoading] = useState(true);

  const getStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <p className="py-4">
              {t("integration:linkeindModal.steps.install.description")}
            </p>
            {alert && (
              <Alert
                variant={alert.variant}
                title={alert.title}
                body={alert.body}
              />
            )}
            <div className="mt-5 sm:mt-6 flex gap-6 justify-center">
              <UrlButton
                text={t("integration:linkeindModal.steps.install.link")}
                url="https://chrome.google.com/webstore/detail/coolsalesio/iibkldildillmodahjigghaimfbmikkk"
                variant="primary"
              />
              <Button
                type="button"
                onClick={() => checkExtensionInstallation(true)}
                variant="white"
                children={t("integration:linkeindModal.steps.install.action")}
                isLoading={isLoading}
              />
            </div>
          </>
        );

      case 1:
        return (
          <>
            <p className="py-4">
              {t("integration:linkeindModal.steps.sync.description")}
            </p>
            {alert && (
              <Alert
                variant={alert.variant}
                title={alert.title}
                body={alert.body}
              />
            )}
            <div className="mt-5 sm:mt-6 flex gap-6 justify-center">
              <Button
                type="button"
                onClick={() => synchronizeExtension()}
                variant="primary"
                children={t("integration:linkeindModal.steps.sync.action")}
                isLoading={isLoading}
              />
            </div>
          </>
        );

      case 2:
      default:
        return (
          <>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <CheckIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            </div>
            <p className="py-4">
              {t("integration:linkeindModal.steps.done.description")}
            </p>
            <div className="mt-5 sm:mt-6 flex gap-6 justify-center">
              <Button
                type="button"
                onClick={onClose}
                variant="primary"
                children={t("integration:linkeindModal.steps.done.action")}
              />
            </div>
          </>
        );
    }
  };

  const checkExtensionInstallation = (showAlert: boolean) => {
    if (idToken) {
      setIsLoading(true);
      setTimeout(
        () =>
          sendUserInfoToExtension(
            idToken,
            () => {
              setAlert(undefined);
              setCurrentStep(1);
              setIsLoading(false);
            },
            (error) => {
              setIsLoading(false);
              switch (error) {
                case "NOT_ON_CHROME":
                  showAlert &&
                    setAlert({
                      variant: "Warning",
                      title: "Only on Chrome",
                      body: "Sorry but the extension is available only on Chrome browser for now",
                    });
                  break;

                case "EXTENSION_NOT_FOUND":
                case "NO_EXTENSION_INSTALLED":
                  showAlert &&
                    setAlert({
                      variant: "Warning",
                      title: "Extension was not detected",
                      body: "Please verify the extension is installed",
                    });
                  break;

                default:
                  setAlert({
                    variant: "Warning",
                    title: "An error occured",
                    body: "Please try again later or contact our support team",
                  });
                  break;
              }
            }
          ),
        1500
      );
    } else {
      setAlert({
        variant: "Error",
        title: "We cannot authenticate you",
        body: "Please logout and login to try again",
      });
    }
  };

  const synchronizeExtension = () => {
    setIsLoading(true);
    if (addLinkedinIntegration && user && user.uid) {
      setTimeout(
        () =>
          sendIntegrationInfoToExtension(
            user.uid,
            ".www.linkedin.com",
            ["li_at", "li_a"],
            (payload) => {
              setIsLoading(false);
              setAlert(undefined);
              if (payload.success && payload.success.li_at) {
                addLinkedinIntegration(
                  payload.success.li_at,
                  payload.success.li_a
                );
              }
              setCurrentStep(2);
            },
            (error) => {
              setIsLoading(false);
              switch (error) {
                case "COOKIE_NOT_FOUND":
                  setAlert({
                    variant: "Warning",
                    title: "LinkedIn session not found",
                    body: "Please verify that your are connected to LinkedIn",
                  });
                  break;

                default:
                  setAlert({
                    variant: "Warning",
                    title: "An error occured",
                    body: "Please try again later or contact our support team",
                  });
                  break;
              }
            }
          ),
        800
      );
    } else {
      setAlert({
        variant: "Error",
        title: "Organisation settings inaccessible",
        body: "We cannot sync a new integration right now, please try again later",
      });
    }
  };

  useEffect(() => {
    checkExtensionInstallation(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={t("integration:linkeindModal.title")}
    >
      <div className="mt-2">
        <ol className="divide-y divide-gray-300 md:flex md:divide-y-0">
          {steps.map((step, index) => {
            return (
              <li key={index} className="group flex items-center w-full">
                {index < currentStep ? (
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary rounded-full group-hover:bg-primary-dark">
                    <CheckIcon
                      className="w-6 h-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                ) : index === currentStep ? (
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary rounded-full">
                    <span className="text-primary">{index + 1}</span>
                  </span>
                ) : (
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                    <span className="text-gray-500">{index + 1}</span>
                  </span>
                )}

                <span
                  className={classNames(
                    "ml-1 text-sm font-medium",
                    index === currentStep ? "text-primary" : "text-gray-400"
                  )}
                >
                  {step}
                </span>
              </li>
            );
          })}
        </ol>
        <div className="w-9/12 mx-auto">{getStep(currentStep)}</div>
      </div>
    </Modal>
  );
};
