import { FC } from "react";
import {
  RegisterOptions,
  useFieldArray,
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormTrigger,
  UseFormSetFocus,
} from "react-hook-form";
import { classNames } from "../../../utils";

type TextArrayFieldProps = {
  className?: string;
  register: UseFormRegister<any>;
  control: Control<any, object>;
  registerOptions?: RegisterOptions;
  trigger?: UseFormTrigger<any>;
  setFocus?: UseFormSetFocus<any>;
  name: string;
  label: string;
  placeholder?: string;
  errors?: FieldErrors<
    {
      value: string;
    }[]
  >;
  type: "text" | "email" | "password" | "number";
};

export const TextArrayField: FC<TextArrayFieldProps> = ({
  className,
  register,
  control,
  trigger,
  setFocus,
  registerOptions,
  errors,
  label,
  placeholder,
  name,
  type,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (
    e
  ) => {
    const {
      key,
      currentTarget: { value },
    } = e;

    if (key === "," || key === "Enter") {
      e.preventDefault();
      if (value.length > 0) {
        if (trigger) {
          const hasNoError = await trigger(name);
          if (hasNoError) {
            append({ value: "" });
            if (setFocus) {
              setFocus(`${name}.${fields.length}.value`);
            }
          }
        } else {
          append({ value: "" });
        }
      }
    }

    if (key === "Backspace" && fields.length > 1 && value.length === 0) {
      remove(fields.length - 1);

      if (setFocus) {
        // Timeout for the DOM to update
        setTimeout(() => setFocus(`${name}.${fields.length - 2}.value`), 100);
      }
    }
  };

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm text-left font-medium text-gray-700"
      >
        {label}
        {registerOptions && registerOptions.required && " *"}
      </label>
      <div
        className={classNames(
          "mt-1 flex flex-row flex-wrap gap-2 items-center p-2 rounded-md shadow-sm border focus-within:ring-1",
          errors
            ? "border-red-300 focus-within:ring-red-500 focus-within:border-red-500"
            : "border-gray-300 focus-within:ring-primary-light focus-within:border-primary-light"
        )}
      >
        {fields.map((field, index) => {
          if (fields.length > 1 && index < fields.length - 1) {
            return (
              <BadgeField
                key={field.id}
                text={(field as unknown as { value: string }).value}
                remove={() => remove(index)}
              />
            );
          }
          return (
            <div key={field.id} className="flex flex-1">
              <input
                className={classNames(
                  "appearance-none flex-1 p-0 rounded-md border-none focus:border-none focus:ring-0",
                  errors
                    ? " text-red-900 placeholder-red-300"
                    : " placeholder-gray-400"
                )}
                {...register(`${name}.${index}.value`, registerOptions)}
                placeholder={placeholder}
                type={type}
                onWheel={(e) => e.currentTarget.blur()}
                onKeyDown={handleKeyDown}
              />
            </div>
          );
        })}
      </div>
      {errors && (
        <p className="mt-2 text-sm text-left text-red-600">
          {errors.find((error) => error?.value?.message)?.value?.message}
        </p>
      )}
    </div>
  );
};

const BadgeField: FC<{ text: string; remove: () => void }> = ({
  text,
  remove,
}) => (
  <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded text-xs font-medium bg-indigo-100 text-primary">
    {text}
    <button
      type="button"
      className="flex-shrink-0 ml-0.5 h-4 w-4 rounded inline-flex items-center justify-center text-primary-light hover:bg-primary-light hover:text-primary focus:outline-none focus:bg-primary focus:text-white"
      onClick={remove}
    >
      <span className="sr-only">Remove</span>
      <svg
        className="h-2 w-2"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 8 8"
      >
        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
    </button>
  </span>
);
