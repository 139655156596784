import { FirestoreService } from "../firebase";
import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "@firebase/firestore";
import { createServiceHooks } from "../firebase/firestoreService";
import { useAuth } from "../authentification";

export default class InvitationService extends FirestoreService<Shared.IInvitation> {
  constructor(public path?: string) {
    super("invitations", path);
  }

  getModelConverter() {
    const serverTimestamp = this.serverTimestamp;
    return {
      toFirestore(data: Shared.IInvitation): DocumentData {
        return {
          createdAt: serverTimestamp,
          status: data.status,
          organisationId: data.organisationId,
          organisationName: data.organisationName,
          userEmail: data.userEmail,
          userRole: data.userRole,
        };
      },
      fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
      ): Shared.IInvitation {
        const data = snapshot.data(options);
        return {
          id: snapshot.id,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          status: data.status,
          errorReason: data.errorReason,
          organisationId: data.organisationId,
          organisationName: data.organisationName,
          userEmail: data.userEmail,
          userRole: data.userRole,
          acceptedBy: data.acceptedBy,
        };
      },
    };
  }
}

export const {
  snapshot: useInvitationsSnapshot,
  snapshotDocument: useInvitationSnapshot,
  get: useGetInvitation,
  list: useListInvitations,
  add: useAddInvitation,
  update: useUpdateInvitation,
  softDelete: useSoftDeleteInvitation,
} = createServiceHooks(new InvitationService());

export const useCurrentOrganisationPendingInvitations = () => {
  const { authState } = useAuth();
  const { data: pendingInvitations, isLoading: isLoadingPendingInvitation } =
    useListInvitations({
      filters: [
        {
          field: "organisationId",
          operator: "==",
          value: authState.currentOrganisationId,
        },
        {
          field: "status",
          operator: "==",
          value: "PENDING",
        },
      ],
    });

  return { isLoadingPendingInvitation, pendingInvitations };
};

export const useCurrentUserPendingAndAcceptedInvitations = () => {
  const { authState } = useAuth();
  const { data: pendingInvitations, isLoading: isLoadingPendingInvitation } =
    useListInvitations({
      filters: [
        {
          field: "userEmail",
          operator: "==",
          value: authState.user?.email,
        },
        {
          field: "status",
          operator: "in",
          value: ["PENDING", "ACCEPTED"],
        },
      ],
    });

  return { isLoadingPendingInvitation, pendingInvitations };
};
