import { FC, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { classNames } from "../../../utils";

export type CustomSelectOption = {
  id: string | number;
  value: string | number;
};

type CustomSelectProps = {
  className?: string;
  position?: "above" | "below";
  label?: string;
  options: CustomSelectOption[];
  selected?: CustomSelectOption;
  onChange: (option: CustomSelectOption) => void;
  fullwidth?: boolean;
  error?: string;
};

export const CustomSelect: FC<CustomSelectProps> = ({
  className,
  position = "below",
  label,
  options,
  selected,
  onChange,
  fullwidth = false,
  error,
}) => {
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }: { open: boolean }) => (
        <>
          <div className={classNames("relative", className || "")}>
            {label && (
              <Listbox.Label className="block text-sm font-medium text-gray-700">
                {label}
              </Listbox.Label>
            )}
            <Listbox.Button
              className={classNames(
                "bg-white relative",
                label ? "mt-1" : "",
                fullwidth ? "w-full" : "",
                "border border-gray-300 rounded-md shadow-sm pl-3 pr-10 pt-2 pb-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-light focus:border-primary-light sm:text-sm"
              )}
            >
              {selected ? (
                <span className="block truncate">{selected.value}</span>
              ) : (
                // prettier-ignore
                <span className="block truncate" dangerouslySetInnerHTML={ {__html:"&nbsp;"}} />
              )}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            {error && (
              <p className="mt-2 text-sm text-left text-red-600">{error}</p>
            )}

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  "absolute z-10",
                  position === "above" ? "mb-1 bottom-full" : "mt-1",
                  fullwidth ? "w-full" : "",
                  "bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                )}
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "text-white bg-primary-light"
                          : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ active }) => {
                      const isSelected = selected?.id === option.id;
                      return (
                        <>
                          <span
                            className={classNames(
                              isSelected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option.value}
                          </span>

                          {isSelected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-primary",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
