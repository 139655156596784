import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../ui/overlay";
import linkedinLogo from "./linkedin-logo.png";
import lemlistLogo from "./lemlist-logo.png";
import datananasLogo from "./datananas-logo.png";
import lgmLogo from "./lgm-logo.png";
import emeliaLogo from "./emelia-logo.png";

export const ChooseIntegrationModal: FC<{
  show: boolean;
  onClose: () => void;
  onAddLinkedIn: () => void;
  onLemlist: () => void;
  onDatananas: () => void;
  onLgm: () => void;
  onEmelia: () => void;
}> = ({
  show,
  onClose,
  onAddLinkedIn,
  onLemlist,
  onDatananas,
  onLgm,
  onEmelia,
}) => {
  const { t } = useTranslation(["common", "integration"]);
  return (
    <Modal show={show} onClose={onClose} title="Add new integration">
      <div className="mt-2">
        <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2">
          <li
            key="linkedin"
            className="col-span-1 flex shadow-sm rounded-md"
            onClick={onAddLinkedIn}
          >
            <img
              className="lex-shrink-0 flex items-center justify-center w-16 rounded-l-md"
              src={linkedinLogo}
              alt="linkedin logo"
            />
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <p className="text-gray-900 font-medium hover:text-gray-600">
                  LinkedIn
                </p>
                <p className="text-gray-500 text-xs">
                  {t("integration:integrationList.linkedinDescription")}
                </p>
              </div>
            </div>
          </li>
          <li
            key="lemlist"
            className="col-span-1 flex shadow-sm rounded-md cursor-pointer"
            onClick={onLemlist}
          >
            <img
              className="lex-shrink-0 flex items-center justify-center w-16 rounded-l-md"
              src={lemlistLogo}
              alt="lemlist logo"
            />
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <p className="text-gray-900 font-medium hover:text-gray-600">
                  Lemlist
                </p>
                <p className="text-gray-500 text-xs">
                  {t("integration:integrationList.lemlistDescription")}
                </p>
              </div>
            </div>
          </li>
          <li
            key="datananas"
            className="col-span-1 flex shadow-sm rounded-md cursor-pointer"
            onClick={onDatananas}
          >
            <img
              className="lex-shrink-0 flex items-center justify-center w-16 rounded-l-md"
              src={datananasLogo}
              alt="datananas logo"
            />
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <p className="text-gray-900 font-medium hover:text-gray-600">
                  Datananas
                </p>
                <p className="text-gray-500 text-xs">
                  {t("integration:integrationList.datananasDescription")}
                </p>
              </div>
            </div>
          </li>
          <li
            key="lagrowthmachine"
            className="col-span-1 flex shadow-sm rounded-md cursor-pointer"
            onClick={onLgm}
          >
            <img
              className="lex-shrink-0 flex items-center justify-center w-16 rounded-l-md"
              src={lgmLogo}
              alt="lagrowthmachine logo"
            />
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <p className="text-gray-900 font-medium hover:text-gray-600">
                  La Growth Machine
                </p>
                <p className="text-gray-500 text-xs">
                  {t("integration:integrationList.lgmDescription")}
                </p>
              </div>
            </div>
          </li>
          <li
            key="emelia"
            className="col-span-1 flex shadow-sm rounded-md cursor-pointer"
            onClick={onEmelia}
          >
            <img
              className="lex-shrink-0 flex items-center justify-center w-16 rounded-l-md"
              src={emeliaLogo}
              alt="emalia logo"
            />
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <p className="text-gray-900 font-medium hover:text-gray-600">
                  Emelia
                </p>
                <p className="text-gray-500 text-xs">
                  {t("integration:integrationList.emeliaDescription")}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Modal>
  );
};
