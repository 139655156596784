import { FC } from "react";
import { useForm, RegisterOptions } from "react-hook-form";
import { SignUpPage } from "./components/SignUpPage";
import { useAuth } from "./AuthProvider";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { AuthErrorCodes } from "@firebase/auth";

export type SignupFormData = {
  email: string;
  password: string;
  confirmationPassword: string;
};

export const SignUp: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm<SignupFormData>();

  const { t } = useTranslation("authentification");

  const { authState, createUserWithEmailAndPassword, signInWithGoogle } =
    useAuth();

  const passwordValue = watch("password");

  const registerOptions: Record<keyof SignupFormData, RegisterOptions> = {
    email: {
      required: t("fromValidation.emailRequired"),
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: t("fromValidation.emailRequired"),
      },
    },
    password: { required: t("fromValidation.passwordRequired") },
    confirmationPassword: {
      required: t("fromValidation.confirmationPasswordRequired"),
      validate: (value) =>
        value === passwordValue ||
        t("fromValidation.confirmationPasswordDifferent"),
    },
  };

  const onSubmit = handleSubmit((data) => {
    createUserWithEmailAndPassword(data.email, data.password).catch((e) => {
      switch (e.message) {
        case AuthErrorCodes.EMAIL_EXISTS:
          setError("email", {
            type: "manual",
            message: t("errors.auth/EMAIL_EXISTS"),
          });
          break;

        case AuthErrorCodes.WEAK_PASSWORD:
          setError("password", {
            type: "manual",
            message: t("errors.auth/WEAK_PASSWORD"),
          });
          break;

        default:
          setError("email", {
            type: "manual",
            message: t("errors.unknown"),
          });
      }
    });
  });

  if (
    authState.status === "AUTHENTICATED" &&
    authState.token?.claims.favOrganisationId
  ) {
    return (
      <Redirect
        to={`/${authState.token?.claims.favOrganisationId}/dashboard`}
      />
    );
  } else if (authState.status === "AUTHENTICATED") {
    return <Redirect to={"/"} />;
  } else {
    return (
      <SignUpPage
        register={register}
        registerOptions={registerOptions}
        onSubmit={onSubmit}
        errors={errors}
        isLoading={authState.status === "AUTHENTICATION_LOADING"}
        signInWithGoogle={signInWithGoogle}
      />
    );
  }
};
