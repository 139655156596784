import { FirestoreService, logAnalyticsEvent } from "../firebase";
import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  deleteField,
} from "@firebase/firestore";
import {
  createListHook,
  createServiceHooks,
  createSnapshotDocumentHook,
  createUpdateHook,
} from "../firebase/firestoreService";
import { removeUndefined } from "../../utils";
import { useAuth } from "../authentification";
import { useAddInvitation } from "../invitation";

export class OrganisationService extends FirestoreService<Shared.IOrganisation> {
  constructor(public path?: string) {
    super("organisations", path);
  }

  getModelConverter() {
    const serverTimestamp = this.serverTimestamp;
    return {
      toFirestore(data: Shared.IOrganisation): DocumentData {
        const cleanData = removeUndefined({
          name: data.name,
          phone: data.phone,
          domain: data.domain,
          users: data.users,
          companySize: data.companySize,
        });
        cleanData.createdAt = serverTimestamp;
        return cleanData;
      },
      fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
      ): Shared.IOrganisation {
        const {
          createdAt,
          updatedAt,
          name,
          domain,
          phone,
          users,
          freeTrialEndOn,
          stripeId,
          subscription,
          leads_left,
          product,
          companySize,
          linkedinIntegrations,
          lemlistIntegrations,
          datananasIntegrations,
          emeliaIntegrations,
          lgmIntegrations,
        } = snapshot.data(options);
        return {
          id: snapshot.id,
          createdAt,
          updatedAt,
          name,
          domain,
          phone,
          freeTrialEndOn,
          users,
          stripeId,
          subscription,
          leads_left,
          product,
          companySize,
          linkedinIntegrations,
          lemlistIntegrations,
          datananasIntegrations,
          emeliaIntegrations,
          lgmIntegrations,
        };
      },
    };
  }
}

export const {
  snapshot: useOrganisationsSnapshot,
  snapshotDocument: useOrganisationSnapshot,
  get: useGetOrganisation,
  list: useListOrganisations,
  add: useAddOrganisation,
  update: useUpdateOrganisation,
  softDelete: useSoftDeleteOrganisation,
} = createServiceHooks(new OrganisationService());

export const useUserCurrentOrganisations = () => {
  const { authState } = useAuth();
  const organisationService = new OrganisationService();

  const { isLoading: isLoadingUserOrganisations, data: userOrganisations } =
    createListHook(organisationService)({
      filters: [
        {
          field: `users.${authState.user?.uid}`,
          operator: "!=",
          value: null,
        },
      ],
    });

  return { isLoadingUserOrganisations, userOrganisations };
};

export const useCurrentOrganisation = () => {
  const { authState } = useAuth();
  const { add: addInvitation } = useAddInvitation();
  const organisationService = new OrganisationService();

  if (authState.currentOrganisationId) {
    const organisationId = authState.currentOrganisationId;

    // Fetch and serve current organisation data as a snapshot
    const {
      isLoading: isLoadingCurrentOrganisation,
      document: currentOrganisation,
    } = createSnapshotDocumentHook(organisationService)(organisationId);

    const { isLoading: isLoadingUpdate, update } =
      createUpdateHook(organisationService)();

    /**
     * Edit the role of a user
     * @param userId The user id
     * @param role the new role
     */
    const editUserRole = (userId: string, role: Shared.IUserRoles) =>
      update(organisationId, {
        [`users.${userId}.role`]: role,
      }).then(() => logAnalyticsEvent("update_organisation"));

    /**
     * Add an invitation for a user to the current organisation
     * @param userEmail The user email
     * @param userRole The user role in the organisation
     */
    const inviteUser = (userEmail: string, userRole: Shared.IUserRoles) =>
      addInvitation({
        status: "NEW",
        organisationId: currentOrganisation?.id,
        organisationName: currentOrganisation?.name,
        organisationPrefferedLanguage: currentOrganisation?.prefferedLanguage,
        userEmail,
        userRole,
      }).then(() => logAnalyticsEvent("send_invitation"));

    /**
     * Add a LinkedIn integration on the current organisation
     * The current user Id is used as the integration Id because
     * the Chromes Extension will update the integration with the user token
     * @param li_at The LinkedIn cookie send back by the extension
     */
    const addLinkedinIntegration = (li_at: string, li_a?: string) => {
      update(organisationId, {
        [`linkedinIntegrations.${authState.user?.uid}`]: {
          createdBy: authState.user?.displayName,
          enabled: true,
          cookies: {
            li_at,
            li_a,
          },
          limits: {
            genericLimit: 3000,
            maxVisitPerDayClassic: 80,
            maxVisitPerDaySalesNavigator: 800,
          },
        },
      }).then(() => logAnalyticsEvent("add_integration"));
    };

    /**
     * Update a LinkedIn integration limits
     * @param linkedinIntegrationId The LinkedIn integration Id, not necessaraly the current user id
     * @param limits The new limits
     */
    const updateLinkedinIntegrationQuota = (
      linkedinIntegrationId: string,
      limits: {
        genericLimit: number;
        maxVisitPerDayClassic: number;
        maxVisitPerDaySalesNavigator: number;
      }
    ) => {
      update(organisationId, {
        [`linkedinIntegrations.${linkedinIntegrationId}.limits`]: limits,
      });
    };

    /**
     * Toggle enabled for a given linkedin integration
     * @param integrationId The integration id
     * @param enabled the status for enabled
     * @returns
     */
    const toggleLinkedinIntegration = (
      integrationId: string,
      enabled: boolean
    ) =>
      update(organisationId, {
        [`linkedinIntegrations.${integrationId}.enabled`]: enabled,
      });

    /**
     * Delete a given linkedin integration
     * @param integrationId The integraiton id
     * @returns
     */
    const deleteLinkedinIntegration = (integrationId: string) =>
      update(organisationId, {
        [`linkedinIntegrations.${integrationId}`]: deleteField(),
      });

    /**
     * Add a Lemlist integration to the current organisation
     * The campaign id is used as a map key to ensure it will be added only once
     * @param apiKey
     */
    const addLemlistIntegration = (
      apiKey: string,
      campaignId: string,
      campaignName: string
    ) => {
      update(organisationId, {
        [`lemlistIntegrations.${campaignId}`]: {
          createdBy: authState.user?.displayName,
          enabled: true,
          apiKey,
          campaignId,
          campaignName,
        },
      }).then(() => logAnalyticsEvent("add_integration"));
    };

    /**
     * Toggle enabled for a given Lemlist integration
     * @param integrationId The integration id
     * @param enabled the status for enabled
     * @returns
     */
    const toggleLemlistIntegration = (
      integrationId: string,
      enabled: boolean
    ) => {
      update(organisationId, {
        [`lemlistIntegrations.${integrationId}.enabled`]: enabled,
      });
    };

    /**
     * Delete a given Lemlist integration
     * @param integrationId The integraiton id
     * @returns
     */
    const deleteLemlistIntegration = (integrationId: string) =>
      update(organisationId, {
        [`lemlistIntegrations.${integrationId}`]: deleteField(),
      });

    /**
     * Add a Datananas integration to the current organisation
     * The sequenceID is used as a map key to ensure it will be added only once
     * @param apiKey
     */
    const addDatananasIntegration = (
      apiToken: string,
      sequenceId: string,
      sequenceName: string,
      senderId: string
    ) => {
      update(organisationId, {
        [`datananasIntegrations.${sequenceId}`]: {
          createdBy: authState.user?.displayName,
          enabled: true,
          apiToken,
          sequenceId,
          sequenceName,
          senderId,
        },
      }).then(() => logAnalyticsEvent("add_integration"));
    };

    /**
     * Toggle enabled for a given Datananas integration
     * @param integrationId The integration id
     * @param enabled the status for enabled
     * @returns
     */
    const toggleDatananasIntegration = (
      integrationId: string,
      enabled: boolean
    ) => {
      update(organisationId, {
        [`datananasIntegrations.${integrationId}.enabled`]: enabled,
      });
    };

    /**
     * Delete a given Datananas integration
     * @param integrationId The integraiton id
     * @returns
     */
    const deleteDatananasIntegration = (integrationId: string) =>
      update(organisationId, {
        [`datananasIntegrations.${integrationId}`]: deleteField(),
      });

    /**
     * Add a LGM integration to the current organisation
     * The sequenceID is used as a map key to ensure it will be added only once
     * @param apiKey
     */
    const addLGMIntegration = (apiKey: string, name: string) => {
      update(organisationId, {
        [`lgmIntegrations.${apiKey}`]: {
          createdBy: authState.user?.displayName,
          enabled: true,
          apiKey,
          name,
        },
      }).then(() => logAnalyticsEvent("add_integration"));
    };

    /**
     * Toggle enabled for a given LGM integration
     * @param integrationId The integration id
     * @param enabled the status for enabled
     * @returns
     */
    const toggleLGMIntegration = (integrationId: string, enabled: boolean) => {
      update(organisationId, {
        [`lgmIntegrations.${integrationId}.enabled`]: enabled,
      });
    };

    /**
     * Delete a given LGM integration
     * @param integrationId The integraiton id
     * @returns
     */
    const deleteLGMIntegration = (integrationId: string) =>
      update(organisationId, {
        [`lgmIntegrations.${integrationId}`]: deleteField(),
      });

    /**
     * Add a Emelia integration to the current organisation
     * The campaign id is used as a map key to ensure it will be added only once
     * @param apiKey
     */
    const addEmeliaIntegration = (
      apiKey: string,
      campaignId: string,
      campaignName: string
    ) => {
      update(organisationId, {
        [`emeliaIntegrations.${campaignId}`]: {
          createdBy: authState.user?.displayName,
          enabled: true,
          apiKey,
          campaignId,
          campaignName,
        },
      }).then(() => logAnalyticsEvent("add_integration"));
    };

    /**
     * Toggle enabled for a given Emelia integration
     * @param integrationId The integration id
     * @param enabled the status for enabled
     * @returns
     */
    const toggleEmeliaIntegration = (
      integrationId: string,
      enabled: boolean
    ) => {
      update(organisationId, {
        [`emeliaIntegrations.${integrationId}.enabled`]: enabled,
      });
    };

    /**
     * Delete a given Emelia integration
     * @param integrationId The integraiton id
     * @returns
     */
    const deleteEmeliaIntegration = (integrationId: string) =>
      update(organisationId, {
        [`emeliaIntegrations.${integrationId}`]: deleteField(),
      });

    return {
      isLoadingCurrentOrganisation,
      isLoadingUpdate,
      currentOrganisation,
      editUserRole,
      inviteUser,
      addLinkedinIntegration,
      toggleLinkedinIntegration,
      updateLinkedinIntegrationQuota,
      deleteLinkedinIntegration,
      addLemlistIntegration,
      toggleLemlistIntegration,
      deleteLemlistIntegration,
      addDatananasIntegration,
      toggleDatananasIntegration,
      deleteDatananasIntegration,
      addLGMIntegration,
      toggleLGMIntegration,
      deleteLGMIntegration,
      addEmeliaIntegration,
      toggleEmeliaIntegration,
      deleteEmeliaIntegration,
    };
  } else {
    return { isLoadingCurrentOrganisation: true };
  }
};
