import { LockClosedIcon } from "@heroicons/react/solid";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuth } from ".";
import { useOverlay } from "../overlay";
import { Button } from "../ui/buttons";
import { GoogleIcon, Spinner } from "../ui/icons";

export const UserAuthDetails: FC<{ pathToChangePasswordModal: string }> = ({
  pathToChangePasswordModal,
}) => {
  const { t } = useTranslation(["common", "authentification"]);

  const { pushNotification } = useOverlay();

  const { authState } = useAuth();

  const getProviderData = (provider: "password" | "google.com") => {
    return authState.user?.providerData.find(
      (userInfo) => userInfo?.providerId === provider
    );
  };

  const passwordProvider = getProviderData("password");
  const googleProvider = getProviderData("google.com");

  const handleLinkGoogleAccount = () => {
    pushNotification({
      severity: "info",
      title: "Coming soon",
      body: "Sorry, linking Google account is not available yet",
    });
  };

  const handleCreatePasswordAccount = () => {
    pushNotification({
      severity: "info",
      title: "Coming soon",
      body: "Sorry, linking a Email and Password account is not available yet",
    });
  };

  return (
    <section aria-labelledby="user-details">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 sm:p-6">
          <div>
            <h2
              id="payment-details-heading"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              {t("authentification:userAuthDetail.title")}
            </h2>
            <p className="max-w-2xl text-sm text-gray-500">
              {t("authentification:userAuthDetail.description")}
            </p>
          </div>
          {authState.user ? (
            <>
              <dl className="divide-y divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    <LockClosedIcon className="w-8 h-8" />
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                    <span className="flex-grow my-auto">
                      {passwordProvider
                        ? passwordProvider.email
                        : t("authentification:userAuthDetail.noEmailLinked")}
                    </span>
                    <span className="ml-4 flex-shrink-0">
                      {passwordProvider ? (
                        <Link
                          to={{
                            pathname: pathToChangePasswordModal,
                            state: { modal: true },
                          }}
                          children={
                            <Button
                              type="button"
                              variant="textPrimary"
                              size="xs"
                              children={t(
                                "authentification:userAuthDetail.changePassword"
                              )}
                            />
                          }
                        />
                      ) : (
                        <Button
                          type="button"
                          variant="textPrimary"
                          size="xs"
                          children={t(
                            "authentification:userAuthDetail.createEmailAccount"
                          )}
                          onClick={handleCreatePasswordAccount}
                        />
                      )}
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    <GoogleIcon className="w-8 h-8" />
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                    <span className="flex-grow my-auto">
                      {googleProvider
                        ? googleProvider.email
                        : t("authentification:userAuthDetail.noGoogleAccount")}
                    </span>
                    <span className="ml-4 flex-shrink-0">
                      {googleProvider ? (
                        <Button
                          type="button"
                          variant="textPrimary"
                          size="xs"
                          children={t("authentification:userAuthDetail.unLink")}
                        />
                      ) : (
                        <Button
                          type="button"
                          variant="textPrimary"
                          size="xs"
                          children={t("authentification:userAuthDetail.link")}
                          onClick={handleLinkGoogleAccount}
                        />
                      )}
                    </span>
                  </dd>
                </div>
              </dl>
            </>
          ) : (
            <div className="my-10 place-content-center">
              <Spinner size={20} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
