import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PageLayout } from "../../ui/layout/PageLayout";
import { classNames } from "../../../utils";

type ChooseWorkflowPageProps = {
  workflowsChoice: Workflow[];
};

type Workflow = {
  title: string;
  description: string;
  onClick?: () => void;
  Icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  iconForeground: string;
  iconBackground: string;
};

type WorkflowCardProps = {
  index: number;
  totalWorkflows: number;
} & Workflow;

export const ChooseWorkflowPage: FC<ChooseWorkflowPageProps> = ({
  workflowsChoice,
}) => {
  const { t } = useTranslation(["common", "workflow"]);
  return (
    <PageLayout
      title={t("workflow:chooseWorkflowPage.title")}
      maxWidthDesktop="max-w-5xl"
    >
      <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
        {workflowsChoice.map((workflow, index) => (
          <WorkflowCard
            key={index}
            {...workflow}
            index={index}
            totalWorkflows={workflowsChoice.length}
          />
        ))}
      </div>
    </PageLayout>
  );
};

const WorkflowCard: FC<WorkflowCardProps> = ({
  title,
  description,
  onClick,
  index,
  Icon,
  iconBackground,
  iconForeground,
  totalWorkflows,
}) => (
  <WrapWorkflowCard
    onClick={onClick}
    className={classNames(
      index === 0 ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none" : "",
      index === 1 ? "sm:rounded-tr-lg" : "",
      index === totalWorkflows - 2 ? "sm:rounded-bl-lg" : "",
      index === totalWorkflows - 1
        ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
        : "",
      "relative group bg-white p-6",
      onClick ? "" : "opacity-60 cursor-not-allowed"
    )}
  >
    <div>
      <span
        className={classNames(
          iconBackground,
          iconForeground,
          "rounded-lg inline-flex p-3 ring-4 ring-white"
        )}
      >
        <Icon className="h-6 w-6" aria-hidden="true" />
      </span>
    </div>
    <div className="mt-8">
      <h3 className="text-lg font-medium">{title}</h3>
      <p className="mt-2 text-sm text-gray-500">{description}</p>
    </div>
    <span
      className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
      aria-hidden="true"
    >
      <svg
        className="h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
      </svg>
    </span>
  </WrapWorkflowCard>
);

const WrapWorkflowCard: FC<{
  className: string;
  onClick?: () => void;
}> = ({ className, onClick, children }) =>
  onClick ? (
    <div onClick={onClick} className={className} children={children} />
  ) : (
    <div className={className} children={children} />
  );
