import { FC } from "react";
import { RegisterOptions, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useAuth } from "../authentification";
import { ChangePasswordModal } from "../authentification/components/ChangePasswordModal";
import { useOverlay } from "../overlay";
import { useUpdateUserClaim } from "./";
import { UserSettingsPage } from "./UserSettingsPage";

export type ChangePasswordFormData = {
  username: string; // for accessibility
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

export const UserSettings: FC = () => {
  let { path, url } = useRouteMatch();
  let location = useLocation<{ modal: boolean }>();
  const history = useHistory();
  let displayModal = (location.state && location.state.modal) || false;

  const { authState, changeCurrentOrganisation } = useAuth();

  const { update: updateUserClaim } = useUpdateUserClaim();

  const organisationsList = authState.organisations
    ? Object.entries(authState.organisations).map(([id, organisation]) => ({
        id,
        name: organisation.name,
        userRole: organisation.role,
      }))
    : [];

  const { pushNotification } = useOverlay();

  const { t } = useTranslation(["common", "authentification"]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<ChangePasswordFormData>({
    defaultValues: { username: authState.user?.email || undefined },
  });

  const newPasswordValue = watch("newPassword");

  const registerOptions: Record<keyof ChangePasswordFormData, RegisterOptions> =
    {
      username: {
        required: true,
      },
      currentPassword: {
        required: t("authentification:fromValidation.passwordRequired"),
      },
      newPassword: {
        required: t("authentification:fromValidation.passwordRequired"),
      },
      newPasswordConfirmation: {
        required: t(
          "authentification:fromValidation.confirmationPasswordRequired"
        ),
        validate: (value) =>
          value === newPasswordValue ||
          t("authentification:fromValidation.confirmationPasswordDifferent"),
      },
    };

  const onSubmit = handleSubmit((data) => {
    pushNotification({
      severity: "warning",
      title: "Password change is not yet available",
    });
    history.push(url);
    /* updatePassword(data.currentPassword, data.newPassword)
      .then(() => {
        pushNotification({ severity: "success", title: "Password changed" });
        history.push(url);
      })
      .catch((error) => {
        pushNotification({
          severity: "error",
          title: "Error",
          body:
            currentEnvironment !== "production"
              ? error.message
              : t("authentification:errors.passwordNotupdated"),
        });
        history.push(url);
      }); */
  });

  const handleOpenOrganisation = (organisationId?: string) => {
    if (organisationId) {
      changeCurrentOrganisation(organisationId);
      history.push(`/organisations/${organisationId}/dashboard`);
    }
  };

  const updateFavOrganisation = (organisationId: string) => {
    if (authState.user?.uid) {
      updateUserClaim(authState.user.uid, {
        //@ts-ignore
        [`claims.favOrganisationId`]: organisationId,
      });
    }
  };

  return (
    <>
      <UserSettingsPage
        pathToChangePasswordModal={`${url}/changePassword`}
        userOrganisationsProps={{
          favOrganisationId: authState.favOrganisationId,
          organisationsList,
          handleOpenOrganisation,
          updateFavOrganisation,
        }}
      />
      <Switch>
        <Route
          path={`${path}/changePassword`}
          children={
            <ChangePasswordModal
              show={displayModal}
              onClose={() => history.push(url)}
              register={register}
              registerOptions={registerOptions}
              onSubmit={onSubmit}
              errors={errors}
            />
          }
        />
      </Switch>
    </>
  );
};
