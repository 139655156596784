import { FC } from "react";
import { Spinner } from "../ui/icons";
import { PageLayout } from "../ui/layout/PageLayout";
import { PlanSelection } from "./components/planSelection";
import type { PlanSelectionProps } from "./components/planSelection";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/buttons";

type SubscriptionPageProps = {
  currentOrganisation?: Shared.IOrganisation;
  sessionSuccess: boolean;
  isLoadingCurrentOrganisation: boolean;
  planSelectionProps: PlanSelectionProps;
  isLoadingPortalSession: boolean;
  handleOpenBillingPortal: () => void;
};

export const SubscriptionPage: FC<SubscriptionPageProps> = ({
  currentOrganisation,
  sessionSuccess,
  isLoadingCurrentOrganisation,
  planSelectionProps,
  isLoadingPortalSession,
  handleOpenBillingPortal,
}) => {
  const { t } = useTranslation(["common", "subscription"]);
  return (
    <PageLayout
      title={t("subscription:subscriptionPage.title")}
      maxWidthDesktop="max-w-7xl"
    >
      {isLoadingCurrentOrganisation || currentOrganisation === undefined ? (
        <Spinner />
      ) : currentOrganisation.subscription ? (
        <div className="flex flex-col mx-auto gap-5 mt-20 items-center">
          <p>{t("subscription:subscriptionPage.editBillingDetails")}</p>
          <div>
            <Button
              type="button"
              onClick={handleOpenBillingPortal}
              children="Open billing details"
              isLoading={isLoadingPortalSession}
            />
          </div>
        </div>
      ) : sessionSuccess ? (
        <div className="mt-12">
          <Spinner />
          <p className="text-center mt-6">
            {t("subscription:subscriptionPage.sessionSuccess")}
          </p>
        </div>
      ) : planSelectionProps.isLoadingCheckoutSession ? (
        <div className="mt-12">
          <Spinner />
          <p className="text-center mt-6">
            {t("subscription:subscriptionPage.redirectionToPaymentPage")}
          </p>
        </div>
      ) : (
        <PlanSelection {...planSelectionProps} />
      )}
    </PageLayout>
  );
};
