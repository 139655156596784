import { FC } from "react";
import { Link, NavLink } from "react-router-dom";
import { classNames } from "../../../utils";

type NavigationLinkProps = {
  to: string;
  text: string;
  NavigationIcon: (props: React.ComponentProps<"svg">) => JSX.Element;
  current?: boolean;
};

export const NavigationLinkMobile: FC<
  NavigationLinkProps & { onClick: () => void }
> = ({ to, text, onClick, NavigationIcon, current = false }) => {
  return (
    <Link
      to={to}
      onClick={onClick}
      className={classNames(
        current
          ? "bg-gray-100 text-gray-900"
          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
      )}
    >
      <NavigationIcon
        className={classNames(
          current ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500",
          "mr-4 flex-shrink-0 h-6 w-6"
        )}
        aria-hidden="true"
      />
      {text}
    </Link>
  );
};

export const NavigationLinkDesktop: FC<NavigationLinkProps> = ({
  to,
  text,
  NavigationIcon,
}) => {
  return (
    <NavLink
      to={to}
      activeClassName="bg-gray-100 text-gray-900"
      className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
    >
      <NavigationIcon
        className="mr-3 flex-shrink-0 h-6 w-6"
        aria-hidden="true"
      />
      {text}
    </NavLink>
  );
};
