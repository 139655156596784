import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Modal } from ".";
import { Button } from "../buttons";

type ConfirmationModalProps = {
  show: boolean;
  title: string;
  body?: string;
  action: string;
  actionType?: "primary" | "secondary" | "red" | "white";
  onConfirmation: (params: Record<string, string>) => void;
  onClose: () => void;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  show,
  title,
  body,
  action,
  actionType = "primary",
  onConfirmation,
  onClose,
}) => {
  const { t } = useTranslation("common");
  const params = useParams<Record<string, string>>();
  return (
    <Modal show={show} title={title} size="md" onClose={onClose}>
      <div className="mt-2">
        <p className="text-sm text-gray-500">{body}</p>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse space-x-4 gap-4">
        <Button
          type="button"
          variant={actionType}
          onClick={() => onConfirmation(params)}
          children={action}
        />
        <Button
          type="button"
          variant="white"
          onClick={onClose}
          children={t("action.cancel")}
        />
      </div>
    </Modal>
  );
};
