import { FC, useState } from "react";
import { useCurrentOrganisation } from "../organisation/organisationService";
import { Controller, RegisterOptions, useForm } from "react-hook-form";
import { Modal } from "../ui/overlay";
import { Button } from "../ui/buttons";
import { CustomSelect, CustomSelectOption } from "../ui/inputs";
import { useTranslation } from "react-i18next";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { TextFieldWithAction } from "../ui/inputs/TextFieldWithAction";
import { RefreshIcon } from "@heroicons/react/solid";
import { Spinner } from "../ui/icons";

type DatananasAddIntegrationModalData = {
  apiToken: string;
  senderId: string;
  sequenceId: string;
};

export const DatananasAddIntegrationModal: FC<{
  show: boolean;
  onClose: () => void;
}> = ({ show, onClose }) => {
  // UI
  const { t } = useTranslation(["common", "integration"]);
  const [isFetchingSequences, setIsFetchingSequences] = useState(false);

  // Form
  const [sequences, setSequences] = useState<Record<string, string>>();
  const [sequencesOptions, setSequencesOptions] = useState<
    CustomSelectOption[]
  >([
    {
      id: "noSequenceSelected",
      value: t("integration:datananasModal.pleaseSelectApiToken"),
    },
  ]);

  const [senderIds, setSenderIds] = useState<Record<string, string>>();
  const [senderIdsOptions, setSenderIdsOptions] = useState<
    CustomSelectOption[]
  >([
    {
      id: "noSenderIdSelected",
      value: t("integration:datananasModal.pleaseSelectSenderId"),
    },
  ]);

  const listDatananasData = httpsCallable<
    CoolsalesCallable.Datananas.ListDatananasData,
    CoolsalesCallable.Datananas.ListDatananasResponse
  >(functions, "salesAutomation-datananasListData");

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    reset,
  } = useForm<DatananasAddIntegrationModalData>({
    defaultValues: { sequenceId: "noSequenceSelected" },
  });

  const apiToken = watch("apiToken");
  const selectedSequenceId = watch("sequenceId");
  const selectedSenderId = watch("senderId");

  const registerOptions: Record<
    keyof DatananasAddIntegrationModalData,
    RegisterOptions
  > = {
    apiToken: {
      required: t("integration:fomrValidation.apiTokenRequired"),
    },
    senderId: {
      required: t("integration:fomrValidation.senderIdRequired"),
    },
    sequenceId: {
      required: t("integration:fomrValidation.sequenceIdRequired"),
      validate: (value) => {
        return (
          value !== "noSequenceSelected" ||
          t("integration:fomrValidation.sequenceIdRequired")
        );
      },
    },
  };

  const { addDatananasIntegration, isLoadingUpdate } = useCurrentOrganisation();

  const handleFetch = async () => {
    if (!apiToken) {
      setError("apiToken", {
        message: t("integration:fomrValidation.apiTokenRequired"),
      });
    } else {
      setIsFetchingSequences(true);
      try {
        const response = await listDatananasData({ apiToken });
        if (response && response.data) {
          clearErrors("apiToken");
          setSequences(
            response.data.sequences.reduce(
              (sequences, currentSequences) =>
                Object.assign(sequences, {
                  [currentSequences._id]: currentSequences.name,
                }),
              {
                noSequenceSelected: t(
                  "integration:datananasModal.pleaseSelectSequence"
                ),
              } as Record<string, string>
            )
          );
          setSequencesOptions([
            {
              id: "noSequenceSelected",
              value: t("integration:datananasModal.pleaseSelectSequence"),
            },
            ...response.data.sequences.map((sequence) => ({
              id: sequence._id,
              value: sequence.name,
            })),
          ]);
          reset({ apiToken, sequenceId: "noSequenceSelected" });
          setSenderIds(
            response.data.senders.reduce(
              (senders, currentSender) =>
                Object.assign(senders, {
                  [currentSender._id]: currentSender.name,
                }),
              {
                noSenderIdSelected: t(
                  "integration:datananasModal.pleaseSelectSenderId"
                ),
              } as Record<string, string>
            )
          );
          setSenderIdsOptions([
            {
              id: "noSenderIdSelected",
              value: t("integration:datananasModal.pleaseSelectSenderId"),
            },
            ...response.data.senders.map((sender) => ({
              id: sender._id,
              value: sender.name,
            })),
          ]);
          reset({
            apiToken,
            sequenceId: "noSequenceSelected",
            senderId: "noSenderIdSelected",
          });
          setIsFetchingSequences(false);
        }
      } catch (error) {
        setIsFetchingSequences(false);
        setError("apiToken", {
          message: t("integration:fomrValidation.wrongApiToken"),
        });
      }
    }
  };

  const onSubmit = handleSubmit((data) => {
    if (addDatananasIntegration && sequences) {
      addDatananasIntegration(
        data.apiToken,
        data.sequenceId,
        sequences[data.sequenceId],
        data.senderId
      );
      onClose();
    }
    reset({ apiToken: "", sequenceId: "noSequenceSelected" });
    setSequences({});
    setSequencesOptions([
      {
        id: "noSequenceSelected",
        value: t("integration:datananasModal.pleaseSelectSequence"),
      },
    ]);
  });

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={t("integration:datananasModal.title")}
      size="md"
    >
      <form className="space-y-6" onSubmit={onSubmit}>
        <TextFieldWithAction
          name="apiToken"
          type="text"
          register={register}
          registerOptions={registerOptions.apiToken}
          action={handleFetch}
          actionIcon={
            isFetchingSequences ? (
              <Spinner size={5} />
            ) : (
              <RefreshIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )
          }
          error={errors.apiToken?.message}
          label={t("integration:formLabel.apiToken")}
        />
        <Controller
          control={control}
          name="senderId"
          rules={registerOptions.senderId}
          render={({ field: { onChange } }) => {
            return (
              <CustomSelect
                className="text-left"
                fullwidth
                position="below"
                label={t("integration:formLabel.senderId")}
                options={senderIdsOptions}
                selected={
                  selectedSenderId && senderIds
                    ? {
                        id: selectedSenderId,
                        value: senderIds[selectedSenderId],
                      }
                    : {
                        id: "noSenderIdSelected",
                        value: t(
                          "integration:datananasModal.pleaseSelectSenderId"
                        ),
                      }
                }
                onChange={(option) => onChange(option.id)}
                error={errors.senderId?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="sequenceId"
          rules={registerOptions.sequenceId}
          render={({ field: { onChange } }) => {
            return (
              <CustomSelect
                className="text-left"
                fullwidth
                position="below"
                label={t("integration:formLabel.sequenceId")}
                options={sequencesOptions}
                selected={
                  selectedSequenceId && sequences
                    ? {
                        id: selectedSequenceId,
                        value: sequences[selectedSequenceId],
                      }
                    : {
                        id: "noSequenceSelected",
                        value: t(
                          "integration:datananasModal.pleaseSelectSequence"
                        ),
                      }
                }
                onChange={(option) => onChange(option.id)}
                error={errors.sequenceId?.message}
              />
            );
          }}
        />
        <Button
          type="submit"
          children={t("common:action.save")}
          size="sm"
          isLoading={isLoadingUpdate}
        />
      </form>
    </Modal>
  );
};
