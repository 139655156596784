import { FC, createContext, useContext, useState, useEffect } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword as firebaesCreateUserWithEmailAndPassword,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signInWithRedirect,
  GoogleAuthProvider,
  sendPasswordResetEmail as firebaseSendpasswordResetEmail,
  updateProfile as firebaseUpdateProfile,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword as firebaseUpdatePassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  User,
  ParsedToken,
} from "firebase/auth";
import { setUserId } from "firebase/analytics";
import type { IdTokenResult } from "firebase/auth";
import { db, analytics, logAnalyticsEvent } from "../firebase";
import { doc, onSnapshot } from "@firebase/firestore";
import { useTranslation } from "react-i18next";

export type AuthState = {
  status:
    | null
    | "AUTHENTICATION_LOADING"
    | "AUTHENTICATED"
    | "AUTHENTICATED_ANONYMOUSLY"
    | "UNAUTHENTICATED"
    | "AUTHENTICATION_FAILED";
  user?: User;
  token?: IdTokenResult;
  idToken?: string;
  organisations?: Record<string, { name: string; role: Shared.IUserRoles }>; // key as Organisation Id and value as organisation name
  favOrganisationId?: string;
  currentOrganisationId?: string;
  currentRole?: Shared.IUserRoles;
  error?: string;
};

/**
 * Auth context that encapsulate the authentification state and all required methods
 */
const AuthContext = createContext<
  | {
      authState: AuthState;
      createUserWithEmailAndPassword: (
        email: string,
        password: string
      ) => Promise<User | void>;
      signInWithEmailAndPassword: (
        email: string,
        password: string
      ) => Promise<User | null | void>;
      signInWithGoogle: () => Promise<void>;
      sendPasswordResetEmail: (email: string) => Promise<void>;
      updateProfile: (profile: {
        displayName?: string;
        photoUrl?: string;
      }) => Promise<void>;
      updatePassword: (
        currentPassword: string,
        newPassword: string
      ) => Promise<void>;
      signOut: () => Promise<void>;
      setAuthLanguage: (languageCode: string) => void;
      changeCurrentOrganisation: (organisationId: string) => void;
    }
  | undefined
>(undefined);

/**
 * Context provider for authentification with Firebase
 * useAuth hook can be used inside this provider to access authentification state
 * and firebase auth methods
 * @remarks Firebase must be initialized
 */
export const AuthProvider: FC = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

// For React.lazy
export default AuthProvider;

/**
 * Auth hook to access the authentification state
 * and firebase authentification methods
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within AuthContext");
  }
  return context;
};

/**
 * Provider hook that handle state and provide auth method
 */
const useAuthProvider = () => {
  const { i18n } = useTranslation();
  const auth = getAuth();
  const [authState, setAuthState] = useState<AuthState>({
    status: null,
  });

  const handleError = (e: any) => {
    const errorCode = e.code || "UNKNOWN";
    setAuthState({
      ...authState,
      status: "AUTHENTICATION_FAILED",
      error: errorCode,
    });
    throw new Error(errorCode);
  };

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<User | void> => {
    setAuthState({ status: "AUTHENTICATION_LOADING" });
    return firebaesCreateUserWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        logAnalyticsEvent("sign_up");
        return userCredentials.user;
      })
      .catch((e) => handleError(e));
  };

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<User | null | void> => {
    setAuthState({ status: "AUTHENTICATION_LOADING" });
    return firebaseSignInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        logAnalyticsEvent("login");
        return userCredential.user;
      })
      .catch((e) => handleError(e));
  };

  const sendPasswordResetEmail = async (email: string): Promise<void> => {
    return firebaseSendpasswordResetEmail(auth, email).catch((e) =>
      handleError(e)
    );
  };

  const signInWithGoogle = async () => {
    return signInWithRedirect(auth, new GoogleAuthProvider())
      .then(() => logAnalyticsEvent("login"))
      .catch((e) => handleError(e));
  };

  const updateProfile = async (profile: {
    displayName?: string;
    photoUrl?: string;
  }): Promise<void> => {
    if (authState.user) {
      if (profile.photoUrl) {
        // Firebase Auth doesn't accept photoUrl (camelcase) yet
        //@ts-ignore
        profile.photoURL = profile.photoUrl;
      }
      return firebaseUpdateProfile(authState.user, profile).catch((e) =>
        handleError(e)
      );
    } else {
      setAuthState({
        status: "AUTHENTICATION_FAILED",
        error: "NO_USER_AUTHENTICATED",
      });
    }
  };

  const updatePassword = async (
    currentPassword: string,
    newPassword: string
  ) => {
    if (authState.user) {
      const user = authState.user;
      const credential = EmailAuthProvider.credential(
        user.email as string,
        currentPassword
      );
      return reauthenticateWithCredential(user, credential)
        .then((userCredential) => {
          return firebaseUpdatePassword(userCredential.user, newPassword);
        })
        .catch((e) => handleError(e));
    } else {
      setAuthState({
        status: "AUTHENTICATION_FAILED",
        error: "NO_USER_AUTHENTICATED",
      });
    }
  };

  const signOut = async (): Promise<void> => {
    return firebaseSignOut(auth)
      .then(() => {
        setAuthState({ status: "UNAUTHENTICATED" });
      })
      .catch((e) => handleError(e));
  };

  const setAuthLanguage = (languageCode: string) => {
    auth.languageCode = languageCode;
  };

  const changeCurrentOrganisation = (newOrganisationId: string) =>
    setAuthState({ ...authState, currentOrganisationId: newOrganisationId });

  const getUserOrganisationsClaim = (claims: ParsedToken) => {
    const organisationData: {
      organisations?: AuthState["organisations"];
      favOrganisationId?: string;
    } = {};
    if (
      typeof claims.organisations === "object" &&
      Object.keys(claims.organisations).length > 0
    ) {
      organisationData.organisations =
        claims.organisations as AuthState["organisations"];
      // add user favorite organisation from custom claims or from the first organisation available
      if (claims.favOrganisationId) {
        organisationData.favOrganisationId = claims.favOrganisationId as string;
      } else {
        organisationData.favOrganisationId = Object.keys(
          claims.organisations
        )[0];
      }
    }
    return organisationData;
  };

  // Listen for change on user claim in Firestore to refresh the token
  useEffect(() => {
    const auth = getAuth();
    if (auth.currentUser && auth.currentUser.uid) {
      let unsubscribe = onSnapshot(
        doc(db, "userclaims", auth.currentUser?.uid),
        (snapshot) => {
          const upToDateClaim = snapshot.data();
          if (
            upToDateClaim &&
            upToDateClaim.updatedAt &&
            upToDateClaim.updatedAt.toDate()
          ) {
            auth.currentUser
              ?.getIdTokenResult()
              .then((currentToken) => {
                const currentTokenNotUpToDate =
                  new Date(Number(currentToken.claims.auth_time) * 1000) <
                  upToDateClaim.updatedAt.toDate();
                if (currentTokenNotUpToDate) {
                  return auth.currentUser?.getIdTokenResult(true);
                }
              })
              .then((token) => {
                if (token) {
                  const userOrganisationsClaim = getUserOrganisationsClaim(
                    token.claims
                  );
                  // Set preffered language if any
                  if (
                    typeof token.claims.prefferedLanguage === "string" &&
                    ["en", "fr"].includes(token.claims.prefferedLanguage)
                  ) {
                    i18n.changeLanguage(token.claims.prefferedLanguage);
                    auth.languageCode = token.claims.prefferedLanguage;
                  }

                  // Set a current organisation
                  const currentOrganisationId =
                    authState.currentOrganisationId ||
                    userOrganisationsClaim.favOrganisationId;

                  // Get user role for current organisation
                  let currentRole: Shared.IUserRoles = "VIEWER";
                  if (
                    currentOrganisationId &&
                    userOrganisationsClaim.organisations &&
                    userOrganisationsClaim.organisations[
                      currentOrganisationId
                    ] &&
                    userOrganisationsClaim.organisations[currentOrganisationId]
                      .role
                  ) {
                    currentRole =
                      userOrganisationsClaim.organisations[
                        currentOrganisationId
                      ].role;
                  }

                  setAuthState((authState) => ({
                    ...authState,
                    token,
                    ...userOrganisationsClaim,
                    currentOrganisationId:
                      authState.currentOrganisationId ||
                      userOrganisationsClaim.favOrganisationId,
                    currentRole,
                  }));
                }
              })
              .catch((e) => console.error(e));
          }
        },
        (e) => {
          console.error(e);
        }
      );
      return unsubscribe;
    }
    // ignore i18n missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.user?.uid]);

  // Listen for auth change
  useEffect(() => {
    const auth = getAuth();
    let idToken: string;
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        user
          .getIdToken()
          .then((result) => {
            idToken = result;
            return user.getIdTokenResult();
          })
          .then((token) => {
            // Get userOrganisation info
            const userOrganisationsClaim = getUserOrganisationsClaim(
              token.claims
            );

            // Set a current organisation
            const currentOrganisationId =
              authState.currentOrganisationId ||
              userOrganisationsClaim.favOrganisationId;

            // Get user role for current organisation
            let currentRole: Shared.IUserRoles = "VIEWER";
            if (
              currentOrganisationId &&
              userOrganisationsClaim.organisations &&
              userOrganisationsClaim.organisations[currentOrganisationId] &&
              userOrganisationsClaim.organisations[currentOrganisationId].role
            ) {
              currentRole =
                userOrganisationsClaim.organisations[currentOrganisationId]
                  .role;
            }

            // Set preffered language if any
            if (
              typeof token.claims.prefferedLanguage === "string" &&
              ["en", "fr"].includes(token.claims.prefferedLanguage)
            ) {
              i18n.changeLanguage(token.claims.prefferedLanguage);
              auth.languageCode = token.claims.prefferedLanguage;
            }

            setAuthState((authState) => {
              return {
                status: user.isAnonymous
                  ? "AUTHENTICATED_ANONYMOUSLY"
                  : "AUTHENTICATED",
                user,
                token,
                idToken,
                ...userOrganisationsClaim,
                currentOrganisationId,
                currentRole,
              };
            });

            // Set GA user id
            setUserId(analytics, user.uid);
          })
          .catch((e) =>
            setAuthState((authState) => ({ ...authState, error: "UNKNONW" }))
          );
      } else {
        setAuthState({
          status: "UNAUTHENTICATED",
        });
      }
    });
    return () => unsubscribe();
    // ignore i18n missing dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    authState,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithGoogle,
    sendPasswordResetEmail,
    setAuthLanguage,
    updateProfile,
    updatePassword,
    signOut,
    changeCurrentOrganisation,
  };
};
