/**
 * Return an clean url (domain and subdomain expcet www) from a string
 * @param url the url
 * @returns The cleaned url
 */
export const getCleanUrl = (url: string): string => {
  try {
    const urlObject = new URL(url);
    const hostname = urlObject.hostname;
    const cleanUrl = hostname.replace("www.", "");

    return cleanUrl;
  } catch (error) {
    return url;
  }
};
