import { FC } from "react";
import { UseFormRegister, RegisterOptions, FieldErrors } from "react-hook-form";
import { Button } from "../../ui/buttons";
import { TextField } from "../../ui/inputs";
import { RouterLink } from "../../ui/links";
import { SigninFormData } from "../SignIn";
import { useTranslation } from "react-i18next";
import { LocaleSwitcher } from "../../common";
import { GoogleIcon } from "../../ui/icons";

export const SignInPage: FC<{
  register: UseFormRegister<SigninFormData>;
  registerOptions: Record<keyof SigninFormData, RegisterOptions>;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  errors: FieldErrors<SigninFormData>;
  isLoading: boolean;
  signInWithGoogle: () => Promise<void>;
}> = ({
  register,
  registerOptions,
  onSubmit,
  errors,
  isLoading,
  signInWithGoogle,
}) => {
  const { t } = useTranslation("authentification");
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src="/logo512.png" alt="Logo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {t("signinPage.title")}
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          {t("signinPage.noAccountLink.text")}{" "}
          <RouterLink
            to="/signup"
            text={t("signinPage.noAccountLink.action")}
          />
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="mb-6 space-y-6">
            <div>
              <Button
                type="button"
                variant="white"
                fullwidth
                onClick={signInWithGoogle}
              >
                <span className="sr-only">Sign in with Google</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <GoogleIcon className="w-8 h-8" />
                </svg>
              </Button>
            </div>
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  {t("signinPage.continueWith")}
                </span>
              </div>
            </div>
          </div>
          <form className="space-y-6" onSubmit={onSubmit} noValidate>
            <TextField
              register={register}
              registerOptions={registerOptions.email}
              error={errors.email?.message}
              label={t("formLabel.email")}
              name="email"
              type="email"
              autocomplete="email"
            />
            <TextField
              register={register}
              registerOptions={registerOptions.password}
              error={errors.password?.message}
              label={t("formLabel.password")}
              name="password"
              type="password"
              autocomplete="new-password"
            />

            <div className="flex items-center justify-end">
              <div className="text-sm">
                <RouterLink
                  to="/reset-password"
                  text={t("signinPage.forgotPassword")}
                />
              </div>
            </div>

            <div>
              <Button
                type="submit"
                children={t("formSubmit.signin")}
                size="sm"
                fullwidth
                isLoading={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md mt-4">
        <LocaleSwitcher />
      </div>
    </div>
  );
};
