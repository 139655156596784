import { useRef, useMemo } from "react";
import { dequal as deepEqual } from "dequal";

/**
 * Copy past of useDeepCompareMemoize from use-deep-compare-effect package to be used in custom hook factory
 */
export const useDeepCompareMemoize = <T extends unknown>(value: T) => {
  const ref = useRef<T>(value);
  const signalRef = useRef<number>(0);

  if (!deepEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ref.current, [signalRef.current]);
};
