import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { FC, Fragment } from "react";
import { NavigationLinkDesktop, NavigationLinkMobile } from "./NavigationLink";
import { MenuItem, UserMenu } from "./UserMenu";
import { SubscriptionSidenavBlock } from "../../subscription";

export type SidebarProps = {
  navigation: {
    name: string;
    path: string;
    icon: (props: React.ComponentProps<"svg">) => JSX.Element;
    current: boolean;
  }[];
  userNavigation: MenuItem[];
  organisationName?: string;
  subscriptionPath: string;
  displayName?: string | null;
  photoUrl?: string | null;
};

type SidebarMobileProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
} & SidebarProps;

export const SidebarMobile: FC<SidebarMobileProps> = ({
  sidebarOpen,
  setSidebarOpen,
  navigation,
  userNavigation,
  organisationName,
  subscriptionPath,
  displayName,
  photoUrl,
}) => {
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-40 md:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-8 w-auto" src="/logo512.png" alt="Logo" />
              </div>
              <nav className="mt-5 px-2 space-y-1">
                {navigation.map((item, index) => (
                  <NavigationLinkMobile
                    key={index}
                    onClick={() => setSidebarOpen(false)}
                    to={item.path}
                    text={item.name}
                    current={item.current}
                    NavigationIcon={item.icon}
                  />
                ))}
              </nav>
            </div>
            <SubscriptionSidenavBlock
              subscriptionPath={subscriptionPath}
              onClick={() => setSidebarOpen(false)}
            />
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <UserMenu
                userNavigation={userNavigation}
                displayName={displayName}
                organisationName={organisationName}
                photoUrl={photoUrl}
              />
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const SidebarDesktop: FC<SidebarProps> = ({
  navigation,
  userNavigation,
  subscriptionPath,
  organisationName,
  displayName,
  photoUrl,
}) => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img className="h-8 w-auto" src="/logo512.png" alt="Workflow" />
            </div>
            <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
              {navigation.map((item, index) => (
                <NavigationLinkDesktop
                  key={index}
                  to={item.path}
                  text={item.name}
                  current={item.current}
                  NavigationIcon={item.icon}
                />
              ))}
            </nav>
          </div>
          <SubscriptionSidenavBlock subscriptionPath={subscriptionPath} />
          <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
            <UserMenu
              userNavigation={userNavigation}
              displayName={displayName}
              organisationName={organisationName}
              photoUrl={photoUrl}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
