import { FC, useState } from "react";
import {
  UseFormRegister,
  FieldErrors,
  RegisterOptions,
  Control,
  Controller,
} from "react-hook-form";
import { Button } from "../ui/buttons";
import { TextField } from "../ui/inputs";
import { RegisterFormData } from "./Register";
import { useTranslation } from "react-i18next";
import { LocaleSwitcher } from "../common";
import { CustomSelect } from "../ui/inputs";
import { CustomSelectOption } from "../ui/inputs/CustomSelect";

export const RegisterPage: FC<{
  register: UseFormRegister<RegisterFormData>;
  control?: Control<RegisterFormData, object>;
  registerOptions: Record<keyof RegisterFormData, RegisterOptions>;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  errors: FieldErrors<RegisterFormData>;
  isLoading: boolean;
}> = ({ register, registerOptions, onSubmit, errors, control, isLoading }) => {
  const { t } = useTranslation(["common", "user"]);

  const companySizeOptions = [
    {
      id: "notSelected",
      value: t("user:registerPage.companySize.notSelected"),
    },
    { id: "2-10", value: t("user:registerPage.companySize.2-10") },
    { id: "11-50", value: t("user:registerPage.companySize.11-50") },
    { id: "51-200", value: t("user:registerPage.companySize.51-200") },
    { id: "201-500", value: t("user:registerPage.companySize.201-500") },
    { id: "501-1000", value: t("user:registerPage.companySize.501-1000") },
    { id: "1001-5000", value: t("user:registerPage.companySize.1001-5000") },
    { id: "5001-10000", value: t("user:registerPage.companySize.5001-10000") },
    { id: "10001", value: t("user:registerPage.companySize.10001") },
  ];
  const [companySizeSelected, setCompanySizeSelected] =
    useState<CustomSelectOption>(companySizeOptions[0]);

  const personaeOptions = [
    {
      id: "notSelected",
      value: t("user:registerPage.personae.notSelected"),
    },
    {
      id: "founder",
      value: t("user:registerPage.personae.founder"),
    },
    {
      id: "developer",
      value: t("user:registerPage.personae.developer"),
    },
    {
      id: "marketing",
      value: t("user:registerPage.personae.marketing"),
    },
    {
      id: "productOwner",
      value: t("user:registerPage.personae.productOwner"),
    },
    {
      id: "growth",
      value: t("user:registerPage.personae.growth"),
    },
    {
      id: "sales",
      value: t("user:registerPage.personae.sales"),
    },
    {
      id: "recruiter",
      value: t("user:registerPage.personae.recruiter"),
    },
    {
      id: "other",
      value: t("user:registerPage.personae.other"),
    },
  ];
  const [personaeSelected, setPersonaeSelected] = useState<CustomSelectOption>(
    personaeOptions[0]
  );

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src="logo512.png" alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {t("user:registerPage.title")}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-transparent py-8 px-4 sm:rounded-lg sm:px-10 sm:bg-white sm:shadow">
          <form className="space-y-6" onSubmit={onSubmit} noValidate>
            <TextField
              register={register}
              registerOptions={registerOptions.displayName}
              error={errors.displayName?.message}
              label={t("user:formLabel.displayName")}
              name="displayName"
              type="text"
              autocomplete="name"
            />
            <Controller
              control={control}
              defaultValue=""
              name="personae"
              render={({ field: { onChange } }) => {
                return (
                  <CustomSelect
                    label={t("user:formLabel.personae")}
                    options={personaeOptions}
                    selected={personaeSelected}
                    onChange={(option) => {
                      onChange(option.id);
                      setPersonaeSelected(option);
                    }}
                  />
                );
              }}
            />
            <TextField
              register={register}
              registerOptions={registerOptions.organisationName}
              error={errors.organisationName?.message}
              label={t("user:formLabel.organisationName")}
              name="organisationName"
              type="text"
              autocomplete="organization"
            />
            <TextField
              register={register}
              registerOptions={registerOptions.organisationPhone}
              error={errors.organisationPhone?.message}
              label={t("user:formLabel.organisationPhone")}
              name="organisationPhone"
              type="text"
              autocomplete="tel"
            />
            <TextField
              register={register}
              registerOptions={registerOptions.organisationDomain}
              error={errors.organisationDomain?.message}
              label={t("user:formLabel.organisationDomain")}
              name="organisationDomain"
              type="text"
              autocomplete="off"
            />
            <Controller
              control={control}
              defaultValue=""
              name="organisationSize"
              render={({ field: { onChange } }) => {
                return (
                  <CustomSelect
                    label={t("user:formLabel.organisationSize")}
                    options={companySizeOptions}
                    selected={companySizeSelected}
                    onChange={(option) => {
                      onChange(option.id);
                      setCompanySizeSelected(option);
                    }}
                  />
                );
              }}
            />

            <div>
              <Button
                type="submit"
                children={t("common:action.save")}
                size="sm"
                fullwidth
                isLoading={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md mt-4">
        <LocaleSwitcher />
      </div>
    </div>
  );
};
