import { useEffect, useState } from "react";
import { app } from "./index";
import {
  Firestore,
  FieldValue,
  serverTimestamp,
  increment,
  getFirestore,
  CollectionReference,
  collection,
  FirestoreDataConverter,
  doc,
  getDoc,
  query,
  getDocs,
  addDoc,
  WithFieldValue,
  updateDoc,
  UpdateData,
  setDoc,
  deleteDoc,
  QuerySnapshot,
  FirestoreError,
  onSnapshot,
  DocumentSnapshot,
  WhereFilterOp,
  FieldPath,
  where,
  limit,
  orderBy,
  startAt,
  endAt,
  startAfter,
  endBefore,
} from "firebase/firestore";
import { logFirestoreEvent } from ".";
import { useOverlay } from "../overlay";
import { removeUndefined } from "../../utils";
import { useDeepCompareMemoize } from "../../utils";
import { currentEnvironment } from "../config";

type ListConstraints = {
  limitResults?: number;
  filters?: {
    field: string | FieldPath;
    operator: WhereFilterOp;
    value: unknown;
  }[];
  order?: {
    direction: "asc" | "desc";
    by: string | FieldPath;
  };
  cursor?: {
    direction?: "startAt" | "startAfter" | "endAt" | "endBefore";
    at?: any | any[];
    documentId?: string;
  };
};

export abstract class FirestoreService<T extends Shared.IFirestoreModel> {
  /**
   * An instance of the Cloud Firestore database
   */
  protected readonly db: Firestore;

  /**
   * A Firestore method for server timestamp
   *
   * @remarks
   * Use for createdAt and upadteAt field
   */
  protected readonly serverTimestamp: FieldValue;

  /**
   * A Firestore method to increment atomically by one a number value
   */
  protected readonly increment: FieldValue;

  /**
   * A Firestore method to decrement atomically by one a number value
   */
  protected readonly decrement: FieldValue;

  /**
   * A reference to the Firestore collection of the object
   */
  protected collection: CollectionReference;

  /**
   * A reference to the Firestore collection of deleted object
   */
  protected deletedCollection: CollectionReference;

  constructor(public readonly collectionName: string, public path?: string) {
    this.db = getFirestore(app);
    this.serverTimestamp = serverTimestamp();
    this.increment = increment(1);
    this.decrement = increment(-1);
    this.collection = collection(
      this.db,
      path ? `${path}/${this.collectionName}` : this.collectionName
    );
    this.deletedCollection = collection(
      this.db,
      path
        ? `${path}/deleted_${this.collectionName}`
        : `deleted_${this.collectionName}`
    );
  }

  /**
   * Set path for the collection reference
   *
   * @remarks
   * Path must correspond to the root path without the collection name : "collection/docID"
   *
   * @public
   */
  public setPath(path: string): void {
    this.path = `${path}/${this.collectionName}`;
    this.collection = collection(this.db, this.path);
  }

  /**
   * Returns a converter that can be chained to get and set call to firestore
   *
   * @remarks
   * The toFirestore converter must set a deleted key to false
   *
   * @protected
   */
  protected abstract getModelConverter(): FirestoreDataConverter<T>;

  /**
   * Return the object corresponding
   *
   * @param id The desired object's id
   *
   * @public
   */
  async get(id: string): Promise<T | undefined> {
    const docRef = doc(this.db, this.collection.path, id).withConverter(
      this.getModelConverter()
    );
    return getDoc(docRef)
      .then((document) => {
        logFirestoreEvent("firestore_get", {
          collection: this.collectionName,
          id,
        });
        return document.data();
      })
      .catch((error) => {
        logFirestoreEvent("firestore_error", {
          collection: this.collectionName,
          id,
          error: error.name,
        });
        throw new Error(error);
      });
  }

  /**
   * Get all objects of the collection
   * @returns An array of objects
   */
  async list({
    limitResults = 100,
    filters,
    order,
    cursor,
  }: ListConstraints = {}): Promise<T[]> {
    let q = query(this.collection).withConverter(this.getModelConverter());

    q = query(q, limit(limitResults));

    if (filters) {
      filters.forEach(
        (filter) =>
          (q = query(q, where(filter.field, filter.operator, filter.value)))
      );
    }

    if (order && !cursor) {
      q = query(q, orderBy(order.by, order.direction));
    } else if (cursor && order) {
      console.log(cursor);
      if (cursor.documentId) {
        console.log("Start After", cursor.documentId);
        const docSnap = await getDoc(
          doc(this.db, this.collection.path, cursor.documentId)
        );
        switch (cursor.direction) {
          case "startAfter":
            q = query(
              q,
              orderBy(order.by, order.direction),
              startAfter(docSnap)
            );
            break;

          case "endAt":
            q = query(q, orderBy(order.by, order.direction), endAt(docSnap));
            break;

          case "endBefore":
            q = query(
              q,
              orderBy(order.by, order.direction),
              endBefore(docSnap)
            );
            break;

          case "startAt":
          default:
            q = query(q, orderBy(order.by, order.direction), startAt(docSnap));
            break;
        }
      } else if (cursor.at) {
        switch (cursor.direction) {
          case "startAfter":
            q = query(q, startAfter(cursor.at));
            break;

          case "endAt":
            q = query(q, orderBy(order.by, order.direction), endAt(cursor.at));
            break;

          case "endBefore":
            q = query(
              q,
              orderBy(order.by, order.direction),
              endBefore(cursor.at)
            );
            break;

          case "startAt":
          default:
            q = query(
              q,
              orderBy(order.by, order.direction),
              startAt(cursor.at)
            );
            break;
        }
      }
    }

    return getDocs(q)
      .then((querySnapshots) => {
        const docs: T[] = [];
        if (!querySnapshots.empty) {
          querySnapshots.forEach((document) => docs.push(document.data()));
        }
        logFirestoreEvent("firestore_list", {
          collection: this.collectionName,
          numDocs: docs.length,
        });
        return docs;
      })
      .catch((error) => {
        logFirestoreEvent("firestore_error", {
          collection: this.collectionName,
          error: error.name,
        });
        throw new Error(error);
      });
  }

  /**
   * Add an object with the given data
   *
   * @param data The object's data to create
   * @return the created object id
   */
  async add(data: T): Promise<string> {
    return addDoc(
      this.collection.withConverter(this.getModelConverter()),
      removeUndefined(data) as WithFieldValue<T>
    )
      .then((documentReference) => {
        logFirestoreEvent("firestore_create", {
          collection: this.collectionName,
          id: documentReference.id,
        });
        return documentReference.id;
      })
      .catch((error) => {
        logFirestoreEvent("firestore_error", {
          collection: this.collectionName,
          error: error.name,
        });
        throw new Error(error);
      });
  }

  /**
   * Update an object with the given data
   *
   * @remarks
   * The data can be partial. Map field will be replaced,
   * this can be avoid by providing dotted reference to nested fields
   *
   * @param id The object's id to update
   * @param data The data to update
   */
  async update(id: string, data: T): Promise<void> {
    const docRef = doc(this.db, this.collection.path, id).withConverter(
      this.getModelConverter()
    );

    return updateDoc(docRef, {
      updatedAt: this.serverTimestamp,
      ...(removeUndefined(data) as UpdateData<T>),
    })
      .then(() => {
        logFirestoreEvent("firestore_update", {
          collection: this.collectionName,
          id,
        });
      })
      .catch((error) => {
        logFirestoreEvent("firestore_error", {
          collection: this.collectionName,
          id,
          error: error.name,
        });
        throw new Error(error);
      });
  }

  /**
   * Soft delete an object
   *
   * @remarks
   * Create a copy og the object in the deleted collection : path/deleted_collectionName
   */
  async delete(id: string): Promise<void> {
    const docToDeleteRef = doc(this.db, this.collection.path, id);
    const docInDeletedCollectionRef = doc(
      this.db,
      this.deletedCollection.path,
      id
    );

    return getDoc(docToDeleteRef)
      .then((documentSnapshot) => {
        if (documentSnapshot.exists()) {
          logFirestoreEvent("firestore_create", {
            collection: `deleted_${this.collectionName}`,
            id,
          });
          return setDoc(docInDeletedCollectionRef, documentSnapshot.data());
        } else {
          throw new Error("The document to delete doesn't exist");
        }
      })
      .then(() => {
        return deleteDoc(docToDeleteRef);
      })
      .then(() => {
        logFirestoreEvent("firestore_delete", {
          collection: `${this.collectionName}`,
          id,
        });
      })
      .catch((error) => {
        logFirestoreEvent("firestore_error", {
          collection: this.collectionName,
          id,
          error: error.name,
        });
        throw new Error(error);
      });
  }

  /**
   * Attach an observer to a document
   * @param id The document id
   * @param next A callback to be called every time a new DocumentSnapshot is available.
   * @param error A callback to be called if the listen fails or is cancelled. No further callbacks will occur.
   * @returns An unsubscribe function that can be called to cancel the snapshot listener.
   */
  snapshotDocument(
    id: string,
    next: (snapshot: DocumentSnapshot<T>) => void,
    error: (error: FirestoreError) => void
  ): () => void {
    logFirestoreEvent("firestore_snapshotDocument", {
      collection: this.collectionName,
      id,
    });
    return onSnapshot(
      doc(this.db, this.collection.path, id).withConverter(
        this.getModelConverter()
      ),
      next,
      error
    );
  }

  /**
   * Attach an observer to the collection
   * @param next A callback to be called every time a new DocumentSnapshot is available.
   * @param error A callback to be called if the listen fails or is cancelled. No further callbacks will occur.
   * @returns An unsubscribe function that can be called to cancel the snapshot listener.
   */
  snapshot(
    next: (snapshot: QuerySnapshot<T>) => void,
    error: (error: FirestoreError) => void
  ): () => void {
    logFirestoreEvent("firestore_snapshotList", {
      collection: this.collectionName,
    });
    const q = query(this.collection).withConverter(this.getModelConverter());
    return onSnapshot(q, next, error);
  }
}

export const createSnapshotHook =
  <T>(service: FirestoreService<T>) =>
  () => {
    const [list, setList] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { pushNotification } = useOverlay();

    useEffect(() => {
      currentEnvironment !== "production" && console.log("SnapshotHook");
      const unsubscribe = service.snapshot(
        (snapshot) => {
          setList(snapshot.docs.map((doc) => doc.data()));
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          pushNotification({
            severity: "error",
            title: "An error occured",
            body:
              currentEnvironment !== "production" ? error.message : undefined,
          });
        }
      );

      return unsubscribe;
    }, [pushNotification]);

    return { list, isLoading };
  };

export const createSnapshotDocumentHook =
  <T>(service: FirestoreService<T>) =>
  (id: string) => {
    const [document, setDocument] = useState<T>();
    const [isLoading, setIsLoading] = useState(true);
    const { pushNotification } = useOverlay();

    useEffect(() => {
      currentEnvironment !== "production" &&
        console.log("SnapshotDocumentHook");
      const unsubscribe = service.snapshotDocument(
        id,
        (snapshot) => {
          setDocument(snapshot.data());
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          pushNotification({
            severity: "error",
            title: "An error occured",
            body:
              currentEnvironment !== "production" ? error.message : undefined,
          });
        }
      );

      return () => {
        unsubscribe();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return { document, isLoading };
  };

export const createGetHook =
  <T>(service: FirestoreService<T>) =>
  (id: string) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<T>();
    const { pushNotification } = useOverlay();

    useEffect(() => {
      currentEnvironment !== "production" && console.log("GettHook");
      service
        .get(id)
        .then((object) => {
          if (object) {
            setData(object);
          } else {
            pushNotification({
              severity: "error",
              title: "Not found",
              body: `No item found with id ${id}`,
            });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          pushNotification({
            severity: "error",
            title: "Error",
            body:
              currentEnvironment !== "production"
                ? error.message
                : `An error occured while getting ${service.collectionName} with id ${id}`,
          });
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return { isLoading, data };
  };

export const createListHook =
  <T>(service: FirestoreService<T>) =>
  (constraints?: ListConstraints) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<T[]>();
    const { pushNotification } = useOverlay();

    const memoizeConstraints = useDeepCompareMemoize(constraints);

    useEffect(() => {
      currentEnvironment !== "production" && console.log("ListHook");
      service
        .list(memoizeConstraints)
        .then((objects) => {
          setData(objects);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          pushNotification({
            severity: "error",
            title: "Error",
            body:
              currentEnvironment !== "production"
                ? error.message
                : `An error occured while getting ${service.collectionName}`,
          });
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoizeConstraints]);

    return { isLoading, data };
  };

export const createAddlHook =
  <T>(service: FirestoreService<T>) =>
  () => {
    const [isLoading, setIsLoading] = useState(false);
    const { pushNotification } = useOverlay();

    const add = (data: T) => {
      setIsLoading(true);
      return service
        .add(data)
        .then((result) => {
          setIsLoading(false);
          return result;
        })
        .catch((error) => {
          setIsLoading(false);
          pushNotification({
            severity: "error",
            title: "Error",
            body:
              currentEnvironment !== "production"
                ? error.message
                : `An error occured while adding ${service.collectionName}`,
          });
        });
    };

    return { isLoading, add };
  };

export const createUpdateHook =
  <T>(service: FirestoreService<T>) =>
  () => {
    const [isLoading, setIsLoading] = useState(false);
    const { pushNotification } = useOverlay();

    const update = (id: string, data: T) => {
      setIsLoading(true);
      return service
        .update(id, data)
        .then(() => setIsLoading(false))
        .catch((error) => {
          setIsLoading(false);
          pushNotification({
            severity: "error",
            title: "Error",
            body:
              currentEnvironment !== "production"
                ? error.message
                : `An error occured while updating ${service.collectionName} with id ${id}`,
          });
        });
    };

    return { isLoading, update };
  };

export const createSoftDeletelHook =
  <T>(service: FirestoreService<T>) =>
  () => {
    const [isLoading, setIsLoading] = useState(false);
    const { pushNotification } = useOverlay();

    const softDelete = (id: string) => {
      setIsLoading(true);
      return service
        .delete(id)
        .then(() => setIsLoading(false))
        .catch((error) => {
          setIsLoading(false);
          pushNotification({
            severity: "error",
            title: "Error",
            body:
              currentEnvironment !== "production"
                ? error.message
                : `An error occured while deleting ${service.collectionName} with id ${id}`,
          });
        });
    };

    return { isLoading, softDelete };
  };

export const createPagindatedListHook =
  <T extends Shared.IFirestoreModel>(service: FirestoreService<T>) =>
  () => {
    const { pushNotification } = useOverlay();
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState<10 | 25 | 50 | 100>(10);
    const [by, setOrderBy] = useState<string>("createdAt");
    const [direction, setOrderDirection] = useState<"asc" | "desc">("desc");
    const [cursor, setCursor] = useState<string>();
    const [hasMore, setHasMore] = useState(false);
    const [currentData, setCurrentData] = useState<T[]>([]);
    const [nextData, setNextData] = useState<T[]>([]);
    const [prevData, setPrevData] = useState<T[]>([]);

    const hasNext = nextData.length > 0 || hasMore;
    const hasPrev = prevData.length > 0;

    const showingFrom = prevData.length === 0 ? 1 : prevData.length + 1;
    const showingTo = prevData.length + limit;

    const total = hasMore
      ? prevData.length + currentData.length + nextData.length + "+"
      : prevData.length + currentData.length + nextData.length;

    const getNextData = () => {
      if (nextData.length > 0) {
        setPrevData((prevData) => prevData.concat(currentData));
        setCurrentData(nextData.slice(0, limit));
        setNextData((nextData) => nextData.slice(limit));
      } else if (hasMore) {
        setPrevData((prevData) => prevData.concat(currentData));
        setIsLoading(true);
        setCursor(currentData[limit - 1].id);
      }
    };

    const getPrevData = () => {
      if (prevData.length > 0) {
        setNextData((nextData) => currentData.concat(nextData));
        setCurrentData(prevData.slice(-limit));
        setPrevData((prevData) => prevData.slice(0, -limit));
      }
    };

    useEffect(() => {
      currentEnvironment !== "production" && console.log("PaginatedHook");
      service
        .list({
          limitResults: 100,
          order: { by, direction },
          cursor: cursor
            ? {
                documentId: cursor,
                direction: "startAfter",
              }
            : undefined,
        })
        .then((results) => {
          // Update query data
          setHasMore(results.length === 100);

          // Set current data to beginning of results and store next data
          if (results.length > 0) {
            setCurrentData(results.slice(0, limit));
            setNextData(results.slice(limit));
          } else {
            // Remove last current data added to prev data
            setPrevData((prevData) => prevData.slice(limit));
          }

          // Update UI
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          pushNotification({
            severity: "error",
            title: "Error",
            body:
              currentEnvironment !== "production"
                ? error.message
                : `An error occured while getting ${service.collectionName} list`,
          });
        });
      // ignore missing dependecies currentData and pushNotification
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, by, direction, cursor]);

    return {
      isLoading,
      limit,
      setLimit,
      setOrderBy,
      setOrderDirection,
      currentData,
      total,
      showingFrom,
      showingTo,
      hasPrev,
      hasNext,
      getNextData,
      getPrevData,
    };
  };

export const createServiceHooks = <T>(service: FirestoreService<T>) => {
  const snapshot = createSnapshotHook(service);
  const snapshotDocument = createSnapshotDocumentHook(service);
  const get = createGetHook(service);
  const list = createListHook(service);
  const add = createAddlHook(service);
  const update = createUpdateHook(service);
  const softDelete = createSoftDeletelHook(service);
  return { snapshot, snapshotDocument, get, list, add, update, softDelete };
};
