import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../../utils";
import { CustomSelect, CustomSelectOption } from "../inputs";

export type TabsProps = {
  menu: {
    name: string;
    to: string;
    current?: boolean;
    count?: number;
  }[];
};

export const Tabs: FC<TabsProps> = ({ menu }) => {
  const options: CustomSelectOption[] = menu.map((item) => ({
    id: item.to,
    value: item.name,
  }));
  const currentItem = menu.find((item) => item.current);
  const [selected, setSelected] = useState<CustomSelectOption>(
    currentItem ? { id: currentItem.to, value: currentItem.name } : options[0]
  );

  const handleChange = (option: CustomSelectOption) => {
    setSelected(option);
  };

  return (
    <div>
      <div className="sm:hidden">
        <CustomSelect
          options={options}
          selected={selected}
          onChange={handleChange}
        />
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {menu.map((item) => (
            <Link
              key={item.name}
              to={item.to}
              className={classNames(
                item.current
                  ? "bg-blue-50 text-primary"
                  : "text-gray-500 hover:text-gray-700",
                "px-3 py-2 font-medium text-sm rounded-md"
              )}
              aria-current={item.current ? "page" : undefined}
            >
              {item.name}
              {item.count ? (
                <span
                  className={classNames(
                    item.current
                      ? "bg-blue-50 text-primary"
                      : "bg-gray-100 text-gray-900",
                    "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                  )}
                >
                  {item.count}
                </span>
              ) : null}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};
