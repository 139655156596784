import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PageLayout } from "../../ui/layout/PageLayout";
import { SideStepper, SideStepperProps } from "../../ui/navigation";
import { useQuery } from "../../../utils";
import { StepJobForm } from "./StepJobForm";
import { StepTargetForm } from "./StepTargetForm";
import { StepSettingsForm } from "./StepSettingsForm";
import { StepOutputForm } from "./StepOutputForm";
import { StepWorkflowReady } from "./StepWorkflowReady";

type JobboardWorkflowPageProps = {
  steps: SideStepperProps["steps"];
  workflow?: Shared.IWorkflow<Shared.JobboardParams>;
  isLoading: boolean;
};

export const JobboardWorkflowPage: FC<JobboardWorkflowPageProps> = ({
  steps,
  workflow,
  isLoading,
}) => {
  const query = useQuery();
  const step = query.get("step") || "job";
  const { t } = useTranslation(["common", "workflow"]);

  const getJobboardWorkflowStep = () => {
    switch (step) {
      case "target":
        return (
          <StepTargetForm
            isLoadingWorkflow={isLoading}
            targetParams={workflow?.params?.target}
          />
        );

      case "output":
        return (
          <StepOutputForm
            isLoadingWorkflow={isLoading}
            outputParams={workflow?.params?.output}
          />
        );

      case "settings":
        return (
          <StepSettingsForm
            isLoadingWorkflow={isLoading}
            workflowName={workflow?.name}
            settingsParams={workflow?.params?.settings}
          />
        );

      case "ready":
        return (
          <StepWorkflowReady
            isLoadingWorkflow={isLoading}
            workflow={workflow}
          />
        );

      case "job":
      default:
        return (
          <StepJobForm
            isLoadingWorkflow={isLoading}
            jobParams={workflow?.params?.job}
          />
        );
    }
  };

  return (
    <PageLayout
      title={t("workflow:addJobboardWorkflowPage.title")}
      maxWidthDesktop="max-w-5xl"
    >
      <div className="grid grid-cols-6 gap-x-6">
        <div className="hidden sm:block">
          <SideStepper steps={steps} />
        </div>
        <div className="col-span-6 sm:col-span-5">
          {getJobboardWorkflowStep()}
        </div>
      </div>
    </PageLayout>
  );
};
