import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { classNames } from "../../../utils";

const bgColors = [
  "bg-blue-500",
  "bg-green-500",
  "bg-red-500",
  "bg-indigo-500",
  "bg-purple-500",
];

export const ChangeOrganisationModalContent: FC<{
  organisations: {
    name: string;
    id: string;
    role: Shared.IUserRoles;
  }[];
  changeCurrentOrganisation: (id: string) => void;
  closeModal: () => void;
}> = ({ organisations, changeCurrentOrganisation, closeModal }) => {
  const { t } = useTranslation(["common", "organisation"]);
  const history = useHistory();
  return (
    <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 md:grid-cols-2">
      {organisations.map((organisation, index) => (
        <li
          key={index}
          className="col-span-1 flex shadow-sm rounded-md cursor-pointer"
          onClick={() => {
            changeCurrentOrganisation(organisation.id);
            history.push(`/organisations/${organisation.id}/dashboard`);
            closeModal();
          }}
        >
          <div
            className={classNames(
              bgColors[index % 5],
              "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md"
            )}
          >
            {organisation.name.substring(0, 2).toUpperCase()}
          </div>
          <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
            <div className="flex-1 px-4 py-2 text-sm truncate">
              <p className="text-gray-900 font-medium hover:text-gray-600">
                {organisation.name}
              </p>
              <p className="text-gray-500">
                {t("common:conjunction.asSomethingOf")}{" "}
                {t(`common:userRole.${organisation.role}`)}
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
