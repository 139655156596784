import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/app";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { SplashPage } from "./components/ui/page";
import { OverlayProvider } from "./components/overlay";
import { currentEnvironment } from "./components/config";

// Lasy load the AuthProvider with a minimum time of 600 ms to display the splashscreen
const AuthProvider = lazy(() => {
  return Promise.all([
    import("./components/authentification/AuthProvider"),
    new Promise((resolve) => setTimeout(resolve, 600)),
  ]).then(([moduleExports]) => moduleExports);
});

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<SplashPage />}>
      <OverlayProvider>
        <AuthProvider>
          {currentEnvironment === "local" ? (
            <p className="absolute w-full z-50 bg-red-600 h-6 flex items-center justify-center text-sm font-medium text-white px-4 sm:px-6 lg:px-8">
              Local environment
            </p>
          ) : currentEnvironment === "staging" ? (
            <p className="absolute w-full z-50 bg-yellow-700 h-6 flex items-center justify-center text-sm font-medium text-white px-4 sm:px-6 lg:px-8">
              Staging environment, all data can be restored at any time
            </p>
          ) : null}
          <App />
        </AuthProvider>
      </OverlayProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
