import { FC } from "react";
import { classNames } from "../../../utils";

type Size = "xs" | "sm" | "medium" | "large";

const sizes: Record<Size, string> = {
  xs: "h-5 w-5",
  sm: "h-7 w-7",
  medium: "h-9 w-9",
  large: "h-11 w-11",
};
export const UserAvatar: FC<{
  size?: Size;
  photoUrl?: string;
  displayName?: string;
}> = ({ size = "medium", photoUrl, displayName }) => {
  if (photoUrl) {
    return (
      <img
        className={classNames("inline-block rounded-full", sizes[size])}
        src={photoUrl}
        alt=""
      />
    );
  } else if (displayName && displayName.length > 0) {
    return (
      <span
        className={classNames(
          "inline-flex items-center justify-center rounded-full bg-gray-500",
          sizes[size]
        )}
      >
        <span className="font-medium leading-none text-white">
          {displayName[0].toUpperCase()}
        </span>
      </span>
    );
  } else {
    return (
      <span
        className={classNames(
          "inline-block rounded-full overflow-hidden bg-gray-100",
          sizes[size]
        )}
      >
        <svg
          className="h-full w-full text-gray-300"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
    );
  }
};
