import { FC, useState } from "react";
import { MenuIcon } from "@heroicons/react/outline";
import { SidebarDesktop, SidebarMobile, SidebarProps } from "./Sidebar";
import { useOverlay } from "../../overlay";

type AppLayoutProps = {
  sidebar: SidebarProps;
};

export const AppLayout: FC<AppLayoutProps> = ({ sidebar, children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { ModalComponent, NotificationComponent } = useOverlay();

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <p className="absolute w-full z-50 bg-blue-500 h-8 flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <a
          href="https://calendly.com/getcoolsales/60min"
          className="text-sm font-medium text-white hover:underline"
        >
          Réserver votre onboarding pour activer votre compte | Book your
          onboarding to activate your account
        </a>
      </p>
      <SidebarMobile
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        {...sidebar}
      />

      <SidebarDesktop {...sidebar} />

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6">{children}</div>
        </main>
      </div>
      {ModalComponent}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {NotificationComponent}
        </div>
      </div>
    </div>
  );
};
