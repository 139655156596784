import { FC } from "react";
import {
  InformationCircleIcon,
  CheckCircleIcon,
  ExclamationIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { classNames } from "../../../utils";

export type AlertProps = {
  variant: "Info" | "Success" | "Warning" | "Error";
  title: string;
  body: string;
};

export const Alert: FC<AlertProps> = ({ variant, title, body }) => {
  return (
    <div
      className={classNames(
        "rounded-md p-4",
        variant === "Info" ? "bg-blue-50" : "",
        variant === "Success" ? "bg-green-50" : "",
        variant === "Warning" ? "bg-yellow-50" : "",
        variant === "Error" ? "bg-red-50" : ""
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {variant === "Info" ? (
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          ) : variant === "Success" ? (
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          ) : variant === "Warning" ? (
            <ExclamationIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          ) : variant === "Error" ? (
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          ) : null}
        </div>
        <div className="ml-3">
          <h3
            className={classNames(
              "text-sm font-medium text-left",
              variant === "Info" ? "text-blue-800" : "",
              variant === "Success" ? "text-green-800" : "",
              variant === "Warning" ? "text-yellow-800" : "",
              variant === "Error" ? "text-red-800" : ""
            )}
          >
            {title}
          </h3>
          {body && (
            <div
              className={classNames(
                "mt-2 text-sm",
                variant === "Info" ? "text-blue-700" : "",
                variant === "Success" ? "text-green-700" : "",
                variant === "Warning" ? "text-yellow-700" : "",
                variant === "Error" ? "text-red-700" : ""
              )}
            >
              <p>{body}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
