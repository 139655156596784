import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { FirestoreService } from "./firestoreService";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

/* import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"; */

const firebaseStagingConfig = {
  apiKey: "AIzaSyDyXAetOAOOmFwaDrAeu7zlRp_bhjjF7u0",
  authDomain: "staging-api-coolsales.firebaseapp.com",
  projectId: "staging-api-coolsales",
  storageBucket: "staging-api-coolsales.appspot.com",
  messagingSenderId: "561664839628",
  appId: "1:561664839628:web:d50ad56ea756dbe900d769",
};

const firebaseProductionConfig = {
  apiKey: "AIzaSyBOmOQeTtbpM0A6JyGRHBJGKvZH0xai2TI",
  authDomain: "api-coolsales.firebaseapp.com",
  projectId: "api-coolsales",
  storageBucket: "api-coolsales.appspot.com",
  messagingSenderId: "682811864667",
  appId: "1:682811864667:web:592048624d6fae65c8717e",
  measurementId: "G-38LNPEMZG7",
};

const firebaseConfig = process.env.REACT_APP_DEPLOY_IN_PRODUCTION
  ? firebaseProductionConfig
  : firebaseStagingConfig;

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, "europe-west1");
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const perf = getPerformance(app);

/* initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LeF_cMcAAAAAJYUO10OOngM4WBnVybzr_GlcAKp"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
}); */

const logAnalyticsEvent = (
  eventName: Shared.IAnalyticsEvent,
  eventParams?: Record<string, any>
) => logEvent(analytics, eventName as string, eventParams);

const logFirestoreEvent = (
  eventName:
    | "firestore_get"
    | "firestore_list"
    | "firestore_paginatedList"
    | "firestore_create"
    | "firestore_update"
    | "firestore_delete"
    | "firestore_snapshotDocument"
    | "firestore_snapshotList"
    | "firestore_error",
  eventParams: {
    collection: string;
    id?: string;
    numDocs?: number;
    error?: string;
  }
) => logEvent(analytics, eventName, eventParams);

// Connect Firebase Emulator
if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(getFirestore(), "localhost", 8080);
  connectAuthEmulator(getAuth(app), "http://localhost:9099", {
    disableWarnings: true,
  });
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectStorageEmulator(storage, "localhost", 9199);
}

export {
  app,
  db,
  functions,
  storage,
  FirestoreService,
  analytics,
  perf,
  logAnalyticsEvent,
  logFirestoreEvent,
};
