import { FC, useEffect, useState } from "react";
import { useForm, RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router";
import { useUpdateWorkflow } from "..";
import { useOverlay } from "../../overlay";
import { Alert } from "../../ui/alerts";
import { Button } from "../../ui/buttons";
import { Spinner } from "../../ui/icons";
import {
  CustomSelect,
  CustomSelectOption,
  TextArrayField,
  TextField,
} from "../../ui/inputs";

export type TargetFormData = {
  role: string;
  maxCompanySize: number;
  minCompanySize: number;
  contactsLimit: number;
  excludeDomains: { value: string }[];
  excludeNafs: { value: string }[];
  yearsOfExistenceFilter: Shared.JobboardParams["target"]["yearsOfExistenceFilter"];
  yearsOfExistence: number;
};

export const StepTargetForm: FC<{
  isLoadingWorkflow: boolean;
  targetParams?: Shared.JobboardParams["target"];
}> = ({ isLoadingWorkflow, targetParams }) => {
  let {
    params: { workflowId, organisationId },
    url,
  } = useRouteMatch<{ workflowId: string; organisationId: string }>();

  const history = useHistory();

  // UI
  const { t } = useTranslation(["common", "workflow"]);
  const { pushNotification } = useOverlay();
  const yearsOfExistenceFilterOptions: CustomSelectOption[] = [
    {
      id: "noOptionsselected",
      value: t("workflow:formLabel.jobboard.target.noOptionsSelected"),
    },
    {
      id: "moreThan",
      value: t("workflow:formLabel.jobboard.target.moreThan"),
    },
    {
      id: "lessThan",
      value: t("workflow:formLabel.jobboard.target.lessThan"),
    },
  ];
  const [
    selectedYearsOfExistenceFilterOption,
    setSelectedYearsOfExistenceFilterOptions,
  ] = useState<CustomSelectOption>(yearsOfExistenceFilterOptions[0]);

  const { isLoading: isLoadingUpdateWorkflow, update } =
    useUpdateWorkflow(organisationId)();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    trigger,
    setFocus,
    setValue,
  } = useForm<TargetFormData>({
    defaultValues: {
      excludeNafs: [{ value: "" }],
      excludeDomains: [{ value: "" }],
    },
  });

  const minCompanySize = watch("minCompanySize");

  const registerOptions: Record<keyof TargetFormData, RegisterOptions> = {
    role: {
      required: t("workflow:formValidation.required"),
    },
    minCompanySize: {
      required: false,
      min: {
        value: 1,
        message: t("workflow:formValidation.jobboard.minCompanySize"),
      },
    },
    maxCompanySize: {
      required: false,
      min: {
        value: 1,
        message: t("workflow:formValidation.jobboard.maxCompanySize"),
      },
      validate: (value) =>
        Number(minCompanySize) > 0 && value !== ""
          ? Number(minCompanySize) < value ||
            t("workflow:formValidation.jobboard.minMaxCompanySize")
          : true,
    },
    contactsLimit: {
      required: t("workflow:formValidation.required"),
      min: {
        value: 1,
        message: t("workflow:formValidation.jobboard.contactLimit"),
      },
      max: {
        value: 10,
        message: t("workflow:formValidation.jobboard.contactLimit"),
      },
    },
    excludeNafs: {
      required: false,
    },
    excludeDomains: {
      required: false,
    },
    yearsOfExistenceFilter: {
      required: false,
    },
    yearsOfExistence: {
      required: false,
      min: {
        value: 1,
        message: t("workflow:formValidation.target.minimumOneYear"),
      },
      max: 99,
    },
  };

  const onSubmit = handleSubmit((data) => {
    const target: Shared.JobboardParams["target"] = {
      status: "complete",
      role: data.role,
      minCompanySize: data.minCompanySize
        ? Number(data.minCompanySize)
        : undefined,
      maxCompanySize: data.maxCompanySize
        ? Number(data.maxCompanySize)
        : undefined,
      contactsLimit: data.contactsLimit ? Number(data.contactsLimit) : 1,
      excludeNafs: data.excludeNafs
        ? data.excludeNafs
            .map((field) => field.value)
            .filter((value) => value.length > 0)
        : undefined,
      excludeDomains: data.excludeDomains
        ? data.excludeDomains
            .map((field) => field.value)
            .filter((value) => value.length > 0)
        : undefined,
      yearsOfExistenceFilter:
        selectedYearsOfExistenceFilterOption.id === "lessThan"
          ? "lessThan"
          : selectedYearsOfExistenceFilterOption.id === "moreThan"
          ? "moreThan"
          : undefined,
      yearsOfExistence: data.yearsOfExistence
        ? Number(data.yearsOfExistence)
        : undefined,
    };

    update(workflowId, {
      "params.target": target,
    } as Shared.IWorkflow<Shared.JobboardParams>).then(() => {
      pushNotification({
        severity: "success",
        title: t("workflow:addJobboardWorkflowPage.targetStep.success"),
      });
      history.push(url + "?step=output");
    });
  });

  // Populate existing data
  useEffect(() => {
    setValue(
      "excludeNafs",
      targetParams?.excludeNafs
        ? [
            ...targetParams?.excludeNafs.map((naf) => ({
              value: naf,
            })),
            { value: "" },
          ]
        : [{ value: "" }]
    );
    setValue(
      "excludeDomains",
      targetParams?.excludeDomains
        ? [
            ...targetParams?.excludeDomains.map((domain) => ({
              value: domain,
            })),
            { value: "" },
          ]
        : [{ value: "" }]
    );
    if (targetParams?.yearsOfExistenceFilter === "moreThan") {
      setSelectedYearsOfExistenceFilterOptions(
        yearsOfExistenceFilterOptions[1]
      );
    } else if (targetParams?.yearsOfExistenceFilter === "lessThan") {
      setSelectedYearsOfExistenceFilterOptions(
        yearsOfExistenceFilterOptions[2]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetParams?.excludeNafs?.length, targetParams?.excludeDomains?.length]);

  return (
    <section aria-labelledby="job-details">
      <form onSubmit={onSubmit} noValidate>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2
                id="payment-details-heading"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {t("workflow:addJobboardWorkflowPage.targetStep.title")}
              </h2>
              <p className="max-w-2xl text-sm text-gray-500">
                {t("workflow:addJobboardWorkflowPage.targetStep.description")}
              </p>
            </div>
            {isLoadingWorkflow ? (
              <div className="my-10 place-content-center">
                <Spinner size={20} />
              </div>
            ) : (
              <>
                {targetParams?.error && (
                  <Alert
                    variant="Error"
                    title={t(
                      "workflow:addJobboardWorkflowPage.targetStep.errorTitle"
                    )}
                    body={targetParams.error}
                  />
                )}
                <div className="mt-6 grid grid-cols-4 gap-6">
                  <TextField
                    className="col-span-4 lg:col-span-4"
                    type="text"
                    label={t("workflow:formLabel.jobboard.target.role")}
                    name="role"
                    defaultValue={targetParams?.role}
                    placeholder={t(
                      "workflow:formLabel.jobboard.target.rolePlacholder"
                    )}
                    register={register}
                    registerOptions={registerOptions.role}
                    error={errors.role?.message}
                  />
                  <TextField
                    className="col-span-4 lg:col-span-3"
                    type="number"
                    label={t(
                      "workflow:formLabel.jobboard.target.contactsLimit"
                    )}
                    name="contactsLimit"
                    defaultValue={targetParams?.contactsLimit}
                    placeholder={t(
                      "workflow:formLabel.jobboard.target.contactsLimitPlaceholder"
                    )}
                    register={register}
                    registerOptions={registerOptions.contactsLimit}
                    error={errors.contactsLimit?.message}
                  />
                  <TextField
                    className="col-span-4 lg:col-span-2"
                    type="number"
                    label={t(
                      "workflow:formLabel.jobboard.target.minCompanySize"
                    )}
                    name="minCompanySize"
                    defaultValue={targetParams?.minCompanySize}
                    placeholder={t(
                      "workflow:formLabel.jobboard.target.companySizePlaceholder"
                    )}
                    register={register}
                    registerOptions={registerOptions.minCompanySize}
                    error={errors.minCompanySize?.message}
                  />
                  <TextField
                    className="col-span-4 lg:col-span-2"
                    type="number"
                    label={t(
                      "workflow:formLabel.jobboard.target.maxCompanySize"
                    )}
                    name="maxCompanySize"
                    defaultValue={targetParams?.maxCompanySize}
                    placeholder={t(
                      "workflow:formLabel.jobboard.target.companySizePlaceholder"
                    )}
                    register={register}
                    registerOptions={registerOptions.maxCompanySize}
                    error={errors.maxCompanySize?.message}
                  />
                  <CustomSelect
                    className="col-span-2 sm:col-span-2 z-30"
                    fullwidth
                    options={yearsOfExistenceFilterOptions}
                    selected={selectedYearsOfExistenceFilterOption}
                    label={t(
                      "workflow:formLabel.jobboard.target.companyCreated"
                    )}
                    onChange={(option) => {
                      setSelectedYearsOfExistenceFilterOptions(option);
                    }}
                    error={errors.yearsOfExistenceFilter?.message}
                  />
                  <TextField
                    className="col-span-4 lg:col-span-2"
                    type="number"
                    label={t(
                      "workflow:formLabel.jobboard.target.yearsOfExistance"
                    )}
                    name="yearsOfExistence"
                    defaultValue={targetParams?.yearsOfExistence}
                    placeholder="2"
                    register={register}
                    registerOptions={registerOptions.yearsOfExistence}
                    error={errors.yearsOfExistence?.message}
                  />
                  <TextArrayField
                    className="col-span-4 lg:col-span-2"
                    register={register}
                    control={control}
                    registerOptions={registerOptions.excludeNafs}
                    trigger={trigger}
                    setFocus={setFocus}
                    name="excludeNafs"
                    label={t("workflow:formLabel.jobboard.target.excludeNafs")}
                    placeholder={t(
                      "workflow:formLabel.jobboard.target.excludeNafsPlaceholder"
                    )}
                    type="text"
                    errors={errors.excludeNafs}
                  />
                  <TextArrayField
                    className="col-span-4 lg:col-span-2"
                    register={register}
                    control={control}
                    registerOptions={registerOptions.excludeDomains}
                    trigger={trigger}
                    setFocus={setFocus}
                    name="excludeDomains"
                    label={t(
                      "workflow:formLabel.jobboard.target.excludeDomains"
                    )}
                    placeholder={t(
                      "workflow:formLabel.jobboard.target.excludeDomainsPlaceholder"
                    )}
                    type="text"
                    errors={errors.excludeDomains}
                  />
                </div>
              </>
            )}
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <Button
              type="submit"
              size="sm"
              children={t("common:action.next")}
              isLoading={isLoadingWorkflow || isLoadingUpdateWorkflow}
            />
          </div>
        </div>
      </form>
    </section>
  );
};
