import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UserAuthDetails } from "../authentification";
import { PageLayout } from "../ui/layout/PageLayout";
import { UserDetails } from "./userDetails";
import { UserOrganisations, UserOrganisationsProps } from "./UserOrganisations";

type UserSettingsPageProps = {
  pathToChangePasswordModal: string;
  userOrganisationsProps: UserOrganisationsProps;
};

export const UserSettingsPage: FC<UserSettingsPageProps> = ({
  pathToChangePasswordModal,
  userOrganisationsProps,
}) => {
  const { t } = useTranslation(["common", "user"]);
  return (
    <PageLayout title={t("user:UserSettingsPage.title")}>
      <UserDetails />
      <UserOrganisations {...userOrganisationsProps} />
      <UserAuthDetails pathToChangePasswordModal={pathToChangePasswordModal} />
    </PageLayout>
  );
};
