import { FC } from "react";
import { RegisterOptions, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useOverlay } from "../overlay";
import { useCurrentOrganisation } from "./organisationService";
import { OrganisationSettingsPage } from "./OrganisationSettingsPage";
import { InviteUserModal } from "./components/InviteUserModal";
import { EditUserModal } from "./components/EditUserModal";
import { ConfirmationModal } from "../ui/overlay/ConfirmationModal";
import { useAuth } from "../authentification";
import {
  useCurrentOrganisationPendingInvitations,
  useSoftDeleteInvitation,
  useUpdateInvitation,
} from "../invitation";

export type InviteUserFormData = {
  newUserEmail: string;
  newUserRole: Shared.IUserRoles;
};

export type EditUserFormData = {
  userId: string;
  newRole: Shared.IUserRoles;
};

export const OrganisationSettings: FC = () => {
  let { path, url } = useRouteMatch();
  let location = useLocation<{ modal: boolean }>();
  const history = useHistory();
  let displayModal = (location.state && location.state.modal) || false;

  const { authState } = useAuth();

  const onClose = () => history.push(url);

  const {
    isLoadingCurrentOrganisation,
    currentOrganisation,
    editUserRole,
    inviteUser,
  } = useCurrentOrganisation();

  const { isLoadingPendingInvitation, pendingInvitations } =
    useCurrentOrganisationPendingInvitations();

  const { pushNotification } = useOverlay();

  const { t } = useTranslation(["common", "organisation"]);

  const rolesList: Shared.IUserRoles[] = ["VIEWER", "EDITOR", "ADMIN"];

  const { update: updateInvitation } = useUpdateInvitation();

  const { softDelete: deleteInvitation } = useSoftDeleteInvitation();

  const {
    register: registerInviteUser,
    formState: { errors: errorsInviteUser },
    handleSubmit: handleSubmitInviteUser,
    control: controlInviteUser,
  } = useForm<InviteUserFormData>();

  const registerOptionsInviteUser: Record<
    keyof InviteUserFormData,
    RegisterOptions
  > = {
    newUserEmail: {
      required: t("organisation:formValidation.emailRequired"),
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: t("organisation:formValidation.emailPattern"),
      },
    },
    newUserRole: {
      required: t("organisation:formValidation.roleRequired"),
      validate: {
        isValidRole: (value) =>
          rolesList.includes(value) ||
          t("organisation:formValidation.roleInvalid"),
      },
    },
  };

  const onSubmitInviteUser = handleSubmitInviteUser((data) => {
    onClose();
    if (inviteUser) {
      inviteUser(data.newUserEmail, data.newUserRole)
        .then(() =>
          pushNotification({
            severity: "success",
            title: t("organisation:inviteUserModal.invitationSend"),
          })
        )
        .catch((error) =>
          pushNotification({
            severity: "error",
            title: t("common:errors.error"),
            body: t("organisation:errors.onInviteUser"),
          })
        );
    } else {
      pushNotification({
        severity: "warning",
        title: t("common:errors.serviceNotInitialized"),
      });
    }
  });

  const {
    register: registerEditUser,
    formState: { errors: errorsEditUser },
    handleSubmit: handleSubmitEditUser,
    control: controlEditUser,
    reset: resetEditUser,
  } = useForm<EditUserFormData>();

  const registerOptionsEditUser: Record<
    keyof EditUserFormData,
    RegisterOptions
  > = {
    userId: {
      required: true,
    },
    newRole: {
      required: t("organisation:formValidation.roleRequired"),
      validate: {
        isValidRole: (value) =>
          rolesList.includes(value) ||
          t("organisation:formValidation.roleInvalid"),
      },
    },
  };

  const onSubmitEditUser = handleSubmitEditUser((data) => {
    onClose();
    if (data.userId === authState.user?.uid) {
      pushNotification({
        severity: "warning",
        title: t("organisation:errors.onEditHimself"),
      });
    } else {
      if (editUserRole) {
        editUserRole(data.userId, data.newRole)
          .then(() =>
            pushNotification({
              severity: "success",
              title: t("organisation:editUserModal.editSuccess"),
            })
          )
          .catch((error) =>
            pushNotification({
              severity: "error",
              title: t("common:errors.error"),
              body: t("organisation:errors.onEditUser"),
            })
          );
      } else {
        pushNotification({
          severity: "warning",
          title: t("common:errors.serviceNotInitialized"),
        });
      }
    }
  });

  const onDeleteUserConfirmation = (params: Record<string, string>) => {
    onClose();
    if (params.userId) {
      pushNotification({
        severity: "success",
        title: t("organisation:removeUserModal.removeSuccess"),
      });
    } else {
      pushNotification({
        severity: "error",
        title: t("organisation:errors.noUserIdProvider"),
      });
    }
  };

  const handleResendInvitation = (invitationId?: string) => {
    if (invitationId) {
      updateInvitation(invitationId, {
        status: "NEW",
      })
        .then(() =>
          pushNotification({
            severity: "success",
            title: t("organisation:invitationsList.resendSuccess.title"),
            body: t("organisation:invitationsList.resendSuccess.body"),
          })
        )
        .catch((error) =>
          pushNotification({
            severity: "error",
            title: t("organisation:errors.onResendFailed"),
          })
        );
    }
  };

  const handleDeleteInvitation = (invitationId?: string) => {
    if (invitationId) {
      deleteInvitation(invitationId)
        .then(() =>
          pushNotification({
            severity: "success",
            title: t("organisation:invitationsList.deleteSuccess"),
          })
        )
        .catch((error) =>
          pushNotification({
            severity: "error",
            title: t("organisation:errors.onDeleteFailed"),
          })
        );
    }
  };

  return (
    <>
      <OrganisationSettingsPage
        organisationUsersListProps={{
          currentUserRole: authState.currentRole || "VIEWER",
          currentUserId: authState.user?.uid,
          pathToInviteUserModal: `${url}/inviteUser`,
          pathToEditUserRoleModal: `${url}/editUser`,
          pathToRemoveUserModal: `${url}/removeUser`,
          isLoadingUsersList: isLoadingCurrentOrganisation,
          usersList: currentOrganisation?.users,
        }}
        organisationInvitationsListProps={{
          currentUserRole: authState.currentRole || "VIEWER",
          isLoadingPendingInvitation,
          pendingInvitations,
          handleResendInvitation,
          handleDeleteInvitation,
        }}
        subscriptionDetailsProps={{
          currentUserRole: authState.currentRole || "VIEWER",
          leads_left: currentOrganisation?.leads_left,
          subscription: currentOrganisation?.subscription,
          product: currentOrganisation?.product,
          pathToUpgradeSubscription: `${url.replace(
            "/settings",
            ""
          )}/subscription`,
        }}
      />
      <Switch>
        <Route
          path={`${path}/inviteUser`}
          children={
            <InviteUserModal
              roleList={rolesList.map((role) => ({
                id: role,
                value: t(`common:userRole.${role}`),
              }))}
              show={displayModal}
              onClose={onClose}
              control={controlInviteUser}
              register={registerInviteUser}
              registerOptions={registerOptionsInviteUser}
              onSubmit={onSubmitInviteUser}
              errors={errorsInviteUser}
            />
          }
        />
        <Route
          path={`${path}/editUser/:userId`}
          children={
            <EditUserModal
              roleList={rolesList.map((role) => ({
                id: role,
                value: t(`common:userRole.${role}`),
              }))}
              currentRole={{
                id: authState.currentRole || "VIEWER",
                value: authState.currentRole
                  ? (t(`common:userRole.${authState.currentRole}`) as string)
                  : t("common:userRole.VIEWER"),
              }}
              show={displayModal}
              onClose={onClose}
              control={controlEditUser}
              register={registerEditUser}
              registerOptions={registerOptionsEditUser}
              onSubmit={onSubmitEditUser}
              errors={errorsEditUser}
              reset={resetEditUser}
            />
          }
        />
        <Route
          path={`${path}/removeUser/:userId`}
          children={
            <ConfirmationModal
              show={displayModal}
              title={t("organisation:removeUserModal.title")}
              body={t("organisation:removeUserModal.body")}
              action={t("organisation:removeUserModal.action")}
              actionType="red"
              onConfirmation={onDeleteUserConfirmation}
              onClose={onClose}
            />
          }
        />
      </Switch>
    </>
  );
};
