import { FC, ReactNode } from "react";
import { classNames } from "../../../utils";
import { Spinner } from "../icons";

type PageLayoutProps = {
  title: string;
  trailings?: ReactNode[];
  maxWidthDesktop?: MaxDesktopWidth;
  isLoading?: boolean;
};

type MaxDesktopWidth = "max-w-2xl" | "max-w-5xl" | "max-w-7xl";

export const PageLayout: FC<PageLayoutProps> = ({
  title,
  trailings,
  maxWidthDesktop = "max-w-2xl",
  isLoading,
  children,
}) => {
  return (
    <>
      <div className="max-w-7xl mx-auto px-4 pb-4 sm:px-6 md:px-8 flex items-center justify-start space-x-4 flex-wrap sm:flex-nowrap">
        {isLoading ? (
          <div className="justify-start">
            <Spinner size={8} />
          </div>
        ) : (
          <h1 className="text-2xl font-semibold flex-1 text-gray-900">
            {title}
          </h1>
        )}
        {trailings &&
          trailings.map((Trailing, index) => <div key={index}>{Trailing}</div>)}
      </div>
      <div
        className={classNames(
          maxWidthDesktop,
          "mx-auto px-4 space-y-4 sm:space-y-8 sm:px-6 md:px-8"
        )}
      >
        {children}
      </div>
    </>
  );
};
