import { FC, useEffect, useState } from "react";
import {
  useForm,
  RegisterOptions,
  NestedValue,
  Controller,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { useUpdateWorkflow } from "..";
import { useOverlay } from "../../overlay";
import { Alert } from "../../ui/alerts";
import { Button } from "../../ui/buttons";
import { Spinner } from "../../ui/icons";
import {
  CustomSelect,
  CustomSelectOption,
  MultiSelect,
  TextArrayField,
} from "../../ui/inputs";
import { Checkbox } from "../../ui/inputs/Checkbox";
import { countries, areas, departments } from "./poleEmploiReferentiel";
import { FirebaseError } from "@firebase/util";
import { Row, Table } from "../../ui/list/Table";

export type JobFormData = {
  titles: { value: string }[];
  country?: string;
  area?: string;
  departments?: NestedValue<string[]>;
  mustIncludeAllKeywords: { value: string }[];
  mustNotIncludeAnyKeywords: { value: string }[];
  permanent: boolean;
  fixedTerm: boolean;
  apprenticeship: boolean;
  professionnalTraining: boolean;
  temporaryEmployment: boolean;
};

export const StepJobForm: FC<{
  isLoadingWorkflow: boolean;
  jobParams?: Shared.JobboardParams["job"];
}> = ({ isLoadingWorkflow, jobParams }) => {
  // Routing
  let {
    params: { workflowId, organisationId },
    url,
  } = useRouteMatch<{ workflowId: string; organisationId: string }>();
  const history = useHistory();

  // UI
  const { t } = useTranslation(["common", "workflow"]);
  const { pushNotification } = useOverlay();
  const geoFiltersOptions: CustomSelectOption[] = [
    {
      id: "country",
      value: t("workflow:formLabel.jobboard.job.geoFilterOptions.country"),
    },
    {
      id: "area",
      value: t("workflow:formLabel.jobboard.job.geoFilterOptions.area"),
    },
    {
      id: "departments",
      value: t("workflow:formLabel.jobboard.job.geoFilterOptions.departments"),
    },
  ];
  const [selectedGeoFilterOption, setSelectedGeoFilterOption] =
    useState<CustomSelectOption>(geoFiltersOptions[0]);
  const [getSearchResultsLoading, setGetSearchResultsLoading] = useState(false);
  const [searchResults, setSearchResults] =
    useState<CoolsalesCallable.PoleEmploi.getSearchResultsResponse | null>(
      null
    );
  const searchResultsHeads = [
    { name: t("workflow:formLabel.jobboard.job.title") },
    { name: t("workflow:formLabel.jobboard.job.jobType") },
  ];

  // Workflow
  const { isLoading: isLoadingUpdateWorkflow, update } =
    useUpdateWorkflow(organisationId)();

  // Form
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    trigger,
    setFocus,
    setValue,
  } = useForm<JobFormData>({
    defaultValues: {
      titles: [{ value: "" }],
      mustIncludeAllKeywords: [{ value: "" }],
      mustNotIncludeAnyKeywords: [{ value: "" }],
      country: countries[0].code,
    },
  });
  const titles = watch("titles");
  const mustIncludeAllKeywords = watch("mustIncludeAllKeywords");
  const mustNotIncludeAnyKeywords = watch("mustNotIncludeAnyKeywords");
  const permanent = watch("permanent");
  const fixedTerm = watch("fixedTerm");
  const apprenticeship = watch("apprenticeship");
  const professionnalTraining = watch("professionnalTraining");
  const temporaryEmployment = watch("temporaryEmployment");
  const selectedCountryId = watch("country");
  const selectedCountry = countries.find(
    (country) => country.code === selectedCountryId
  );
  const selectedAreaId = watch("area");
  const selectedArea = areas.find((area) => area.code === selectedAreaId);
  const selectedDepartments = watch("departments");

  const atLeastOneTypeOfContract = () =>
    // At least on type of job is selected
    [
      permanent,
      fixedTerm,
      apprenticeship,
      professionnalTraining,
      temporaryEmployment,
    ].includes(true) ||
    t("workflow:formValidation.jobboard.atLeastOnTypeOfJob");

  const registerOptions: Record<keyof JobFormData, RegisterOptions> = {
    titles: {
      // At least on value
      validate: () =>
        titles[0].value !== "" || t("workflow:formValidation.required"),
    },
    country: {
      required: t("workflow:formValidation.required"),
    },
    area: {
      required: true,
    },
    departments: {
      required: false,
      validate: () =>
        selectedDepartments === undefined
          ? true
          : selectedDepartments.length <= 5
          ? true
          : t("workflow:formValidation.jobboard.max5deparments"),
    },
    mustIncludeAllKeywords: {
      required: false,
    },
    mustNotIncludeAnyKeywords: {
      required: false,
    },
    permanent: {
      required: false,
      validate: atLeastOneTypeOfContract,
    },
    fixedTerm: {
      required: false,
      validate: atLeastOneTypeOfContract,
    },
    apprenticeship: {
      required: false,
      validate: atLeastOneTypeOfContract,
    },
    professionnalTraining: {
      required: false,
      validate: atLeastOneTypeOfContract,
    },
    temporaryEmployment: {
      required: false,
      validate: atLeastOneTypeOfContract,
    },
  };

  // Populate existing data
  useEffect(() => {
    setValue("departments", jobParams?.location?.departments);
    setValue("area", jobParams?.location?.area);
    setValue(
      "titles",
      jobParams?.titles
        ? [
            ...jobParams?.titles.map((keyword) => ({
              value: keyword,
            })),
            { value: "" },
          ]
        : [{ value: "" }]
    );
    setValue(
      "mustIncludeAllKeywords",
      jobParams?.mustIncludeAllKeywords
        ? [
            ...jobParams?.mustIncludeAllKeywords.map((keyword) => ({
              value: keyword,
            })),
            { value: "" },
          ]
        : [{ value: "" }]
    );
    setValue(
      "mustNotIncludeAnyKeywords",
      jobParams?.mustNotIncludeAnyKeywords
        ? [
            ...jobParams?.mustNotIncludeAnyKeywords.map((keyword) => ({
              value: keyword,
            })),
            { value: "" },
          ]
        : [{ value: "" }]
    );

    if (jobParams?.location?.area) {
      setSelectedGeoFilterOption(geoFiltersOptions[1]);
    } else if (jobParams?.location?.departments) {
      setSelectedGeoFilterOption(geoFiltersOptions[2]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    jobParams?.location?.country,
    jobParams?.location?.area,
    jobParams?.location?.departments?.length,
    jobParams?.mustIncludeAllKeywords?.length,
    jobParams?.mustNotIncludeAnyKeywords?.length,
    jobParams?.titles?.length,
  ]);

  const onSubmit = handleSubmit((data) => {
    const job: Shared.JobboardParams["job"] = {
      status: "complete",
      titles: data.titles
        .map((field) => field.value)
        .filter((value) => value.length > 0),
      location: {
        country: data.country,
        area: data.area,
        departments: data.departments,
      },
      mustIncludeAllKeywords: data.mustIncludeAllKeywords
        ? data.mustIncludeAllKeywords
            .map((field) => field.value)
            .filter((value) => value.length > 0)
        : undefined,
      mustNotIncludeAnyKeywords: data.mustNotIncludeAnyKeywords
        ? data.mustNotIncludeAnyKeywords
            .map((field) => field.value)
            .filter((value) => value.length > 0)
        : undefined,
      type: {
        permanent: data.permanent,
        fixedTerm: data.fixedTerm,
        apprenticeship: data.apprenticeship,
        professionnalTraining: data.professionnalTraining,
        temporaryEmployment: data.temporaryEmployment,
      },
    };

    update(workflowId, {
      "params.job": job,
    } as Shared.IWorkflow<Shared.JobboardParams>).then(() => {
      pushNotification({
        severity: "success",
        title: t("workflow:addJobboardWorkflowPage.jobStep.success"),
      });
      history.push(url + "?step=target");
    });
  });

  const getSearchResults = httpsCallable<
    CoolsalesCallable.PoleEmploi.getSearchResultsData,
    CoolsalesCallable.PoleEmploi.getSearchResultsResponse
  >(functions, "poleEmploi-getSearchResults");

  const handleGetSearchResults = async () => {
    setGetSearchResultsLoading(true);
    if (await trigger()) {
      try {
        const jobResults = await getSearchResults({
          titles: titles
            .map((field) => field.value)
            .filter((value) => value.length > 0),
          location: {
            country: selectedCountryId,
            area: selectedAreaId,
            departments: selectedDepartments,
          },
          mustIncludeAllKeywords: mustIncludeAllKeywords
            ? mustIncludeAllKeywords
                .map((field) => field.value)
                .filter((value) => value.length > 0)
            : undefined,
          mustNotIncludeAnyKeywords: mustNotIncludeAnyKeywords
            ? mustNotIncludeAnyKeywords
                .map((field) => field.value)
                .filter((value) => value.length > 0)
            : undefined,
          type: {
            permanent: permanent,
            fixedTerm: fixedTerm,
            apprenticeship: apprenticeship,
            professionnalTraining: professionnalTraining,
            temporaryEmployment: temporaryEmployment,
          },
          publieeDepuis: "7",
        });

        setSearchResults(jobResults.data);
      } catch (error) {
        pushNotification({
          severity: "warning",
          title:
            (error as FirebaseError).code === "functions/invalid-argument"
              ? t(
                  "workflow:addJobboardWorkflowPage.jobStep.getSearchResultInvalidArguments"
                )
              : t(
                  "workflow:addJobboardWorkflowPage.jobStep.getSearchResultError"
                ),
        });
      }
    } else {
      pushNotification({
        severity: "warning",
        title: t(
          "workflow:addJobboardWorkflowPage.jobStep.getSearchResultInvalidArguments"
        ),
      });
    }

    setGetSearchResultsLoading(false);
  };

  return (
    <section aria-labelledby="job-details">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <form onSubmit={onSubmit} noValidate>
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2
                id="payment-details-heading"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {t("workflow:addJobboardWorkflowPage.jobStep.title")}
              </h2>
              <p className="max-w-2xl text-sm text-gray-500">
                {t("workflow:addJobboardWorkflowPage.jobStep.description")}
              </p>
            </div>
            {isLoadingWorkflow ? (
              <div className="my-10 place-content-center">
                <Spinner size={20} />
              </div>
            ) : (
              <>
                {jobParams?.error && (
                  <Alert
                    variant="Error"
                    title={t(
                      "workflow:addJobboardWorkflowPage.jobStep.errorTitle"
                    )}
                    body={jobParams.error}
                  />
                )}
                <div className="mt-6 grid grid-cols-4 gap-6">
                  <TextArrayField
                    className="col-span-4"
                    register={register}
                    control={control}
                    registerOptions={registerOptions.titles}
                    trigger={trigger}
                    setFocus={setFocus}
                    name="titles"
                    label={t("workflow:formLabel.jobboard.job.title")}
                    placeholder={t(
                      "workflow:formLabel.jobboard.job.titlePlaceholder"
                    )}
                    type="text"
                    errors={errors.titles}
                  />
                  <TextArrayField
                    className="col-span-4"
                    register={register}
                    control={control}
                    registerOptions={registerOptions.mustIncludeAllKeywords}
                    trigger={trigger}
                    setFocus={setFocus}
                    name="mustIncludeAllKeywords"
                    label={t(
                      "workflow:formLabel.jobboard.job.mustIncludeAllKeywords"
                    )}
                    placeholder={t(
                      "workflow:formLabel.jobboard.job.keywordPlaceholder"
                    )}
                    type="text"
                    errors={errors.mustIncludeAllKeywords}
                  />
                  <TextArrayField
                    className="col-span-4"
                    register={register}
                    control={control}
                    registerOptions={registerOptions.mustNotIncludeAnyKeywords}
                    trigger={trigger}
                    setFocus={setFocus}
                    name="mustNotIncludeAnyKeywords"
                    label={t(
                      "workflow:formLabel.jobboard.job.mustNotIncludeAnyKeywords"
                    )}
                    placeholder={t(
                      "workflow:formLabel.jobboard.job.keywordPlaceholder"
                    )}
                    type="text"
                    errors={errors.mustNotIncludeAnyKeywords}
                  />
                  <CustomSelect
                    className="col-span-2 sm:col-span-2 z-30"
                    fullwidth
                    options={geoFiltersOptions}
                    selected={selectedGeoFilterOption}
                    label={t("workflow:formLabel.jobboard.job.geoFilter")}
                    onChange={(option) => {
                      setSelectedGeoFilterOption(option);
                      switch (option.id) {
                        case "area":
                          setValue("departments", undefined);
                          setValue("country", undefined);
                          break;
                        case "departments":
                          setValue("area", undefined);
                          setValue("country", undefined);
                          break;

                        case "country":
                          setValue("departments", undefined);
                          setValue("area", undefined);
                          setValue("country", countries[0].code);
                          break;

                        default:
                          setValue("country", undefined);
                          setValue("area", undefined);
                          setValue("departments", undefined);
                          break;
                      }
                    }}
                  />
                  {selectedGeoFilterOption.id === "country" ? (
                    <Controller
                      control={control}
                      name="country"
                      render={({ field: { onChange } }) => {
                        return (
                          <CustomSelect
                            fullwidth
                            className="col-span-2 sm:col-span-2 z-20"
                            position="below"
                            label={t("workflow:formLabel.jobboard.job.country")}
                            options={countries.map((area) => ({
                              id: area.code,
                              value: area.libelle,
                            }))}
                            selected={
                              selectedCountry
                                ? {
                                    id: selectedCountry?.code,
                                    value: selectedCountry?.libelle,
                                  }
                                : {
                                    id: countries[0].code,
                                    value: countries[0].libelle,
                                  }
                            }
                            onChange={(option) => onChange(option.id)}
                          />
                        );
                      }}
                    />
                  ) : selectedGeoFilterOption.id === "area" ? (
                    <Controller
                      control={control}
                      name="area"
                      render={({ field: { onChange } }) => {
                        return (
                          <CustomSelect
                            fullwidth
                            className="col-span-2 sm:col-span-2 z-20"
                            position="below"
                            label={t("workflow:formLabel.jobboard.job.area")}
                            options={areas.map((area) => ({
                              id: area.code,
                              value: area.libelle,
                            }))}
                            selected={
                              selectedArea
                                ? {
                                    id: selectedArea?.code,
                                    value: selectedArea?.libelle,
                                  }
                                : undefined
                            }
                            onChange={(option) => onChange(option.id)}
                          />
                        );
                      }}
                    />
                  ) : selectedGeoFilterOption.id === "departments" ? (
                    <MultiSelect
                      register={register}
                      registerOptions={registerOptions.departments}
                      className="col-span-4 lg:col-span-2"
                      name="departments"
                      selectedOptions={selectedDepartments}
                      options={departments.map((department) => ({
                        id: department.code,
                        value: department.libelle,
                      }))}
                      label={t("workflow:formLabel.jobboard.job.departments")}
                      error={errors.departments?.message}
                    />
                  ) : null}

                  <p className="col-span-4 text-sm text-left font-medium text-gray-700">
                    {t("workflow:formLabel.jobboard.job.jobType")}{" "}
                    {errors.permanent?.message && (
                      <span className="text-xs text-red-500 font-light">
                        {errors.permanent?.message}
                      </span>
                    )}
                  </p>
                  <Checkbox
                    className="col-span-2 xl:col-span-1"
                    name="permanent"
                    label={t("workflow:formLabel.jobboard.job.permanent")}
                    register={register}
                    registerOptions={registerOptions.permanent}
                    defaultChecked={jobParams?.type && jobParams.type.permanent}
                  />
                  <Checkbox
                    className="col-span-2 xl:col-span-1"
                    name="fixedTerm"
                    label={t("workflow:formLabel.jobboard.job.fixedTerm")}
                    register={register}
                    registerOptions={registerOptions.fixedTerm}
                    defaultChecked={jobParams?.type && jobParams.type.fixedTerm}
                  />
                  <Checkbox
                    className="col-span-2 xl:col-span-1"
                    name="apprenticeship"
                    label={t("workflow:formLabel.jobboard.job.apprenticeship")}
                    register={register}
                    registerOptions={registerOptions.apprenticeship}
                    defaultChecked={
                      jobParams?.type && jobParams.type.apprenticeship
                    }
                  />
                  <Checkbox
                    className="col-span-2 xl:col-span-1"
                    name="professionnalTraining"
                    label={t(
                      "workflow:formLabel.jobboard.job.professionnalTraining"
                    )}
                    register={register}
                    registerOptions={registerOptions.professionnalTraining}
                    defaultChecked={
                      jobParams?.type && jobParams.type.professionnalTraining
                    }
                  />
                  <Checkbox
                    className="col-span-2 xl:col-span-1"
                    name="temporaryEmployment"
                    label={t(
                      "workflow:formLabel.jobboard.job.temporaryEmployment"
                    )}
                    register={register}
                    registerOptions={registerOptions.temporaryEmployment}
                    defaultChecked={
                      jobParams?.type && jobParams.type.temporaryEmployment
                    }
                  />
                </div>
              </>
            )}
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <div className="flex justify-between gap-4 items-center">
              <Button
                type="button"
                variant="secondary"
                onClick={handleGetSearchResults}
                size="sm"
                children={t(
                  "workflow:addJobboardWorkflowPage.jobStep.getSearchResult"
                )}
                isLoading={getSearchResultsLoading}
              />
              {searchResults && (
                <p className="text-sm text-gray-600 text-left">
                  {t(
                    "workflow:addJobboardWorkflowPage.jobStep.getSearchResultData",
                    { total: searchResults.length }
                  )}
                </p>
              )}
              <Button
                type="submit"
                size="sm"
                children={t("common:action.next")}
                isLoading={isLoadingWorkflow || isLoadingUpdateWorkflow}
              />
            </div>
          </div>
        </form>
      </div>
      {searchResults && searchResults.length > 0 && (
        <div className="mt-6 shadow sm:rounded-md sm:overflow-hidden">
          <Table
            heads={searchResultsHeads}
            rows={searchResults.slice(0, 15).map(
              (result): Row => [
                {
                  type: "text",
                  value: result.jobIntitule,
                },
                {
                  type: "text",
                  value: result.jobTypeContrat,
                },
              ]
            )}
          />
        </div>
      )}
    </section>
  );
};
