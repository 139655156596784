import { FC } from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { OfficeBuildingIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "../ui/buttons";
import { Row, Table } from "../ui/list/Table";
import { usePaginatedList } from "./LeadService";

export type LeadItem = {
  pathToLead: string;
  name: string;
  lastEvent: string;
  contactsNumber: number;
};

export const LeadsTable: FC = () => {
  // Routing
  let {
    params: { organisationId },
    url,
  } = useRouteMatch<{ organisationId: string }>();

  //UI
  const { t } = useTranslation(["common", "lead"]);

  // Leads
  const {
    isLoading,
    currentData: leads,
    limit,
    setLimit,
    showingFrom,
    showingTo,
    total,
    hasPrev,
    hasNext,
    getNextData,
    getPrevData,
  } = usePaginatedList(organisationId)();

  const heads = [
    { name: t("lead:leadTable.name") },
    { name: t("lead:leadTable.lastEvents") },
    { name: t("lead:leadTable.contacts") },
  ];

  return (
    <>
      <Table
        heads={heads}
        isLoading={isLoading}
        paginationProps={{
          isLoading,
          perPage: limit,
          total,
          from: showingFrom,
          to: showingTo,
          onChangePerPage: setLimit,
          handleNext: hasNext ? getNextData : undefined,
          handlePrev: hasPrev ? getPrevData : undefined,
        }}
        rows={
          leads &&
          leads.map(
            (lead): Row => [
              {
                type: "link",
                value: lead.companyData?.name || "",
                link: {
                  to: `${url}/${lead.id}`,
                },
              },
              {
                type: "text",
                value: getLastEventDate(lead.events)
                  ? t("lead:leadTable.occuredOn", {
                      date: getLastEventDate(lead.events),
                    })
                  : "",
              },
              {
                type: "number",
                value: lead.contacts
                  ? Object.values(lead.contacts).length.toString()
                  : "0",
              },
            ]
          )
        }
      />
      {!isLoading && leads?.length === 0 && (
        <div className="text-center">
          <OfficeBuildingIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            {t("lead:leadsPage.noLeads")}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t("lead:leadsPage.getStarted")}
          </p>
          <div className="mt-6">
            <Link to={`/organisations/${organisationId}/workflows`}>
              <Button type="button">
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                {t("lead:leadsPage.newWorkflow")}
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

const getLastEventDate = (events?: Shared.ILead["events"]) => {
  if (events && Object.values(events).length > 0) {
    const occuredOnDates = Object.values(events).map(
      (events) => events.occuredOn
    );
    return occuredOnDates
      .sort((occuredOnA, occuredOnB) =>
        occuredOnA && occuredOnB && occuredOnA > occuredOnB ? 1 : -1
      )[0]
      ?.toDate();
  }
};
