import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { env } from "process";
import moment from "moment";
import "moment/locale/fr";

/**
 * i18next instance
 * see : https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb
 */
i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: env.NODE_ENV === "development",
    supportedLngs: ["en", "fr"],
    fallbackLng: "en",
    load: "currentOnly",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if (value instanceof Date) {
          return moment(value)
            .locale(lng ? lng : "en")
            .format(format);
        }
        return value;
      },
    },
    defaultNS: "common",
    ns: ["common", "authentification", "user"],
  });

export default i18n;
