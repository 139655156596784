import { FC, useState } from "react";
import { useCurrentOrganisation } from "../organisation/organisationService";
import { RegisterOptions, useForm } from "react-hook-form";
import { Modal } from "../ui/overlay";
import { Button } from "../ui/buttons";
import { TextField } from "../ui/inputs";
import { useTranslation } from "react-i18next";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { TextFieldWithAction } from "../ui/inputs/TextFieldWithAction";
import { RefreshIcon } from "@heroicons/react/solid";
import { Spinner } from "../ui/icons";

type LgmAddIntegrationModalData = {
  apiKey: string;
  name: string;
};

export const LgmAddIntegrationModal: FC<{
  show: boolean;
  onClose: () => void;
}> = ({ show, onClose }) => {
  // UI
  const { t } = useTranslation(["common", "integration"]);
  const [isFetchingAudiences, setIsFetchingAudiences] = useState(false);
  const [isValidApiKey, setIsValidApiKey] = useState(false);

  // Form

  const listLgmAudiences = httpsCallable<
    CoolsalesCallable.LGM.ListAudiencesData,
    CoolsalesCallable.LGM.ListAudiencesResponse
  >(functions, "salesAutomation-lgmListData");

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setError,
    clearErrors,
    reset,
  } = useForm<LgmAddIntegrationModalData>();

  const apiKey = watch("apiKey");

  const registerOptions: Record<
    keyof LgmAddIntegrationModalData,
    RegisterOptions
  > = {
    apiKey: {
      required: t("integration:fomrValidation.apiKeyRequired"),
    },
    name: {
      required: t("integration:fomrValidation.nameRequired"),
      validate: () =>
        isValidApiKey || t("integration:fomrValidation.wrongApiKey"),
    },
  };

  const { addLGMIntegration, isLoadingUpdate } = useCurrentOrganisation();

  const handleFetchCampaignList = async () => {
    if (!apiKey) {
      setError("apiKey", {
        message: t("integration:fomrValidation.apiKeyRequired"),
      });
    } else {
      setIsFetchingAudiences(true);
      try {
        const response = await listLgmAudiences({ apiKey });
        if (response && response.data) {
          clearErrors("apiKey");
          reset({ apiKey });
          setIsValidApiKey(true);
          setIsFetchingAudiences(false);
        }
      } catch (error) {
        setIsFetchingAudiences(false);
        setIsValidApiKey(false);
        setError("apiKey", {
          message: t("integration:fomrValidation.wrongApiKey"),
        });
      }
    }
  };

  const onSubmit = handleSubmit((data) => {
    if (addLGMIntegration && data.apiKey && data.name) {
      addLGMIntegration(data.apiKey, data.name);
      onClose();
    }
    reset({ apiKey: "", name: "" });
  });

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={t("integration:lgmModal.title")}
      size="md"
    >
      <form className="space-y-6" onSubmit={onSubmit}>
        <TextFieldWithAction
          name="apiKey"
          type="text"
          register={register}
          registerOptions={registerOptions.apiKey}
          action={handleFetchCampaignList}
          actionIcon={
            isFetchingAudiences ? (
              <Spinner size={5} />
            ) : (
              <RefreshIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )
          }
          error={errors.apiKey?.message}
          label={t("integration:formLabel.apiKey")}
        />
        <TextField
          className="col-span-4 lg:col-span-2"
          type="text"
          label={t("integration:formLabel.name")}
          name="name"
          register={register}
          registerOptions={registerOptions.name}
          error={errors.name?.message}
        />
        <Button
          type="submit"
          children={t("common:action.save")}
          size="sm"
          isLoading={isLoadingUpdate}
        />
      </form>
    </Modal>
  );
};
