import { FC, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "../../../utils";
import { Link } from "react-router-dom";
import { UserAvatar } from "../icons";

export type MenuItem = {
  name: string;
  path?: string;
  onClick?: () => Promise<void> | void;
};

type UserMenuProps = {
  userNavigation: MenuItem[];
  displayName?: string | null;
  organisationName?: string;
  photoUrl?: string | null;
};

export const UserMenu: FC<UserMenuProps> = ({
  userNavigation,
  displayName,
  organisationName,
  photoUrl,
}) => {
  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none">
          <span className="sr-only">Open user menu</span>
          <div>
            <UserAvatar
              size="medium"
              displayName={displayName || undefined}
              photoUrl={photoUrl || undefined}
            />
          </div>
          <div className="ml-3">
            {displayName && (
              <p className="text-sm text-left font-medium text-gray-700 group-hover:text-gray-900">
                {displayName}
              </p>
            )}
            <p className="text-xs text-left font-medium text-gray-500 group-hover:text-gray-700">
              {organisationName}
            </p>
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-bottom-right absolute left-auto bottom-10 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map(getMenuItem)}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const getMenuItem: FC<MenuItem> = ({ name, onClick, path }) => {
  if (path) {
    return (
      <Menu.Item key={name}>
        {({ active }) => (
          <Link
            to={path}
            className={classNames(
              active ? "bg-gray-100" : "",
              "block px-4 py-2 text-sm text-gray-700"
            )}
          >
            {name}
          </Link>
        )}
      </Menu.Item>
    );
  } else if (onClick) {
    return (
      <Menu.Item key={name}>
        {({ active }) => (
          <button
            onClick={onClick}
            className={classNames(
              active ? "bg-gray-100" : "",
              "block w-full text-left px-4 py-2 text-sm text-gray-700"
            )}
          >
            {name}
          </button>
        )}
      </Menu.Item>
    );
  } else {
    return (
      <Menu.Item key={name}>
        {({ active }) => (
          <p
            className={classNames(
              active ? "bg-gray-100" : "",
              "block w-full text-left px-4 py-2 text-sm text-gray-700"
            )}
          >
            {name}
          </p>
        )}
      </Menu.Item>
    );
  }
};
