/**
 * Return a copy of the object without any undefined value
 * Work recusrivly on nested object
 * @remarks
 * Array and field value are ignored
 * source : https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
 * @param o The object
 * @returns
 */
export const removeUndefined = (
  obj: Record<string, any>
): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [
        k,
        v === Object(v) && !Array.isArray(v) && !v["_methodName"]
          ? removeUndefined(v)
          : v,
      ])
  );
};
