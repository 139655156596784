import { FC } from "react";
import { Button } from "../ui/buttons";
import {
  IntegrationsList,
  IntegrationsListProps,
} from "./components/IntegrationsList";
import { Link } from "react-router-dom";
import { PageLayout } from "../ui/layout/PageLayout";
import { hasNotAccessBelow } from "../../utils";
import { useTranslation } from "react-i18next";
import linkedinLogo from "./components/linkedin-logo.png";
import lemlistLogo from "./components/lemlist-logo.png";
import datananasLogo from "./components/datananas-logo.png";
import lgmLogo from "./components/lgm-logo.png";
import emeliaLogo from "./components/emelia-logo.png";

type IntegrationsPageProps = {
  linkedinIntegrations: IntegrationsListProps[];
  lemlistIntegrations: IntegrationsListProps[];
  datananasIntegrations: IntegrationsListProps[];
  lgmIntegrations: IntegrationsListProps[];
  emeliaIntegrations: IntegrationsListProps[];
  pathToChooseIntegrationModal: string;
  currentUserRole: Shared.IUserRoles;
};

export const IntegrationsPage: FC<IntegrationsPageProps> = ({
  linkedinIntegrations,
  lemlistIntegrations,
  datananasIntegrations,
  lgmIntegrations,
  emeliaIntegrations,
  pathToChooseIntegrationModal,
  currentUserRole,
}) => {
  const { t } = useTranslation(["common", "integration"]);
  return (
    <>
      <PageLayout
        title={t("integration:integrationPage.title")}
        trailings={[
          <Link
            to={{
              pathname: pathToChooseIntegrationModal,
              state: { modal: true },
            }}
            children={
              <Button
                type="button"
                children={t("integration:integrationPage.addIntegration")}
                size="sm"
                disbaled={hasNotAccessBelow(currentUserRole, "EDITOR")}
              />
            }
          />,
        ]}
      >
        {linkedinIntegrations.length > 0 && (
          <>
            <div className="py-4 flex align-middle items-center px-2 gap-2">
              <img className="w-8 h-8" src={linkedinLogo} alt="linkedin-logo" />
              <h2 className="text-xl font-semibold text-gray-900">LinkedIn</h2>
            </div>
            <IntegrationsList
              integrations={linkedinIntegrations}
              currentUserRole={currentUserRole}
            />
          </>
        )}
        {lemlistIntegrations.length > 0 && (
          <>
            <div className="py-4 flex align-middle items-center px-2 gap-2">
              <img className="w-8 h-8" src={lemlistLogo} alt="lemlist-logo" />
              <h2 className="text-xl font-semibold text-gray-900">Lemlist</h2>
            </div>
            <IntegrationsList
              integrations={lemlistIntegrations}
              currentUserRole={currentUserRole}
            />
          </>
        )}
        {datananasIntegrations.length > 0 && (
          <>
            <div className="py-4 flex align-middle items-center px-2 gap-2">
              <img
                className="w-8 h-8"
                src={datananasLogo}
                alt="datananas-logo"
              />
              <h2 className="text-xl font-semibold text-gray-900">Datananas</h2>
            </div>
            <IntegrationsList
              integrations={datananasIntegrations}
              currentUserRole={currentUserRole}
            />
          </>
        )}
        {lgmIntegrations.length > 0 && (
          <>
            <div className="py-4 flex align-middle items-center px-2 gap-2">
              <img
                className="w-8 h-8"
                src={lgmLogo}
                alt="lagrowthmachine-logo"
              />
              <h2 className="text-xl font-semibold text-gray-900">
                La Growth Machine
              </h2>
            </div>
            <IntegrationsList
              integrations={lgmIntegrations}
              currentUserRole={currentUserRole}
            />
          </>
        )}
        {emeliaIntegrations.length > 0 && (
          <>
            <div className="py-4 flex align-middle items-center px-2 gap-2">
              <img className="w-8 h-8" src={emeliaLogo} alt="emelia-logo" />
              <h2 className="text-xl font-semibold text-gray-900">Emelia</h2>
            </div>
            <IntegrationsList
              integrations={emeliaIntegrations}
              currentUserRole={currentUserRole}
            />
          </>
        )}
      </PageLayout>
    </>
  );
};
