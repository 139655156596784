import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { currentEnvironment } from "../config";
import { logAnalyticsEvent } from "../firebase";
import { Notification } from "../ui/overlay";
import { Modal, ModalProps } from "../ui/overlay/Modal";

export type NotificationProps = {
  severity?: "info" | "success" | "warning" | "error";
  title?: string;
  body?: string;
  action?: React.EventHandler<React.MouseEvent | React.TouchEvent>;
};

export type OverlayModal = {
  title: string;
  content: ReactNode;
  size?: ModalProps["size"];
};

/**
 * Overlay context that encapsulate the overlay state and all required methods
 */
const OverlayContext = createContext<
  | {
      status: "IDLE" | "BUSY";
      setApplicationStatus: (status: "IDLE" | "BUSY") => void;
      pushNotification: (notification: NotificationProps) => void;
      showModal: (modal: OverlayModal) => void;
      closeModal: () => void;
      ModalComponent: ReactNode;
      NotificationComponent: ReactNode;
    }
  | undefined
>(undefined);

/**
 * Context provider for overlay
 * useOverlay hook can be used inside this provider to access overlay state and methods
 */
export const OverlayProvider: FC = ({ children }) => {
  const [status, setStatus] = useState<"IDLE" | "BUSY">("IDLE");
  const [notification, setNotification] = useState<NotificationProps>();
  const [notificationStack, setNotificationStack] = useState<
    NotificationProps[]
  >([]);
  const [notificationDisplayed, setNotificationDisplayed] = useState(false);

  const [displayModal, setDisplayModal] = useState(false);
  const [modal, setModal] = useState<OverlayModal>({
    title: "",
    content: "",
  });

  const pushNotification = (notification: NotificationProps) => {
    setNotificationStack([...notificationStack, notification]);
  };

  const closeNotification = () => {
    setNotification(undefined);
    setNotificationDisplayed(false);
  };

  const showModal = (overlayModal: OverlayModal) => {
    setModal({
      ...overlayModal,
    });
    setDisplayModal(true);
  };

  const closeModal = () => {
    setDisplayModal(false);
    setModal({
      title: "",
      content: "",
    });
  };

  const setApplicationStatus = (status: "IDLE" | "BUSY") => setStatus(status);

  useEffect(() => {
    currentEnvironment !== "production" && console.log("Overlay hook");
    if (notificationStack.length === 1 && !notificationDisplayed) {
      setNotification(notificationStack[0]);
      setNotificationDisplayed(true);
      setNotificationStack([...notificationStack.slice(1)]);
      setTimeout(() => {
        setNotification(undefined);
        setNotificationDisplayed(false);
      }, 4000);
      logAnalyticsEvent("show_notification");
    }
  }, [notificationStack, notificationDisplayed]);

  const ModalComponent = (
    <Modal
      title={modal.title}
      show={displayModal}
      size={modal.size}
      onClose={closeModal}
      children={modal.content}
    />
  );

  const NotificationComponent = (
    <Notification
      show={notificationDisplayed}
      onClose={closeNotification}
      severity={notification?.severity}
      action={notification?.action}
      title={notification?.title}
      body={notification?.body}
    />
  );

  return (
    <OverlayContext.Provider
      value={{
        status,
        setApplicationStatus,
        pushNotification,
        showModal,
        closeModal,
        ModalComponent,
        NotificationComponent,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
};

/**
 * Auth hook to access the authentification state
 * and firebase authentification methods
 */
export const useOverlay = () => {
  const context = useContext(OverlayContext);
  if (context === undefined) {
    throw new Error("useOverlay must be used within OveralyContext");
  }
  return context;
};
