import { FC, useState } from "react";
import {
  UseFormRegister,
  RegisterOptions,
  Control,
  Controller,
  FieldErrors,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../ui/buttons";
import { CustomSelect, TextField } from "../../ui/inputs";
import { Modal } from "../../ui/overlay";
import type { InviteUserFormData } from "../../organisation/OrganisationSettings";

export const InviteUserModal: FC<{
  roleList: { id: string | number; value: string | number }[];
  show: boolean;
  onClose: () => void;
  control: Control<InviteUserFormData, object>;
  register: UseFormRegister<InviteUserFormData>;
  registerOptions: Record<keyof InviteUserFormData, RegisterOptions>;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  errors: FieldErrors<InviteUserFormData>;
}> = ({
  roleList,
  show,
  onClose,
  control,
  register,
  registerOptions,
  onSubmit,
  errors,
}) => {
  const { t } = useTranslation(["common", "organisation"]);
  const [selectedRole, setSelectedRole] = useState(roleList[0]);
  return (
    <Modal
      show={show}
      onClose={onClose}
      title={t("organisation:inviteUserModal.title")}
      size="sm"
    >
      <form className="space-y-6 text-left" onSubmit={onSubmit} noValidate>
        <Controller
          control={control}
          name="newUserRole"
          defaultValue="VIEWER"
          render={({ field: { onChange } }) => {
            return (
              <CustomSelect
                className="col-span3 sm:col-span-2"
                position="below"
                label={t("organisation:formLabel.newUserRole")}
                options={roleList}
                selected={selectedRole}
                onChange={(option) => {
                  onChange(option.id);
                  setSelectedRole(option);
                }}
              />
            );
          }}
        />
        <TextField
          register={register}
          registerOptions={registerOptions.newUserEmail}
          error={errors.newUserEmail?.message}
          label={t("organisation:formLabel.newUserEmail")}
          name="newUserEmail"
          type="email"
        />
        <div>
          <Button
            type="submit"
            children={t("organisation:inviteUserModal.action")}
            size="sm"
            fullwidth
          />
        </div>
      </form>
    </Modal>
  );
};
