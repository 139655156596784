import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PageLayout } from "../ui/layout/PageLayout";
import { LeadsTable } from "./LeadsTable";

export const LeadsPage: FC = () => {
  const { t } = useTranslation(["common", "lead"]);
  return (
    <PageLayout title={t("lead:leadsPage.title")} maxWidthDesktop="max-w-5xl">
      <LeadsTable />
    </PageLayout>
  );
};
