import { FirestoreService } from "../firebase";
import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "@firebase/firestore";
import {
  createAddlHook,
  createGetHook,
  createListHook,
  createSnapshotDocumentHook,
  createSnapshotHook,
  createSoftDeletelHook,
  createUpdateHook,
} from "../firebase/firestoreService";
import { removeUndefined } from "../../utils";

type IWorflow = Shared.IWorkflow<
  Shared.JobboardParams | Shared.FundraisingParams
>;

export class WorkflowService extends FirestoreService<IWorflow> {
  constructor(public path: string) {
    super("workflows", path);
  }

  getModelConverter() {
    const serverTimestamp = this.serverTimestamp;
    return {
      toFirestore(data: IWorflow): DocumentData {
        const cleanData = removeUndefined({
          enabled: data.enabled,
          type: data.type,
          name: data.name,
          params: data.params,
          metrics: data.metrics,
        });
        cleanData.createdAt = serverTimestamp;
        return cleanData;
      },
      fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
      ): IWorflow {
        const {
          createdAt,
          updatedAt,
          enabled,
          type,
          name,
          params,
          metrics,
          error,
        } = snapshot.data(options);
        return {
          id: snapshot.id,
          createdAt,
          updatedAt,
          enabled,
          name,
          metrics,
          error,
          type,
          params,
        };
      },
    };
  }
}

export const useWorkflowsSnapshot = (organisationId: string) =>
  createSnapshotHook(new WorkflowService(`organisations/${organisationId}`));

export const useWorkflowSnapshot = (organisationId: string) =>
  createSnapshotDocumentHook(
    new WorkflowService(`organisations/${organisationId}`)
  );

export const useGetWorkflow = (organisationId: string) =>
  createGetHook(new WorkflowService(`organisations/${organisationId}`));

export const useListWorkflows = (organisationId: string) =>
  createListHook(new WorkflowService(`organisations/${organisationId}`));

export const useAddWorkflow = (organisationId: string) =>
  createAddlHook(new WorkflowService(`organisations/${organisationId}`));

export const useUpdateWorkflow = (organisationId: string) =>
  createUpdateHook(new WorkflowService(`organisations/${organisationId}`));

export const useSoftDeleteWorkflow = (organisationId: string) =>
  createSoftDeletelHook(new WorkflowService(`organisations/${organisationId}`));

export const isJobboardWorkflow = (
  workflow: IWorflow
): workflow is Shared.IWorkflow<Shared.JobboardParams> =>
  (workflow.type && workflow.type === "jobboard") || false;
