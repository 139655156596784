import { FC } from "react";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import { classNames } from "../../../utils";
import { RouterLink } from "../links";
import { Spinner } from "../icons";
export type StatsWithIconProps = {
  Icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  name: string;
  stat: string;
  loading?: boolean;
  changeType?: "increase" | "decrease";
  change?: string;
  linkPath?: string;
  linkText?: string;
};

export const StatsWithIcon: FC<StatsWithIconProps> = ({
  Icon,
  name,
  stat,
  loading = false,
  changeType,
  change,
  linkPath,
  linkText,
}) => {
  return (
    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
      <dt>
        <div className="absolute bg-primary-dark rounded-md p-3">
          <Icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 text-sm font-medium text-gray-500 truncate">
          {name}
        </p>
      </dt>
      <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
        <p className="text-2xl font-semibold text-gray-900">
          {loading ? <Spinner size={4} /> : stat}
        </p>
        {changeType && change && (
          <p
            className={classNames(
              changeType === "increase" ? "text-green-600" : "text-red-600",
              "ml-2 flex items-baseline text-sm font-semibold"
            )}
          >
            {changeType === "increase" ? (
              <ArrowSmUpIcon
                className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                aria-hidden="true"
              />
            ) : (
              <ArrowSmDownIcon
                className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            )}

            <span className="sr-only">
              {changeType === "increase" ? "Increased" : "Decreased"} by
            </span>
            {change}
          </p>
        )}
        {linkPath && linkText && (
          <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <RouterLink to={linkPath} text={linkText} />
            </div>
          </div>
        )}
      </dd>
    </div>
  );
};
