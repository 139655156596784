import { TrashIcon } from "@heroicons/react/solid";
import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { hasNotAccessBelow } from "../../../utils";
import { Button } from "../../ui/buttons";
import { EnableDisbaleToggle } from "../../ui/toggle";

export type workflowItem = {
  id: string;
  pathToWorkflowEdit: string;
  name: string;
  enabled?: boolean;
  error?: boolean;
  toggleEnabled: (value: boolean) => Promise<void> | void;
  deleteWorkflow: () => void;
  stats?: { name: string; stat: string }[];
};

export type WorfklowsListProps = {
  currentUserRole: Shared.IUserRoles;
  isLoading: boolean;
  workflows: workflowItem[];
};

export const WorfklowsList: FC<WorfklowsListProps> = ({
  currentUserRole,
  isLoading,
  workflows,
}) => {
  const { t } = useTranslation(["common", "workflow"]);

  const today = new Date();
  const nextRunDate = [0, 1, 2, 3, 4].includes(today.getDay())
    ? new Date(today.getTime() + 24 * 60 * 60 * 1000)
    : today.getDay() === 5
    ? new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000)
    : new Date(today.getTime() + 2 * 24 * 60 * 60 * 1000);
  nextRunDate.setHours(5);
  const nextRun = moment(today).to(nextRunDate.getTime());

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {workflows.map((workflow) => (
          <li key={workflow.id}>
            <div className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between gap-x-4">
                  <Link
                    className="flex-1 "
                    to={workflow.pathToWorkflowEdit}
                    children={
                      <p className="text-sm font-medium text-primary truncate">
                        {workflow.name}
                      </p>
                    }
                  />
                  <div className="ml-2 flex-shrink-0 flex">
                    {workflow.enabled !== undefined ? (
                      <div className="flex flex-row space-x-4 items-center">
                        {workflow.enabled ? (
                          <i className="text-xs text-gray-500">
                            {t("workflow:workflowList.nextRun", {
                              nextRun,
                            })}
                          </i>
                        ) : null}
                        <EnableDisbaleToggle
                          enabled={workflow.enabled}
                          onChange={workflow.toggleEnabled}
                          disbaled={
                            isLoading ||
                            hasNotAccessBelow(currentUserRole, "EDITOR")
                          }
                        />
                      </div>
                    ) : workflow.error ? (
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        {t("common:errors.error")}
                      </p>
                    ) : (
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                        {t("common:status.incomplete")}
                      </p>
                    )}
                  </div>
                  <div className="mt-2 flex-shrink-0 flex items-center text-sm text-gray-500 sm:mt-0">
                    <Button
                      type="button"
                      size="xs"
                      variant="white"
                      onClick={workflow.deleteWorkflow}
                      disbaled={
                        isLoading || hasNotAccessBelow(currentUserRole, "ADMIN")
                      }
                      tooltip={t("workflow:workflowList.deleteWorkflow")}
                      children={<TrashIcon className="w-5 h-5" />}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  {workflow.stats && (
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                      {workflow.stats.map((item) => (
                        <div
                          key={item.name}
                          className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
                        >
                          <dt className="text-sm font-medium text-gray-500 truncate">
                            {item.name}
                          </dt>
                          <dd className="mt-1 text-3xl font-semibold text-gray-900">
                            {item.stat}
                          </dd>
                        </div>
                      ))}
                    </dl>
                  )}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
