import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { hasAccessFrom } from "../../utils";
import { useAuth } from "../authentification";
import { useCurrentOrganisation } from "../organisation";
import { Button } from "../ui/buttons";

export const SubscriptionSidenavBlock: FC<{
  subscriptionPath: string;
  onClick?: () => void;
}> = ({ subscriptionPath, onClick }) => {
  const { t, i18n } = useTranslation(["common", "subscription"]);
  moment.locale(i18n.language);

  const { currentOrganisation } = useCurrentOrganisation();

  const isOnTrial =
    currentOrganisation &&
    currentOrganisation.freeTrialEndOn &&
    currentOrganisation.freeTrialEndOn.toDate() > new Date();

  const trialEndsIn =
    currentOrganisation && currentOrganisation.freeTrialEndOn
      ? moment(currentOrganisation.freeTrialEndOn.toMillis()).to(
          new Date().getTime(),
          true
        )
      : 0;

  const {
    authState: { currentRole },
  } = useAuth();

  return (
    <div className="px-4 mb-6 flex justify-around">
      {currentOrganisation?.leads_left ? (
        <div>
          <p className="text-xs text-gray-600">
            {t("subscription:sidebar.leadsLeft.description")}
          </p>
          <p>
            <span className="text-2xl font-bold">
              {currentOrganisation.leads_left}
            </span>
            {currentOrganisation.product?.leads && (
              <span className="text-xs text-gray-600">
                {" / "}
                {currentOrganisation.product.leads}
              </span>
            )}
          </p>
        </div>
      ) : (
        <div>
          <p className="text-xs text-gray-600 mx-4">
            {t("subscription:sidebar.freeTrial.description")}
          </p>
        </div>
      )}
      <div className="flex flex-col">
        {isOnTrial && (
          <p className="mb-2 text-xs text-gray-600 text-center">
            {t("subscription:sidebar.freeTrial.trialEndsIn", {
              trialEndsIn,
            })}
          </p>
        )}
        {hasAccessFrom(currentRole || "VIEWER", "ADMIN") && (
          <Link
            className="flex justify-center"
            to={subscriptionPath}
            onClick={onClick}
            children={
              <Button
                type="button"
                size="xs"
                children={
                  currentOrganisation?.leads_left
                    ? t("subscription:sidebar.leadsLeft.action")
                    : t("subscription:sidebar.freeTrial.action")
                }
              />
            }
          />
        )}
      </div>
    </div>
  );
};
