import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { classNames } from "../../../utils";
import { Button } from "../buttons";
import { Spinner } from "../icons";
import { CustomSelect, CustomSelectOption } from "../inputs";

export type Cell = {
  type: "text" | "text-medium" | "number" | "badge" | "link" | "action";
  value: string;
  badgeBgColorClass?: string;
  badgeTextColorClass?: string;
  onClick?: () => void;
  link?: {
    to: string;
    state?: any;
  };
};

export type Row = Cell[];

type TableProps = {
  heads: {
    name: string;
  }[];
  isLoading?: boolean;
  rows?: Row[];
  paginationProps?: PaginationProps;
};

export const Table: FC<TableProps> = ({
  isLoading,
  heads,
  rows,
  paginationProps,
}) => {
  const loadingRows = Array(10).fill(
    Array(heads.length).fill({
      type: "text",
      value: "",
    } as Cell)
  );

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {heads.map((head, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {head.name}
                    </th>
                  ))}
                </tr>
              </thead>
              {isLoading && rows?.length === 0 ? (
                <tbody>
                  {loadingRows.map((row, index) => (
                    <tr
                      key={index}
                      className={
                        index % 2 === 0
                          ? "bg-white"
                          : "bg-gray-200 animate-pulse"
                      }
                    >
                      {row.map(formatCell)}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  {rows &&
                    rows.map((row, rowIndex) => (
                      <tr
                        key={rowIndex}
                        className={
                          rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                        }
                      >
                        {row.map(formatCell)}
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
            {rows && rows.length > 0 && paginationProps && (
              <Pagination {...paginationProps} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type PaginationProps = {
  isLoading: boolean;
  perPage: 10 | 25 | 50 | 100;
  from: number;
  to: number;
  total: number | string;
  onChangePerPage: React.Dispatch<React.SetStateAction<10 | 25 | 50 | 100>>;
  handleNext?: () => Promise<void> | void;
  handlePrev?: () => Promise<void> | void;
};

type PerPageOption = CustomSelectOption & { id: 10 | 25 | 50 | 100 };

const Pagination: FC<PaginationProps> = ({
  isLoading,
  perPage,
  from,
  to,
  total,
  onChangePerPage,
  handleNext,
  handlePrev,
}) => {
  const { t } = useTranslation(["common"]);
  const perPageOptions: PerPageOption[] = [
    {
      id: 10,
      value: 10,
    },
    {
      id: 25,
      value: 25,
    },
    {
      id: 50,
      value: 50,
    },
    {
      id: 100,
      value: 100,
    },
  ];

  const [selectedPageOption, setSelectedPageOption] = useState<PerPageOption>({
    id: perPage,
    value: perPage,
  });

  const handleSelectPageChange = (option: CustomSelectOption) => {
    setSelectedPageOption(option as PerPageOption);
    onChangePerPage((option as PerPageOption).id);
  };

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex-1 flex justify-between sm:hidden">
            <div>
              <Button
                type="button"
                variant="white"
                size="sm"
                onClick={handlePrev}
                disbaled={!!!handlePrev}
                children={t("common:action.previous")}
              />
            </div>
            <div>
              <Button
                type="button"
                variant="white"
                size="sm"
                onClick={handleNext}
                disbaled={!!!handleNext}
                children={t("common:action.next")}
              />
            </div>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <CustomSelect
              position="above"
              options={perPageOptions}
              selected={selectedPageOption}
              onChange={handleSelectPageChange}
            />
            <div>
              <p className="text-sm text-gray-700">
                {t("common:data.showing")}{" "}
                <span className="font-medium">{from}</span>{" "}
                {t("common:conjunction.to")}{" "}
                <span className="font-medium">{to}</span>{" "}
                {t("common:conjunction.of")}{" "}
                <span className="font-medium">{total}</span>{" "}
                {t("common:data.results")}
              </p>
            </div>
            <div className="flex gap-x-4">
              <div>
                <Button
                  type="button"
                  variant="white"
                  size="sm"
                  onClick={handlePrev}
                  disbaled={!!!handlePrev}
                  children={t("common:action.previous")}
                />
              </div>
              <div>
                <Button
                  type="button"
                  variant="white"
                  size="sm"
                  onClick={handleNext}
                  disbaled={!!!handleNext}
                  children={t("common:action.next")}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const formatCell = (cell: Cell, cellIndex: number) => {
  switch (cell.type) {
    case "text":
      return (
        <td
          key={cellIndex}
          className="px-6 py-3 whitespace-nowrap text-sm text-gray-500"
        >
          {cell.value}
        </td>
      );

    case "text-medium":
      return (
        <td
          key={cellIndex}
          className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
        >
          {cell.value}
        </td>
      );

    case "number":
      return (
        <td
          key={cellIndex}
          className="px-6 py-3 whitespace-nowrap text-left text-sm font-medium"
        >
          {cell.value}
        </td>
      );

    case "badge":
      return (
        <td
          key={cellIndex}
          className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
        >
          <span
            className={classNames(
              "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
              cell.badgeBgColorClass || "bg-gray-100",
              cell.badgeTextColorClass || "text-gray-800"
            )}
          >
            {cell.value}
          </span>
        </td>
      );

    case "action":
      return (
        <td
          key={cellIndex}
          className="px-6 py-0 whitespace-nowrap text-right text-sm font-medium"
        >
          <Button
            size="sm"
            type="button"
            variant="textPrimary"
            onClick={cell.onClick}
            children={cell.value}
          />
        </td>
      );

    case "link":
      return (
        <td
          key={cellIndex}
          className="px-6 py-0 whitespace-nowrap text-left text-sm font-medium"
        >
          <Link
            to={{
              pathname: cell.link?.to,
              state: cell.link?.state,
            }}
            children={
              <Button
                size="sm"
                type="button"
                variant="textPrimary"
                children={cell.value}
              />
            }
          />
        </td>
      );

    default:
      return (
        <td
          key={cellIndex}
          className="px-6 py-3 whitespace-nowrap text-sm text-gray-500"
        >
          {" "}
        </td>
      );
  }
};
