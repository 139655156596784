import { FC } from "react";

export const SplashPage: FC<{ message?: string }> = ({ message }) => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <p className="text-4xl text-center font-extrabold text-gray-700 sm:text-5xl pb-4 animate-pulse-fast">
          Coolsales.io
        </p>
        <img
          className="mx-auto h-32 w-auto animate-pulse-fast"
          src="/logo512.png"
          alt="Workflow"
        />
      </div>
      {message && (
        <div className="sm:mx-auto sm:w-full sm:max-w-md pt-4 px-10">
          <p className="text-xl md:text-base text-center text-gray-500 sm:text-5xl pb-4">
            {message}
          </p>
        </div>
      )}
    </div>
  );
};
