import { FC } from "react";
import { UseFormRegister, RegisterOptions, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../ui/buttons";
import { TextField } from "../../ui/inputs";
import { Modal } from "../../ui/overlay";
import type { ChangePasswordFormData } from "../../user/UserSettings";

export const ChangePasswordModal: FC<{
  show: boolean;
  onClose: () => void;
  register: UseFormRegister<ChangePasswordFormData>;
  registerOptions: Record<keyof ChangePasswordFormData, RegisterOptions>;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  errors: FieldErrors<ChangePasswordFormData>;
}> = ({ show, onClose, register, registerOptions, onSubmit, errors }) => {
  const { t } = useTranslation(["common", "authentification"]);
  return (
    <Modal
      show={show}
      onClose={onClose}
      title={t("authentification:changePasswordModal.title")}
      size="sm"
    >
      <form className="space-y-6" onSubmit={onSubmit} noValidate>
        <div className="hidden">
          <TextField
            register={register}
            registerOptions={registerOptions.username}
            error={errors.username?.message}
            label=""
            hidden={true}
            name="username"
            type="email"
            autocomplete="username"
          />
        </div>
        <TextField
          register={register}
          registerOptions={registerOptions.currentPassword}
          error={errors.currentPassword?.message}
          label={t("authentification:formLabel.currentPassword")}
          name="currentPassword"
          type="password"
          autocomplete="current-password"
        />
        <TextField
          register={register}
          registerOptions={registerOptions.newPassword}
          error={errors.newPassword?.message}
          label={t("authentification:formLabel.newPassword")}
          name="newPassword"
          type="password"
          autocomplete="new-password"
        />
        <TextField
          register={register}
          registerOptions={registerOptions.newPasswordConfirmation}
          error={errors.newPasswordConfirmation?.message}
          label={t("authentification:formLabel.confirmationPassword")}
          name="newPasswordConfirmation"
          type="password"
          autocomplete="new-password"
        />

        <div>
          <Button
            type="submit"
            children={t("common:action.save")}
            size="sm"
            fullwidth
          />
        </div>
      </form>
    </Modal>
  );
};
