import { FC } from "react";
import { useCurrentOrganisation } from "../organisation/organisationService";
import { RegisterOptions, useForm } from "react-hook-form";
import { Modal } from "../ui/overlay";
import { Button } from "../ui/buttons";
import { TextField } from "../ui/inputs";
import { useParams } from "react-router-dom";
import { Spinner } from "../ui/icons";

type LinkedInUpdateIntegrationQuotaData = {
  genericLimit: number;
  maxVisitPerDayClassic: number;
  maxVisitPerDaySalesNavigator: number;
};

export const LinkedInUpdateIntegrationQuota: FC<{
  show: boolean;
  onClose: () => void;
}> = ({ show, onClose }) => {
  let { integrationId } = useParams<{ integrationId: string }>();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LinkedInUpdateIntegrationQuotaData>();

  const registerOptions: Record<
    keyof LinkedInUpdateIntegrationQuotaData,
    RegisterOptions
  > = {
    genericLimit: {
      required: "required",
      max: { value: 3000, message: "The maximum is 3000" },
    },
    maxVisitPerDayClassic: {
      required: "required",
      max: { value: 80, message: "The maximum is 80" },
    },
    maxVisitPerDaySalesNavigator: {
      required: "required",
      max: { value: 800, message: "The maximum is 800" },
    },
  };

  const {
    currentOrganisation,
    updateLinkedinIntegrationQuota,
    isLoadingUpdate,
  } = useCurrentOrganisation();

  const limits =
    currentOrganisation?.linkedinIntegrations &&
    currentOrganisation?.linkedinIntegrations[integrationId].limits;

  const onSubmit = handleSubmit((data) => {
    if (updateLinkedinIntegrationQuota) {
      updateLinkedinIntegrationQuota(integrationId, data);
      onClose();
    }
  });

  return (
    <Modal
      show={show}
      onClose={onClose}
      title="Update Linkedin Integration"
      size="sm"
    >
      {limits ? (
        <form className="space-y-6" onSubmit={onSubmit}>
          <TextField
            name="genericLimit"
            type="number"
            defaultValue={limits.genericLimit}
            register={register}
            registerOptions={registerOptions.genericLimit}
            error={errors.genericLimit?.message}
            label={"Generic limit"}
          />
          <TextField
            name="maxVisitPerDayClassic"
            type="number"
            defaultValue={limits.maxVisitPerDayClassic}
            register={register}
            registerOptions={registerOptions.maxVisitPerDayClassic}
            error={errors.maxVisitPerDayClassic?.message}
            label={"Max limit per day classic"}
          />
          <TextField
            name="maxVisitPerDaySalesNavigator"
            type="number"
            defaultValue={limits.maxVisitPerDaySalesNavigator}
            register={register}
            registerOptions={registerOptions.maxVisitPerDaySalesNavigator}
            error={errors.maxVisitPerDaySalesNavigator?.message}
            label={"Max limit per day Sales Navigator"}
          />
          <Button
            type="submit"
            children={"save"}
            size="sm"
            isLoading={isLoadingUpdate}
          />
        </form>
      ) : (
        <Spinner className="my-12" />
      )}
    </Modal>
  );
};
