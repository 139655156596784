import { FC } from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useCurrentOrganisation } from "../organisation/organisationService";
import { Spinner } from "../ui/icons";
import { LinkedinAddIntegrationModal } from "./LinkedinAddIntegrationModal";
import { ChooseIntegrationModal } from "./components/ChooseIntegrationModal";
import { LinkedInUpdateIntegrationQuota } from "./LinkedInUpdateIntegrationQuota";
import { LemlistAddIntegrationModal } from "./LemlistAddIntegrationModal";

import { IntegrationsPage } from "./integrationsPage";
import { useOverlay } from "../overlay";
import { useAuth } from "../authentification";
import { useTranslation } from "react-i18next";
import { currentEnvironment } from "../config";
import { DatananasAddIntegrationModal } from "./DatananasAddIntegrationModal";
import { LgmAddIntegrationModal } from "./LgmAddIntegrationModal";
import { EmeliaAddIntegrationModal } from "./EmeliaAddIntegrationModal";

export const Integrations: FC = () => {
  const { t } = useTranslation(["common", "integration"]);
  // Routing hooks
  let { path, url } = useRouteMatch();
  let location = useLocation<{ modal: boolean }>();
  const history = useHistory();
  let displayModal = (location.state && location.state.modal) || false;

  // Authstate
  const { authState } = useAuth();

  // UI Hooks
  const { pushNotification } = useOverlay();

  // Organisation hooks
  const {
    isLoadingCurrentOrganisation,
    isLoadingUpdate,
    currentOrganisation,
    toggleLinkedinIntegration,
    updateLinkedinIntegrationQuota,
    deleteLinkedinIntegration,
    toggleLemlistIntegration,
    deleteLemlistIntegration,
    toggleDatananasIntegration,
    deleteDatananasIntegration,
    toggleLGMIntegration,
    deleteLGMIntegration,
    toggleEmeliaIntegration,
    deleteEmeliaIntegration,
  } = useCurrentOrganisation();

  if (
    isLoadingCurrentOrganisation === false &&
    currentOrganisation &&
    toggleLinkedinIntegration &&
    updateLinkedinIntegrationQuota &&
    deleteLinkedinIntegration &&
    toggleLemlistIntegration &&
    deleteLemlistIntegration &&
    toggleDatananasIntegration &&
    deleteDatananasIntegration &&
    toggleLGMIntegration &&
    deleteLGMIntegration &&
    toggleEmeliaIntegration &&
    deleteEmeliaIntegration
  ) {
    const linkedinIntegrations = currentOrganisation.linkedinIntegrations
      ? Object.entries(currentOrganisation.linkedinIntegrations)
          .map(([key, integration]) => ({
            id: key,
            name: integration.username,
            level: integration.level,
            createdBy: integration.createdBy,
            enabled: integration.enabled,
            lastRefreshed:
              integration.lastRefreshed?.toDate().toDateString() || "not yet",
            editQuota: () =>
              history.push(`${url}/updateLinkedinIntegration/${key}`, {
                modal: true,
              }),
            toogleIntegration: (value: boolean) =>
              toggleLinkedinIntegration(key, value),
            deleteIntegration: () =>
              deleteLinkedinIntegration(key).catch((error) =>
                pushNotification({
                  severity: "success",
                  title: t("common:errors.errorOccured"),
                  body: currentEnvironment !== "production" && error,
                })
              ),
            isLoading: isLoadingUpdate || false,
          }))
          .sort((integrationA, integrationB) =>
            integrationA.id > integrationB.id ? 1 : -1
          )
      : [];

    const lemlistIntegrations = currentOrganisation.lemlistIntegrations
      ? Object.entries(currentOrganisation.lemlistIntegrations)
          .map(([key, integration]) => ({
            id: key,
            name: integration.campaignName,
            createdBy: integration.createdBy,
            enabled: integration.enabled,
            toogleIntegration: (value: boolean) =>
              toggleLemlistIntegration(key, value),
            deleteIntegration: () =>
              deleteLemlistIntegration(key).catch((error) =>
                pushNotification({
                  severity: "success",
                  title: t("common:errors.errorOccured"),
                  body: currentEnvironment !== "production" && error,
                })
              ),
            isLoading: isLoadingUpdate || false,
          }))
          .sort((integrationA, integrationB) =>
            integrationA.id > integrationB.id ? 1 : -1
          )
      : [];

    const datananasIntegrations = currentOrganisation.datananasIntegrations
      ? Object.entries(currentOrganisation.datananasIntegrations)
          .map(([key, integration]) => ({
            id: key,
            name: integration.sequenceName,
            createdBy: integration.createdBy,
            enabled: integration.enabled,
            toogleIntegration: (value: boolean) =>
              toggleDatananasIntegration(key, value),
            deleteIntegration: () =>
              deleteDatananasIntegration(key).catch((error) =>
                pushNotification({
                  severity: "success",
                  title: t("common:errors.errorOccured"),
                  body: currentEnvironment !== "production" && error,
                })
              ),
            isLoading: isLoadingUpdate || false,
          }))
          .sort((integrationA, integrationB) =>
            integrationA.id > integrationB.id ? 1 : -1
          )
      : [];

    const lgmIntegrations = currentOrganisation.lgmIntegrations
      ? Object.entries(currentOrganisation.lgmIntegrations)
          .map(([key, integration]) => ({
            id: key,
            name: integration.name,
            createdBy: integration.createdBy,
            enabled: integration.enabled,
            toogleIntegration: (value: boolean) =>
              toggleLGMIntegration(key, value),
            deleteIntegration: () =>
              deleteLGMIntegration(key).catch((error) =>
                pushNotification({
                  severity: "success",
                  title: t("common:errors.errorOccured"),
                  body: currentEnvironment !== "production" && error,
                })
              ),
            isLoading: isLoadingUpdate || false,
          }))
          .sort((integrationA, integrationB) =>
            integrationA.id > integrationB.id ? 1 : -1
          )
      : [];

    const emeliaIntegrations = currentOrganisation.emeliaIntegrations
      ? Object.entries(currentOrganisation.emeliaIntegrations)
          .map(([key, integration]) => ({
            id: key,
            name: integration.campaignName,
            createdBy: integration.createdBy,
            enabled: integration.enabled,
            toogleIntegration: (value: boolean) =>
              toggleEmeliaIntegration(key, value),
            deleteIntegration: () =>
              deleteEmeliaIntegration(key).catch((error) =>
                pushNotification({
                  severity: "success",
                  title: t("common:errors.errorOccured"),
                  body: currentEnvironment !== "production" && error,
                })
              ),
            isLoading: isLoadingUpdate || false,
          }))
          .sort((integrationA, integrationB) =>
            integrationA.id > integrationB.id ? 1 : -1
          )
      : [];

    return (
      <>
        <IntegrationsPage
          linkedinIntegrations={linkedinIntegrations}
          lemlistIntegrations={lemlistIntegrations}
          datananasIntegrations={datananasIntegrations}
          lgmIntegrations={lgmIntegrations}
          emeliaIntegrations={emeliaIntegrations}
          pathToChooseIntegrationModal={`${url}/chooseIntegration`}
          currentUserRole={authState.currentRole || "VIEWER"}
        />
        <Switch>
          <Route
            path={`${path}/chooseIntegration`}
            children={
              <ChooseIntegrationModal
                show={displayModal}
                onClose={() => history.push(url)}
                onAddLinkedIn={() => {
                  history.push(url);
                  if (
                    currentOrganisation === undefined ||
                    currentOrganisation.product === undefined ||
                    currentOrganisation.product.max_linkedin === undefined
                  ) {
                    pushNotification({
                      severity: "warning",
                      title: t(
                        "integration:integrationPage.updateSubscriptionNotification.title"
                      ),
                      body: t(
                        "integration:integrationPage.updateSubscriptionNotification.body"
                      ),
                    });
                  } else if (
                    currentOrganisation.product.max_linkedin <=
                    Object.keys(currentOrganisation.linkedinIntegrations || {})
                      .length
                  ) {
                    pushNotification({
                      severity: "warning",
                      title: t(
                        "integration:integrationPage.maxLinkedinIntegrationNotification.title"
                      ),
                      body: t(
                        "integration:integrationPage.maxLinkedinIntegrationNotification.body"
                      ),
                    });
                  } else {
                    // without delay or if invoce directly the model will close instantly
                    setTimeout(
                      () =>
                        history.push(`${url}/addLinkedinIntegration`, {
                          modal: true,
                        }),
                      300
                    );
                  }
                }}
                onLemlist={() => {
                  history.push(url);
                  // without delay or if invoked directly the model will close instantly
                  setTimeout(
                    () =>
                      history.push(`${url}/addLemlistIntegration`, {
                        modal: true,
                      }),
                    300
                  );
                }}
                onDatananas={() => {
                  history.push(url);
                  // without delay or if invoked directly the model will close instantly
                  setTimeout(
                    () =>
                      history.push(`${url}/addDatananasIntegration`, {
                        modal: true,
                      }),
                    300
                  );
                }}
                onLgm={() => {
                  history.push(url);
                  // without delay or if invoked directly the model will close instantly
                  setTimeout(
                    () =>
                      history.push(`${url}/addLgmIntegration`, {
                        modal: true,
                      }),
                    300
                  );
                }}
                onEmelia={() => {
                  history.push(url);
                  // without delay or if invoked directly the model will close instantly
                  setTimeout(
                    () =>
                      history.push(`${url}/addEmeliaIntegration`, {
                        modal: true,
                      }),
                    300
                  );
                }}
              />
            }
          />
          <Route
            path={`${path}/addLinkedinIntegration`}
            children={
              <LinkedinAddIntegrationModal
                show={displayModal}
                onClose={() => history.push(url)}
              />
            }
          />
          <Route
            path={`${path}/updateLinkedinIntegration/:integrationId`}
            children={
              <LinkedInUpdateIntegrationQuota
                show={displayModal}
                onClose={() => history.push(url)}
              />
            }
          />
          <Route
            path={`${path}/addLemlistIntegration`}
            children={
              <LemlistAddIntegrationModal
                show={displayModal}
                onClose={() => history.push(url)}
              />
            }
          />
          <Route
            path={`${path}/addDatananasIntegration`}
            children={
              <DatananasAddIntegrationModal
                show={displayModal}
                onClose={() => history.push(url)}
              />
            }
          />
          <Route
            path={`${path}/addLgmIntegration`}
            children={
              <LgmAddIntegrationModal
                show={displayModal}
                onClose={() => history.push(url)}
              />
            }
          />
          <Route
            path={`${path}/addEmeliaIntegration`}
            children={
              <EmeliaAddIntegrationModal
                show={displayModal}
                onClose={() => history.push(url)}
              />
            }
          />
        </Switch>
      </>
    );
  } else {
    return (
      <div className="mt-28 place-content-center">
        <Spinner size={20} />
      </div>
    );
  }
};
