import { FC } from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../utils";
import { Button } from "../../ui/buttons";

export type Price = {
  id: string;
  product: {
    name: string;
    description: string;
  };
  unit_amount: number;
  action: string;
};

export type PlanSelectionProps = {
  prices: Record<"discovery" | "hobby" | "starter", Price[]>;
  handlePlanSelected: (priceID: string) => void;
  isLoadingCheckoutSession: boolean;
};

export const PlanSelection: FC<PlanSelectionProps> = ({
  prices,
  handlePlanSelected,
  isLoadingCheckoutSession,
}) => {
  const { t } = useTranslation(["common", "subscription"]);
  return (
    <>
      <div className="sm:flex sm:flex-col sm:align-center">
        <p className="mt-5 text-xl text-gray-500 sm:text-center">
          {t("subscription:planSelection.description")}
        </p>
        {/* Period switcher for later */}
        {/* <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
        <button
          type="button"
          className="relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8"
        >
          Monthly billing
        </button>
        <button
          type="button"
          className="ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8"
        >
          Yearly billing
        </button>
      </div> */}
      </div>
      <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:mx-auto lg:grid-cols-3">
        {Object.entries(prices).map(([key, value]) => (
          <div
            key={key}
            className={classNames(
              "flex flex-col border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200",
              value.length === 1 ? "h-1/2" : ""
            )}
          >
            {value.map((price) => (
              <TierDisplay
                key={price.id}
                {...price}
                handlePlanSelected={handlePlanSelected}
                isLoadingCheckoutSession={isLoadingCheckoutSession}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

const TierDisplay: FC<
  Price & {
    handlePlanSelected: (priceId: string) => void;
    isLoadingCheckoutSession: boolean;
  }
> = ({
  id,
  product,
  unit_amount,
  handlePlanSelected,
  isLoadingCheckoutSession,
  action,
}) => (
  <div className="p-6">
    <h2 className="text-lg leading-6 font-medium text-gray-900">
      {product.name}
    </h2>
    <p className="mt-4 text-sm text-gray-500">{product.description}</p>
    <p className="mt-8 mb-2">
      <span className="text-4xl font-extrabold text-gray-900">
        €{unit_amount}
      </span>{" "}
      <span className="text-base font-medium text-gray-500">/mo</span>
    </p>
    <Button
      type="button"
      size="sm"
      onClick={() => handlePlanSelected(id)}
      children={action}
      isLoading={isLoadingCheckoutSession}
    />
  </div>
);
