import { FC } from "react";
import { useForm, RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router";
import { useUpdateWorkflow } from "..";
import { useOverlay } from "../../overlay";
import { Alert } from "../../ui/alerts";
import { Button } from "../../ui/buttons";
import { Spinner } from "../../ui/icons";
import { TextField } from "../../ui/inputs";
import { Checkbox } from "../../ui/inputs/Checkbox";

export type SettingsFormData = {
  workflowName: string;
  onRunSuccess: boolean;
  onRunFail: boolean;
};

export const StepSettingsForm: FC<{
  isLoadingWorkflow: boolean;
  workflowName?: string;
  settingsParams?: Shared.JobboardParams["settings"];
}> = ({ isLoadingWorkflow, workflowName, settingsParams }) => {
  let {
    params: { workflowId, organisationId },
    url,
  } = useRouteMatch<{ workflowId: string; organisationId: string }>();

  const history = useHistory();

  const { t } = useTranslation(["common", "workflow"]);

  const { pushNotification } = useOverlay();

  const { isLoading: isLoadingUpdateWorkflow, update } =
    useUpdateWorkflow(organisationId)();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SettingsFormData>();

  const registerOptions: Record<keyof SettingsFormData, RegisterOptions> = {
    workflowName: {
      required: t("workflow:formValidation.required"),
    },
    onRunSuccess: {
      required: false,
    },
    onRunFail: {
      required: false,
    },
  };

  const onSubmit = handleSubmit((data) => {
    const settings: Shared.JobboardParams["settings"] = {
      status: "complete",
      notifications: {
        onRunSuccess: data.onRunSuccess,
        onRunFail: data.onRunFail,
      },
    };

    update(workflowId, {
      name: data.workflowName,
      "params.settings": settings,
    } as Shared.IWorkflow<Shared.JobboardParams>).then(() => {
      pushNotification({
        severity: "success",
        title: t("workflow:addJobboardWorkflowPage.settingsStep.success"),
      });
      history.push(url + "?step=ready");
    });
  });

  return (
    <section aria-labelledby="job-details">
      <form onSubmit={onSubmit} noValidate>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2
                id="payment-details-heading"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {t("workflow:addJobboardWorkflowPage.settingsStep.title")}
              </h2>
              <p className="max-w-2xl text-sm text-gray-500">
                {t("workflow:addJobboardWorkflowPage.settingsStep.description")}
              </p>
            </div>
            {isLoadingWorkflow ? (
              <div className="my-10 place-content-center">
                <Spinner size={20} />
              </div>
            ) : (
              <>
                {settingsParams?.error && (
                  <Alert
                    variant="Error"
                    title={t(
                      "workflow:addJobboardWorkflowPage.settingsStep.errorTitle"
                    )}
                    body={settingsParams.error}
                  />
                )}
                <div className="mt-6 grid grid-cols-4 gap-6">
                  <TextField
                    className="col-span-4 lg:col-span-2"
                    type="text"
                    label={t(
                      "workflow:formLabel.jobboard.setting.workflowName"
                    )}
                    name="workflowName"
                    defaultValue={workflowName}
                    register={register}
                    registerOptions={registerOptions.workflowName}
                    error={errors.workflowName?.message}
                  />
                  <p className="col-span-4 text-sm text-left font-medium text-gray-700">
                    {t(
                      "workflow:formLabel.jobboard.setting.notifications.title"
                    )}
                  </p>
                  <Checkbox
                    name="onRunSuccess"
                    label={t(
                      "workflow:formLabel.jobboard.setting.notifications.onRunSuccess"
                    )}
                    register={register}
                    registerOptions={registerOptions.onRunSuccess}
                    defaultChecked={
                      settingsParams?.notifications &&
                      settingsParams?.notifications.onRunSuccess
                    }
                  />
                  <Checkbox
                    name="onRunFail"
                    label={t(
                      "workflow:formLabel.jobboard.setting.notifications.onRunFail"
                    )}
                    register={register}
                    registerOptions={registerOptions.onRunFail}
                    defaultChecked={
                      settingsParams?.notifications &&
                      settingsParams?.notifications.onRunFail
                    }
                  />
                </div>
              </>
            )}
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <Button
              type="submit"
              size="sm"
              children={t("common:action.next")}
              isLoading={isLoadingWorkflow || isLoadingUpdateWorkflow}
            />
          </div>
        </div>
      </form>
    </section>
  );
};
