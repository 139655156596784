import { FC } from "react";
import { UseFormRegister, RegisterOptions } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

type TextFieldWithActionProps = {
  className?: string;
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
  action: () => void;
  actionIcon?: JSX.Element;
  actionText?: string;
  error?: string;
  label: string;
  name: string;
  type: "text" | "email" | "password" | "number";
  defaultValue?: string | number;
  hidden?: boolean;
  autocomplete?:
    | "off"
    | "email"
    | "username"
    | "new-password"
    | "current-password"
    | "text"
    | "name"
    | "organization"
    | "organization-title"
    | "street-address"
    | "url";
};

export const TextFieldWithAction: FC<TextFieldWithActionProps> = ({
  className,
  register,
  registerOptions,
  action,
  actionIcon,
  actionText,
  error,
  label,
  name,
  type,
  defaultValue,
  hidden = false,
  autocomplete,
}) => (
  <div>
    <label
      htmlFor={name}
      className="block text-sm text-left font-medium text-gray-700"
    >
      {label}
      {registerOptions && registerOptions.required && " *"}
    </label>
    <div className="mt-1 flex rounded-md shadow-sm">
      <div className="relative flex items-stretch flex-grow focus-within:z-10">
        <input
          id={name}
          type={type}
          defaultValue={defaultValue}
          autoComplete={autocomplete}
          hidden={hidden}
          {...register(name, registerOptions)}
          className={`"appearance-none block w-full px-3 py-2 border rounded-none rounded-l-md shadow-sm focus:outline-none ${
            error
              ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
              : "border-gray-300 placeholder-gray-400 focus:ring-primary-light focus:border-primary-light"
          }  sm:text-sm"`}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <button
        type="button"
        className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
        onClick={action}
      >
        {actionIcon}
        <span>{actionText}</span>
      </button>
    </div>
    {error && <p className="mt-2 text-sm text-left text-red-600">{error}</p>}
  </div>
);
