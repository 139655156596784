import { deleteField } from "@firebase/firestore";
import { FC, useEffect, useState } from "react";
import { useForm, RegisterOptions, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router";
import { useUpdateWorkflow } from "..";
import { DatananasAddIntegrationModal } from "../../integration/DatananasAddIntegrationModal";
import { EmeliaAddIntegrationModal } from "../../integration/EmeliaAddIntegrationModal";
import { LemlistAddIntegrationModal } from "../../integration/LemlistAddIntegrationModal";
import { LgmAddIntegrationModal } from "../../integration/LgmAddIntegrationModal";
import { useCurrentOrganisation } from "../../organisation";
import { useOverlay } from "../../overlay";
import { Alert } from "../../ui/alerts";
import { Button } from "../../ui/buttons";
import { Spinner } from "../../ui/icons";
import {
  CustomeSelectWithAction,
  CustomSelectOption,
  TextArrayField,
} from "../../ui/inputs";
import { Checkbox } from "../../ui/inputs/Checkbox";

export type OutputFormData = {
  csvByEmails?: { value: string }[];
  lemlistCampaignId?: string;
  datananasSequenceId?: string;
  datananasEngageLead?: boolean;
  lgmApiKey?: string;
  emeliaCampaignId?: string;
};

export const StepOutputForm: FC<{
  isLoadingWorkflow: boolean;
  outputParams?: Shared.JobboardParams["output"];
}> = ({ isLoadingWorkflow, outputParams }) => {
  // Routing
  let {
    params: { workflowId, organisationId },
    url,
  } = useRouteMatch<{ workflowId: string; organisationId: string }>();
  const history = useHistory();

  // UI
  const { t } = useTranslation(["common", "workflow"]);
  const { pushNotification } = useOverlay();
  const [showLemlistDialog, setShowLemlistDialog] = useState(false);
  const [showDatananasDialog, setShowDatananasDialog] = useState(false);
  const [showLgmDialog, setShowLgmDialog] = useState(false);
  const [showEmeliaDialog, setShowEmeliaDialog] = useState(false);

  // Organisation
  const { isLoadingCurrentOrganisation, currentOrganisation } =
    useCurrentOrganisation();

  // Workflow
  const { isLoading: isLoadingUpdateWorkflow, update } =
    useUpdateWorkflow(organisationId)();

  // Form
  const [lemlistCampaignsOptions, setLemlistCampaignsOptions] = useState<
    CustomSelectOption[]
  >([]);
  const [lemlistCampaigns, setLemlistCampaigns] = useState<
    Record<string, string>
  >({});

  const [datananasSequencesOptions, setDatananasSequencesOptions] = useState<
    CustomSelectOption[]
  >([]);
  const [datananasSequences, setDatananasSequences] = useState<
    Record<string, string>
  >({});

  const [lgmApikeyOptions, setLgmApikeyOptions] = useState<
    CustomSelectOption[]
  >([]);
  const [lgmApikeys, setLgmApikeys] = useState<Record<string, string>>({});

  const [emeliaCampaignsOptions, setEmeliaCampaignsOptions] = useState<
    CustomSelectOption[]
  >([]);
  const [emeliaCampaigns, setEmeliaCampaigns] = useState<
    Record<string, string>
  >({});

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    trigger,
    setFocus,
  } = useForm<OutputFormData>({
    defaultValues: {
      csvByEmails: outputParams?.csvByEmails
        ? [
            ...outputParams.csvByEmails?.map((email) => ({ value: email })),
            { value: "" },
          ]
        : [{ value: "" }],
      datananasEngageLead: outputParams?.datananas?.engageLead,
      datananasSequenceId: outputParams?.datananas?.sequenceId,
      lemlistCampaignId: outputParams?.lemlist?.campaignId,
      emeliaCampaignId: outputParams?.emelia?.campaignId,
      lgmApiKey: outputParams?.lgm?.apiKey,
    },
  });

  const selectedLemlistCampaignId = watch("lemlistCampaignId");
  const selectedEmeliaCampaignId = watch("emeliaCampaignId");
  const selectedDatananasSequenceId = watch("datananasSequenceId");
  const selectedLgmApikey = watch("lgmApiKey");

  const registerOptions: Record<keyof OutputFormData, RegisterOptions> = {
    csvByEmails: {
      required: false,
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: t("workflow:formValidation.jobboard.emailPattern"),
      },
    },
    lemlistCampaignId: {
      required: false,
    },
    datananasSequenceId: {
      required: false,
    },
    datananasEngageLead: {
      required: false,
    },
    lgmApiKey: {
      required: false,
    },
    emeliaCampaignId: {
      required: false,
    },
  };

  const onSubmit = handleSubmit((data) => {
    const output: Shared.JobboardParams["output"] = {
      status: "complete",
    };
    // Set CsvbyEmails output to existing value if any
    if (outputParams && outputParams.csvByEmails) {
      output.csvByEmails = outputParams.csvByEmails;
    }

    // Set CsvByEmails output to form value if any
    if (data.csvByEmails) {
      output.csvByEmails = data.csvByEmails
        .map((field) => field.value)
        .filter((value) => value.length > 0);
    }

    // Set Lemlist output to existing value if any
    if (
      outputParams &&
      outputParams.lemlist?.campaignId &&
      outputParams.lemlist.apiKey
    ) {
      output.lemlist = {
        apiKey: outputParams.lemlist?.apiKey,
        campaignId: outputParams.lemlist?.campaignId,
      };
    }

    // Set lemlist output to form value if any
    if (
      data.lemlistCampaignId !== undefined &&
      data.lemlistCampaignId !== "removeCampaign" &&
      currentOrganisation &&
      currentOrganisation.lemlistIntegrations
    ) {
      output.lemlist = {
        apiKey:
          currentOrganisation.lemlistIntegrations[data.lemlistCampaignId]
            .apiKey,
        campaignId: data.lemlistCampaignId,
      };
    }

    // Remove Lemlist campaign
    if (data.lemlistCampaignId === "removeCampaign") {
      // Avoid update with existing value on next step
      output.lemlist = undefined;

      update(workflowId, {
        "params.output.lemlist": deleteField(),
      } as Shared.IWorkflow<Shared.JobboardParams>);
    }

    // Set Datananas output to existing value if any
    if (
      outputParams &&
      outputParams.datananas?.apiToken &&
      outputParams.datananas?.senderId &&
      outputParams.datananas?.sequenceId &&
      typeof outputParams.datananas?.engageLead === "boolean"
    ) {
      output.datananas = {
        apiToken: outputParams.datananas.apiToken,
        senderId: outputParams.datananas.senderId,
        sequenceId: outputParams.datananas.sequenceId,
        engageLead: outputParams.datananas.engageLead,
      };
    }

    // Set Datananas output to form value if any
    if (
      data.datananasSequenceId !== undefined &&
      data.datananasSequenceId !== "removeSequence" &&
      currentOrganisation &&
      currentOrganisation.datananasIntegrations
    ) {
      output.datananas = {
        apiToken:
          currentOrganisation.datananasIntegrations[data.datananasSequenceId]
            .apiToken,
        sequenceId: data.datananasSequenceId,
        senderId:
          currentOrganisation.datananasIntegrations[data.datananasSequenceId]
            .senderId,
        engageLead: data.datananasEngageLead || false,
      };
    }

    // Remove Datananas sequence
    if (data.datananasSequenceId === "removeSequence") {
      // Avoid update with existing value on next step
      output.datananas = undefined;

      update(workflowId, {
        "params.output.datananas": deleteField(),
      } as Shared.IWorkflow<Shared.JobboardParams>);
    }

    // Set LGM output to existing value if any
    if (outputParams && outputParams.lgm?.apiKey) {
      output.lgm = {
        apiKey: outputParams.lgm?.apiKey,
      };
    }

    // Set LGM output to form value if any
    if (
      data.lgmApiKey !== undefined &&
      currentOrganisation &&
      currentOrganisation.lemlistIntegrations
    ) {
      output.lgm = {
        apiKey: data.lgmApiKey,
      };
    }
    // Remove LGM campaign
    if (data.lgmApiKey === "removeIntegration") {
      // Avoid update with existing value on next step
      output.lgm = undefined;

      update(workflowId, {
        "params.output.lgm": deleteField(),
      } as Shared.IWorkflow<Shared.JobboardParams>);
    }

    // Set emeliE output to existing value if any
    if (
      outputParams &&
      outputParams.emelia?.campaignId &&
      outputParams.emelia.apiKey
    ) {
      output.emelia = {
        apiKey: outputParams.emelia?.apiKey,
        campaignId: outputParams.emelia?.campaignId,
      };
    }

    // Set Emelia output to form value if any
    if (
      data.emeliaCampaignId !== undefined &&
      data.emeliaCampaignId !== "removeCampaign" &&
      currentOrganisation &&
      currentOrganisation.emeliaIntegrations
    ) {
      output.emelia = {
        apiKey:
          currentOrganisation.emeliaIntegrations[data.emeliaCampaignId].apiKey,
        campaignId: data.emeliaCampaignId,
      };
    }

    // Remove Emelia campaign
    if (data.emeliaCampaignId === "removeCampaign") {
      // Avoid update with existing value on next step
      output.emelia = undefined;

      update(workflowId, {
        "params.output.emelia": deleteField(),
      } as Shared.IWorkflow<Shared.JobboardParams>);
    }

    update(workflowId, {
      "params.output": output,
    } as Shared.IWorkflow<Shared.JobboardParams>).then(() => {
      pushNotification({
        severity: "success",
        title: t("workflow:addJobboardWorkflowPage.outputStep.success"),
      });
      history.push(url + "?step=settings");
    });
  });

  // Update lemlist integrations list
  useEffect(() => {
    if (
      !isLoadingCurrentOrganisation &&
      currentOrganisation &&
      currentOrganisation?.lemlistIntegrations
    ) {
      setLemlistCampaigns(
        Object.values(currentOrganisation.lemlistIntegrations).reduce(
          (campaigns, integration) =>
            Object.assign(campaigns, {
              [integration.campaignId]: integration.campaignName,
            }),
          {
            removeCampaign: t(
              "workflow:formLabel.jobboard.output.removeCampaign"
            ),
          } as Record<string, string>
        )
      );
      setLemlistCampaignsOptions([
        ...Object.values(currentOrganisation.lemlistIntegrations).map(
          (integration) => ({
            id: integration.campaignId,
            value: integration.campaignName,
          })
        ),
        {
          id: "removeCampaign",
          value: t("workflow:formLabel.jobboard.output.removeCampaign"),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCurrentOrganisation, currentOrganisation?.lemlistIntegrations]);

  // Update datananas integrations list
  useEffect(() => {
    if (
      !isLoadingCurrentOrganisation &&
      currentOrganisation &&
      currentOrganisation?.datananasIntegrations
    ) {
      setDatananasSequences(
        Object.values(currentOrganisation.datananasIntegrations).reduce(
          (sequences, integration) =>
            Object.assign(sequences, {
              [integration.sequenceId]: integration.sequenceName,
            }),
          {
            removeSequence: t(
              "workflow:formLabel.jobboard.output.removeSequence"
            ),
          } as Record<string, string>
        )
      );
      setDatananasSequencesOptions([
        ...Object.values(currentOrganisation.datananasIntegrations).map(
          (integration) => ({
            id: integration.sequenceId,
            value: integration.sequenceName,
          })
        ),
        {
          id: "removeSequence",
          value: t("workflow:formLabel.jobboard.output.removeSequence"),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingCurrentOrganisation,
    currentOrganisation?.datananasIntegrations,
  ]);

  // Update LGM integrations list
  useEffect(() => {
    if (
      !isLoadingCurrentOrganisation &&
      currentOrganisation &&
      currentOrganisation?.lgmIntegrations
    ) {
      setLgmApikeys(
        Object.values(currentOrganisation.lgmIntegrations).reduce(
          (integrations, integration) =>
            Object.assign(integrations, {
              [integration.apiKey]: integration.name,
            }),
          {
            removeIntegration: t(
              "workflow:formLabel.jobboard.output.removeIntegration"
            ),
          } as Record<string, string>
        )
      );
      setLgmApikeyOptions([
        ...Object.values(currentOrganisation.lgmIntegrations).map(
          (integration) => ({
            id: integration.apiKey,
            value: integration.name,
          })
        ),
        {
          id: "removeIntegration",
          value: t("workflow:formLabel.jobboard.output.removeIntegration"),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCurrentOrganisation, currentOrganisation?.lemlistIntegrations]);

  // Update Emelia integrations list
  useEffect(() => {
    if (
      !isLoadingCurrentOrganisation &&
      currentOrganisation &&
      currentOrganisation?.emeliaIntegrations
    ) {
      setEmeliaCampaigns(
        Object.values(currentOrganisation.emeliaIntegrations).reduce(
          (campaigns, integration) =>
            Object.assign(campaigns, {
              [integration.campaignId]: integration.campaignName,
            }),
          {
            removeCampaign: t(
              "workflow:formLabel.jobboard.output.removeCampaign"
            ),
          } as Record<string, string>
        )
      );
      setEmeliaCampaignsOptions([
        ...Object.values(currentOrganisation.emeliaIntegrations).map(
          (integration) => ({
            id: integration.campaignId,
            value: integration.campaignName,
          })
        ),
        {
          id: "removeCampaign",
          value: t("workflow:formLabel.jobboard.output.removeCampaign"),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCurrentOrganisation, currentOrganisation?.emeliaIntegrations]);

  return (
    <section aria-labelledby="output-details">
      <form onSubmit={onSubmit} noValidate>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2
                id="payment-details-heading"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {t("workflow:addJobboardWorkflowPage.outputStep.title")}
              </h2>
              <p className="max-w-2xl text-sm text-gray-500">
                {t("workflow:addJobboardWorkflowPage.outputStep.description")}
              </p>
            </div>
            {isLoadingWorkflow || isLoadingCurrentOrganisation ? (
              <div className="my-10 place-content-center">
                <Spinner size={20} />
              </div>
            ) : (
              <>
                {outputParams?.error && (
                  <Alert
                    variant="Error"
                    title={t(
                      "workflow:addJobboardWorkflowPage.outputStep.errorTitle"
                    )}
                    body={outputParams.error}
                  />
                )}
                <div className="mt-6 grid grid-cols-4 gap-6">
                  <Controller
                    control={control}
                    name="lemlistCampaignId"
                    rules={registerOptions.lemlistCampaignId}
                    render={({ field: { onChange } }) => {
                      return (
                        <CustomeSelectWithAction
                          className="text-left col-span-4 xl:w-1/2 xl:pr-3"
                          fullwidth
                          position="below"
                          label={t(
                            "workflow:formLabel.jobboard.output.lemlistCampaign"
                          )}
                          options={lemlistCampaignsOptions}
                          selected={
                            selectedLemlistCampaignId
                              ? {
                                  id: selectedLemlistCampaignId,
                                  value:
                                    lemlistCampaigns[selectedLemlistCampaignId],
                                }
                              : outputParams?.lemlist?.campaignId
                              ? {
                                  id: outputParams.lemlist.campaignId,
                                  value:
                                    lemlistCampaigns[
                                      outputParams.lemlist.campaignId
                                    ],
                                }
                              : {
                                  id: "noCampaignSelected",
                                  value: t(
                                    "workflow:formLabel.jobboard.output.pleaseSelectCampaign"
                                  ),
                                }
                          }
                          action={() => setShowLemlistDialog(true)}
                          onChange={(option) => onChange(option.id)}
                          error={errors.lemlistCampaignId?.message}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name="datananasSequenceId"
                    rules={registerOptions.datananasSequenceId}
                    render={({ field: { onChange } }) => {
                      return (
                        <CustomeSelectWithAction
                          className="text-left col-span-4 xl:w-1/2 xl:pr-3"
                          fullwidth
                          position="below"
                          label={t(
                            "workflow:formLabel.jobboard.output.datananasSequence"
                          )}
                          options={datananasSequencesOptions}
                          selected={
                            selectedDatananasSequenceId
                              ? {
                                  id: selectedDatananasSequenceId,
                                  value:
                                    datananasSequences[
                                      selectedDatananasSequenceId
                                    ],
                                }
                              : outputParams?.datananas?.sequenceId
                              ? {
                                  id: outputParams.datananas.sequenceId,
                                  value:
                                    datananasSequences[
                                      outputParams.datananas.sequenceId
                                    ],
                                }
                              : {
                                  id: "noSequenceSelected",
                                  value: t(
                                    "workflow:formLabel.jobboard.output.pleaseSelectSequence"
                                  ),
                                }
                          }
                          action={() => setShowDatananasDialog(true)}
                          onChange={(option) => onChange(option.id)}
                          error={errors.datananasSequenceId?.message}
                        />
                      );
                    }}
                  />
                  <Checkbox
                    className="col-span-4 xl:col-span-2 self-end"
                    name="datananasEngageLead"
                    label={t(
                      "workflow:formLabel.jobboard.output.datananasEngageLead"
                    )}
                    register={register}
                    registerOptions={registerOptions.datananasEngageLead}
                  />
                  <Controller
                    control={control}
                    name="lgmApiKey"
                    rules={registerOptions.lgmApiKey}
                    render={({ field: { onChange } }) => {
                      return (
                        <CustomeSelectWithAction
                          className="text-left col-span-4 xl:w-1/2 xl:pr-3"
                          fullwidth
                          position="below"
                          label={t(
                            "workflow:formLabel.jobboard.output.lgmIntegration"
                          )}
                          options={lgmApikeyOptions}
                          selected={
                            selectedLgmApikey
                              ? {
                                  id: selectedLgmApikey,
                                  value: lgmApikeys[selectedLgmApikey],
                                }
                              : outputParams?.lgm?.apiKey
                              ? {
                                  id: outputParams.lgm.apiKey,
                                  value: lgmApikeys[outputParams.lgm.apiKey],
                                }
                              : {
                                  id: "noIntegrationsSelected",
                                  value: t(
                                    "workflow:formLabel.jobboard.output.pleaseSelectIntegration"
                                  ),
                                }
                          }
                          action={() => setShowLgmDialog(true)}
                          onChange={(option) => onChange(option.id)}
                          error={errors.lgmApiKey?.message}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name="emeliaCampaignId"
                    rules={registerOptions.emeliaCampaignId}
                    render={({ field: { onChange } }) => {
                      return (
                        <CustomeSelectWithAction
                          className="text-left col-span-4 xl:w-1/2 xl:pr-3"
                          fullwidth
                          position="below"
                          label={t(
                            "workflow:formLabel.jobboard.output.emeliaCampaign"
                          )}
                          options={emeliaCampaignsOptions}
                          selected={
                            selectedEmeliaCampaignId
                              ? {
                                  id: selectedEmeliaCampaignId,
                                  value:
                                    emeliaCampaigns[selectedEmeliaCampaignId],
                                }
                              : outputParams?.emelia?.campaignId
                              ? {
                                  id: outputParams.emelia.campaignId,
                                  value:
                                    emeliaCampaigns[
                                      outputParams.emelia.campaignId
                                    ],
                                }
                              : {
                                  id: "noCampaignSelected",
                                  value: t(
                                    "workflow:formLabel.jobboard.output.pleaseSelectCampaign"
                                  ),
                                }
                          }
                          action={() => setShowEmeliaDialog(true)}
                          onChange={(option) => onChange(option.id)}
                          error={errors.lemlistCampaignId?.message}
                        />
                      );
                    }}
                  />
                  <TextArrayField
                    className="col-span-4 lg:col-span-3"
                    register={register}
                    control={control}
                    registerOptions={registerOptions.csvByEmails}
                    trigger={trigger}
                    setFocus={setFocus}
                    name="csvByEmails"
                    label={t("workflow:formLabel.jobboard.output.csvByEmail")}
                    placeholder={t(
                      "workflow:formLabel.jobboard.output.emailPlaceholder"
                    )}
                    type="email"
                    errors={errors.csvByEmails}
                  />
                </div>
              </>
            )}
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <Button
              type="submit"
              size="sm"
              children={t("common:action.next")}
              isLoading={isLoadingWorkflow || isLoadingUpdateWorkflow}
            />
          </div>
        </div>
      </form>
      <LemlistAddIntegrationModal
        show={showLemlistDialog}
        onClose={() => setShowLemlistDialog(false)}
      />
      <DatananasAddIntegrationModal
        show={showDatananasDialog}
        onClose={() => setShowDatananasDialog(false)}
      />
      <LgmAddIntegrationModal
        show={showLgmDialog}
        onClose={() => setShowLgmDialog(false)}
      />
      <EmeliaAddIntegrationModal
        show={showEmeliaDialog}
        onClose={() => setShowEmeliaDialog(false)}
      />
    </section>
  );
};
