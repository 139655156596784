import { FC, useState } from "react";
import { useCurrentOrganisation } from "../organisation/organisationService";
import { Controller, RegisterOptions, useForm } from "react-hook-form";
import { Modal } from "../ui/overlay";
import { Button } from "../ui/buttons";
import { CustomSelect, CustomSelectOption } from "../ui/inputs";
import { useTranslation } from "react-i18next";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { TextFieldWithAction } from "../ui/inputs/TextFieldWithAction";
import { RefreshIcon } from "@heroicons/react/solid";
import { Spinner } from "../ui/icons";

type LemlistAddIntegrationModalData = {
  apiKey: string;
  campaignId: string;
};

export const LemlistAddIntegrationModal: FC<{
  show: boolean;
  onClose: () => void;
}> = ({ show, onClose }) => {
  // UI
  const { t } = useTranslation(["common", "integration"]);
  const [isFetchingCampaigns, setIsFetchingCampaigns] = useState(false);

  // Form
  const [campaignsOptions, setCampaignsOptions] = useState<
    CustomSelectOption[]
  >([
    {
      id: "noCampaignSelected",
      value: t("integration:lemlistModal.pleaseSelectApiKey"),
    },
  ]);
  const [campaigns, setCampaigns] = useState<Record<string, string>>();

  const listLemlistCampaigns = httpsCallable<
    CoolsalesCallable.Lemlist.ListCampaignsData,
    CoolsalesCallable.Lemlist.ListCampaignsResponse
  >(functions, "salesAutomation-lemlistListCampaigns");

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    reset,
  } = useForm<LemlistAddIntegrationModalData>({
    defaultValues: { campaignId: "noCampaignSelected" },
  });

  const apiKey = watch("apiKey");
  const selectedCampaignId = watch("campaignId");

  const registerOptions: Record<
    keyof LemlistAddIntegrationModalData,
    RegisterOptions
  > = {
    apiKey: {
      required: t("integration:fomrValidation.apiKeyRequired"),
    },
    campaignId: {
      required: t("integration:fomrValidation.campaignIdRequired"),
      validate: (value) => {
        return (
          value !== "noCampaignSelected" ||
          t("integration:fomrValidation.campaignIdRequired")
        );
      },
    },
  };

  const { addLemlistIntegration, isLoadingUpdate } = useCurrentOrganisation();

  const handleFetchCampaignList = async () => {
    if (!apiKey) {
      setError("apiKey", {
        message: t("integration:fomrValidation.apiKeyRequired"),
      });
    } else {
      setIsFetchingCampaigns(true);
      try {
        const response = await listLemlistCampaigns({ apiKey });
        if (response && response.data) {
          clearErrors("apiKey");
          setCampaigns(
            response.data.reduce(
              (campaigns, currentCampaign) =>
                Object.assign(campaigns, {
                  [currentCampaign._id]: currentCampaign.name,
                }),
              {
                noCampaignSelected: t(
                  "integration:lemlistModal.pleaseSelectCampaign"
                ),
              } as Record<string, string>
            )
          );
          setCampaignsOptions([
            {
              id: "noCampaignSelected",
              value: t("integration:lemlistModal.pleaseSelectCampaign"),
            },
            ...response.data.map((campaign) => ({
              id: campaign._id,
              value: campaign.name,
            })),
          ]);
          reset({ apiKey, campaignId: "noCampaignSelected" });
          setIsFetchingCampaigns(false);
        }
      } catch (error) {
        setIsFetchingCampaigns(false);
        setError("apiKey", {
          message: t("integration:fomrValidation.wrongApiKey"),
        });
      }
    }
  };

  const onSubmit = handleSubmit((data) => {
    if (addLemlistIntegration && campaigns) {
      addLemlistIntegration(
        data.apiKey,
        data.campaignId,
        campaigns[data.campaignId]
      );
      onClose();
    }
    reset({ apiKey: "", campaignId: "noCampaignSelected" });
    setCampaigns({});
    setCampaignsOptions([
      {
        id: "noCampaignSelected",
        value: t("integration:lemlistModal.pleaseSelectApiKey"),
      },
    ]);
  });

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={t("integration:lemlistModal.title")}
      size="md"
    >
      <form className="space-y-6" onSubmit={onSubmit}>
        <TextFieldWithAction
          name="apiKey"
          type="text"
          register={register}
          registerOptions={registerOptions.apiKey}
          action={handleFetchCampaignList}
          actionIcon={
            isFetchingCampaigns ? (
              <Spinner size={5} />
            ) : (
              <RefreshIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )
          }
          error={errors.apiKey?.message}
          label={t("integration:formLabel.apiKey")}
        />
        <Controller
          control={control}
          name="campaignId"
          rules={registerOptions.campaignId}
          render={({ field: { onChange } }) => {
            return (
              <CustomSelect
                className="text-left"
                fullwidth
                position="below"
                label={t("integration:formLabel.campaignId")}
                options={campaignsOptions}
                selected={
                  selectedCampaignId && campaigns
                    ? {
                        id: selectedCampaignId,
                        value: campaigns[selectedCampaignId],
                      }
                    : {
                        id: "noCampagnSelected",
                        value: t("integration:lemlistModal.pleaseSelectApiKey"),
                      }
                }
                onChange={(option) => onChange(option.id)}
                error={errors.campaignId?.message}
              />
            );
          }}
        />
        <Button
          type="submit"
          children={t("common:action.save")}
          size="sm"
          isLoading={isLoadingUpdate}
        />
      </form>
    </Modal>
  );
};
