import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router";
import { useQuery } from "../../utils";
import { TabsProps } from "../ui/navigation";
import { LeadPage } from "./LeadPage";
import { useGetLead } from "./LeadService";

export const Lead: FC = () => {
  // Routing
  const {
    url,
    params: { organisationId, leadId },
  } = useRouteMatch<{ organisationId: string; leadId: string }>();
  const query = useQuery();
  const currentItem = query.get("current") || "info";

  //UI
  const { t } = useTranslation(["common", "lead"]);

  // Lead
  const { isLoading, data: lead } = useGetLead(organisationId)(leadId);

  const menu: TabsProps["menu"] = [
    {
      name: t("lead:leadPage.menu.Information"),
      to: `${url}?current=info`,
      current: currentItem === "info",
    },
    {
      name: t("lead:leadPage.menu.Contacts"),
      to: `${url}?current=contacts`,
      current: currentItem === "contacts",
    },
    /* {
      name: t("lead:leadPage.menu.Events"),
      to: `${url}?current=events`,
      current: currentItem === "events",
    }, */
  ];

  return (
    <LeadPage
      menu={menu}
      isLoading={isLoading}
      name={lead?.companyData?.name || "No name"}
      lead={lead}
    />
  );
};
