export const currentEnvironment: "local" | "staging" | "production" =
  process.env.NODE_ENV === "development"
    ? "local"
    : process.env.REACT_APP_DEPLOY_IN_PRODUCTION
    ? "production"
    : "staging";

export const appBaseUrl =
  currentEnvironment === "production"
    ? "https://app.coolsales.io/"
    : currentEnvironment === "staging"
    ? "https://staging.coolsales.io/"
    : "http://localhost:3000";

export const extensionId =
  currentEnvironment === "local"
    ? "oijjgnanhppgoojdempmaomkdakgibpb"
    : "iibkldildillmodahjigghaimfbmikkk";

export const endpoint =
  currentEnvironment === "production"
    ? "https://europe-west1-api-coolsales.cloudfunctions.net"
    : currentEnvironment === "staging"
    ? "https://europe-west1-staging-api-coolsales.cloudfunctions.net"
    : "http://localhost:5001/staging-api-coolsales/europe-west1";

// Cannot be import from Shared ressources as CRA doesn't allow import from outside src
export const supportedLanguages: Shared.IAppLanguage[] = ["en", "fr"];
