import { Popover, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationCircleIcon,
  SelectorIcon,
} from "@heroicons/react/outline";
import { FC, Fragment } from "react";
import { NestedValue, RegisterOptions, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../utils";

export type MultiSelectOption = {
  id: string;
  value: string;
};

type MultiSelectProps = {
  className?: string;
  options: MultiSelectOption[];
  selectedOptions?: NestedValue<string[]>; // option id
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
  name: string;
  label: string;
  fullwidth?: boolean;
  error?: string;
};

export const MultiSelect: FC<MultiSelectProps> = ({
  className = "",
  options,
  selectedOptions,
  register,
  registerOptions,
  name,
  fullwidth,
  label,
  error,
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <Popover
      as="div"
      id="menu"
      className={classNames(
        "relative z-10 inline-block text-left",
        fullwidth ? "w-full" : "",
        className
      )}
    >
      <div>
        {label && (
          <p className="block text-sm font-medium text-gray-700">{label}</p>
        )}
        <Popover.Button
          className={classNames(
            "bg-white relative w-full",
            label ? "mt-1" : "",
            "border border-gray-300 rounded-md shadow-sm pl-3 pr-10 pt-2 pb-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-light focus:border-primary-light sm:text-sm"
          )}
        >
          <span className="flex flex-row truncate">
            {error && (
              <div className="pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
            {selectedOptions === undefined || selectedOptions.length === 0
              ? t("common:format.xSelected", {
                  selected: 0,
                })
              : selectedOptions.length < 6
              ? options
                  .filter((option) => selectedOptions.includes(option.id))
                  .map((option) => option.value)
                  .join(",")
              : t("common:format.xSelected", {
                  selected: selectedOptions.length,
                })}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Popover.Button>
        {error && (
          <p className="mt-2 text-sm text-left text-red-600">{error}</p>
        )}
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="origin-top-right absolute w-full max-h-60 right-0 mt-2 bg-white rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
          {options.map((option, optionIdx) => {
            const isSelected =
              selectedOptions && selectedOptions.includes(option.id);
            return (
              <div
                key={optionIdx}
                className="flex items-center p-2 hover:bg-primary-light"
              >
                <input
                  id={option.id}
                  value={option.id}
                  {...register(name, registerOptions)}
                  type="checkbox"
                  className="invisible"
                />
                <label
                  htmlFor={option.id}
                  className="relative w-full text-base text-gray-900"
                >
                  <span
                    className={classNames(
                      isSelected ? "font-semibold" : "font-normal",
                      "block truncate"
                    )}
                  >
                    {option.value}
                  </span>
                  {isSelected ? (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </label>
              </div>
            );
          })}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
