import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PageLayout } from "../ui/layout/PageLayout";
import { StatsWithIcon } from "../ui/stats";
import type { StatsWithIconProps } from "../ui/stats/";

export const DashboardPage: FC<{ stats: StatsWithIconProps[] }> = ({
  stats,
}) => {
  const { t } = useTranslation(["common", "dashboard"]);
  return (
    <PageLayout
      title={t("dashboard:dashboardPage.title")}
      maxWidthDesktop="max-w-5xl"
    >
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <StatsWithIcon key={item.name} {...item} />
        ))}
      </dl>
    </PageLayout>
  );
};
