import { FC } from "react";
import { ViewGridAddIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { hasNotAccessBelow } from "../../utils";
import { Button } from "../ui/buttons";
import { PageLayout } from "../ui/layout/PageLayout";
import { WorfklowsList, WorfklowsListProps } from "./components/WorkflowsList";

type WorkflowsPageProps = {
  currentUserRole: Shared.IUserRoles;
  pathToChooseWorflow: string;
  workflowListProps: WorfklowsListProps;
  hasLinkedinIntegration: boolean;
  canAddWorkflow: boolean;
};

export const WorkflowsPage: FC<WorkflowsPageProps> = ({
  currentUserRole,
  pathToChooseWorflow,
  workflowListProps,
  canAddWorkflow,
  hasLinkedinIntegration,
}) => {
  const { t } = useTranslation(["common", "workflow"]);
  return (
    <PageLayout
      title={t("workflow:workflowsPage.title")}
      maxWidthDesktop="max-w-5xl"
      trailings={[
              <Link
                to={{
                  pathname: pathToChooseWorflow,
                  state: { modal: true },
                }}
                children={
                  <Button
                    type="button"
                    children={t("workflow:workflowsPage.trailingAction")}
                    size="sm"
                    disbaled={hasNotAccessBelow(currentUserRole, "EDITOR")}
                  />
                }
              />,
            ]
      }
    >
      {workflowListProps.workflows.length > 0 ? (
        <WorfklowsList {...workflowListProps} />
      ) : (
        <div className="text-center">
          <ViewGridAddIcon className="mx-auto h-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            {t("workflow:workflowsPage.addLinkedinIntegration.title")}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t("workflow:workflowsPage.addLinkedinIntegration.body")}
          </p>
          <div className="mt-6">
            <Link
              to={{
                  pathname: pathToChooseWorflow,
                  state: { modal: true },
                }}
              children={
                <Button
                  type="button"
                  children={t(
                    "workflow:workflowsPage.addLinkedinIntegration.action"
                  )}
                />
              }
            />
          </div>
        </div>
      )}
    </PageLayout>
  );
};
