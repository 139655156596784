import { FC, useState } from "react";
import { Redirect } from "react-router";
import { useForm, RegisterOptions } from "react-hook-form";
import { RegisterPage } from "./RegisterPage";
import { useAuth } from "../authentification/AuthProvider";
import { useTranslation } from "react-i18next";
import { useAddOrganisation } from "../organisation";
import { useOverlay } from "../overlay";
import { getSupportedLanguageCode } from "../../utils/getLanguageCode";
import { useCurrentUser } from ".";
import { currentEnvironment } from "../config";
import { getCleanUrl } from "../../utils";

export type RegisterFormData = {
  displayName: string;
  personae: string;
  organisationName: string;
  organisationDomain: string;
  organisationPhone: string;
  organisationSize: string;
};

export const Register: FC<{ userId: string }> = ({ userId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<RegisterFormData>();

  const { t, i18n } = useTranslation(["common", "user"]);

  const { pushNotification } = useOverlay();

  const { authState } = useAuth();

  const { isLoading: isLoadingAddOrganisation, add: addOrganisation } =
    useAddOrganisation();

  const { isLoadingUpdate: isLoadingUpdateUser, updateUser } = useCurrentUser();

  const [isWaitingForCustomClaim, setIsWaitingForCustomClaim] = useState(false);

  const registerOptions: Record<keyof RegisterFormData, RegisterOptions> = {
    displayName: {
      required: t("user:formValidation.displayNameRequired"),
      maxLength: {
        value: 60,
        message: t("common:formValidation.max60Characters"),
      },
    },
    personae: {
      required: false,
    },
    organisationName: {
      required: t("user:formValidation.organisationNameRequired"),
      maxLength: {
        value: 60,
        message: t("common:formValidation.max60Characters"),
      },
    },
    organisationPhone: {
      required: false,
    },
    organisationDomain: {
      required: false,
    },
    organisationSize: { required: false },
  };

  const onSubmit = handleSubmit((data) => {
    setIsWaitingForCustomClaim(true);
    const promises = [];
    if (updateUser) {
      // Create organisation
      promises.push(
        addOrganisation({
          name: data.organisationName,
          domain: getCleanUrl(data.organisationDomain),
          phone: data.organisationPhone,
          companySize: data.organisationSize,
          users: {
            [userId]: {
              role: "ADMIN",
              email: authState.user?.email || undefined,
              displayName: data.displayName,
            },
          },
        })
      );
      // Update User with display name and personae
      promises.push(
        updateUser({
          displayName: data.displayName,
          personae: data.personae,
          prefferedLanguage: getSupportedLanguageCode(i18n.language),
        })
      );

      return Promise.all(promises).catch((e) => {
        pushNotification({
          severity: "error",
          title: "Error",
          body:
            currentEnvironment !== "production"
              ? e.message
              : "An error occured when creating your organisation, please reload the page",
        });
      });
    }
  });

  // User state include a favOrganisationID, custom claim have been updated. Redirect to the organisation page
  if (authState.favOrganisationId) {
    return (
      <Redirect
        to={`/organisations/${authState.favOrganisationId}/dashboard`}
      />
    );
  } else {
    return (
      <RegisterPage
        register={register}
        control={control}
        registerOptions={registerOptions}
        onSubmit={onSubmit}
        errors={errors}
        isLoading={
          isLoadingAddOrganisation ||
          isLoadingUpdateUser ||
          isWaitingForCustomClaim
        }
      />
    );
  }
};
