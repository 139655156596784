import { FC } from "react";
import { classNames } from "../../../utils";

type Variant = "primary" | "secondary" | "white";
const sizes = {
  1: "h-1",
  2: "h-2",
  3: "h-3",
  4: "h-4",
  5: "h-5",
  6: "h-6",
  7: "h-7",
  8: "h-8",
  9: "h-9",
  10: "h-10",
  12: "h-12",
  14: "h-14",
  16: "h-16",
  20: "h-20",
  24: "h-24",
  28: "h-28",
  32: "h-32",
  36: "h-36",
  40: "h-40",
};

export const Spinner: FC<{
  variant?: Variant;
  size?: keyof typeof sizes;
  className?: string;
}> = ({ variant = "primary", size = 12, className = "" }) => (
  <svg
    className={classNames(
      "animate-spin w-full",
      sizes[size],
      variant === "white"
        ? "text-white"
        : variant === "secondary"
        ? "text-secondary-light"
        : "text-primary-light",
      className
    )}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);
