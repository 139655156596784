import Tippy from "@tippyjs/react/headless";
import { FC } from "react";
import { classNames } from "../../../utils";

type Variant =
  | "primary"
  | "secondary"
  | "red"
  | "white"
  | "textPrimary"
  | "textSecondary"
  | "textRed"
  | "iconOnly";

type Size = "xs" | "sm" | "base";

type ButtonProps = {
  className?: string;
  type: "submit" | "button";
  onClick?: (e?: React.BaseSyntheticEvent) => Promise<void> | void;
  variant?: Variant;
  size?: Size;
  fullwidth?: boolean;
  isLoading?: boolean;
  disbaled?: boolean;
  tooltip?: string;
};

const variants: Record<Variant, string> = {
  primary:
    "text-white border border-transparent bg-primary hover:bg-primary-dark focus:ring-2 focus:ring-offset-2 focus:ring-primary-light rounded shadow-sm",
  secondary:
    "text-gray-700 border border-transparent bg-secondary hover:bg-secondary-dark focus:ring-2 focus:ring-offset-2 focus:ring-secondary-light rounded shadow-sm",
  red: "text-white border border-transparent bg-red-600 hover:bg-red-700 focus:ring-2 focus:ring-offset-2 focus:ring-red-500 rounded shadow-sm",
  white:
    "text-gray-700 border border-gray-300 bg-white hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-primary-light rounded shadow-sm",
  textPrimary: "text-primary hover:text-primary-dark focus:ring-primary-light",
  textSecondary:
    "text-secondary hover:text-secondary-dark focus:ring-secondary-light",
  textRed: "text-red-600 hover:text-red-800 focus:ring-red-600",
  iconOnly: "",
};

const sizes: Record<Size, string> = {
  xs: "px-2.5 py-1.5 text-xs",
  sm: "px-4 py-2 text-sm",
  base: "px-6 py-3 text-base",
};

export const Button: FC<ButtonProps> = ({
  className = "",
  type,
  onClick,
  variant = "primary",
  size = "base",
  fullwidth,
  isLoading = false,
  disbaled = false,
  tooltip,
  children,
}) => {
  const Button = (
    <button
      type={type}
      onClick={onClick}
      className={classNames(
        className,
        fullwidth ? "w-full justify-center" : "",
        "inline-flex items-center font-medium focus:outline-none disabled:opacity-50",
        variants[variant],
        sizes[size]
      )}
      disabled={isLoading || disbaled}
    >
      {isLoading && ["primary", "secondary", "white"].includes(variant) && (
        <Spinner variant={variant} />
      )}
      {children}
    </button>
  );

  if (tooltip) {
    return (
      <Tippy
        placement="bottom"
        render={(attrs) => (
          <div
            className="bg-white p-2 rounded-md text-gray-800 text-xs shadow-sm"
            tabIndex={-1}
            {...attrs}
          >
            {tooltip}
          </div>
        )}
      >
        {Button}
      </Tippy>
    );
  } else {
    return Button;
  }
};

const Spinner: FC<{ variant: Variant }> = ({ variant }) => (
  <svg
    className={classNames(
      "animate-spin -ml-1 mr-3 h-5 w-5",
      variant === "white" ? "text-primary-light" : "text-white"
    )}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);
