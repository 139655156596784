import { FC } from "react";
import {
  CalendarIcon,
  ClipboardListIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { Button } from "../../ui/buttons";
/* import { EnableDisbaleToggle } from "../../ui/toggle"; */
import { hasNotAccessBelow } from "../../../utils";
import { useTranslation } from "react-i18next";

export type IntegrationsListProps = {
  id?: string;
  name?: string;
  level?: string;
  createdBy?: string;
  enabled?: boolean;
  lastRefreshed?: string;
  editQuota?: () => void;
  toogleIntegration: (value: boolean) => Promise<void> | void;
  deleteIntegration: () => void;
  isLoading: boolean;
};

export const IntegrationsList: FC<{
  integrations: IntegrationsListProps[];
  currentUserRole: Shared.IUserRoles;
}> = ({ integrations, currentUserRole }) => {
  const { t } = useTranslation(["common", "integration"]);
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {integrations.map((integration) => (
          <li key={integration.id}>
            <div className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-primary truncate">
                      {integration.name}
                      {integration.level && (
                        <span className="ml-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                          {integration.level}
                        </span>
                      )}
                    </p>
                    <p className="mt-2 text-xs text-gray-500 truncate">
                      {t("common:data.addedBy")} {integration.createdBy}
                    </p>
                  </div>
                  <div className="ml-2 flex-shrink-0 flex gap-2">
                    {/* <EnableDisbaleToggle
                      enabled={
                        integration.enabled === undefined
                          ? true
                          : integration.enabled
                      }
                      onChange={integration.toogleIntegration}
                      disbaled={
                        integration.isLoading ||
                        hasNotAccessBelow(currentUserRole, "EDITOR")
                      }
                    /> */}
                    {integration.editQuota && (
                      <Button
                        type="button"
                        size="xs"
                        variant="white"
                        onClick={integration.editQuota}
                        disbaled={
                          integration.isLoading ||
                          hasNotAccessBelow(currentUserRole, "EDITOR")
                        }
                        tooltip={t("integration:integrationList.editQuota")}
                        children={<ClipboardListIcon className="w-5 h-5" />}
                      />
                    )}
                    <Button
                      type="button"
                      size="xs"
                      variant="white"
                      onClick={integration.deleteIntegration}
                      disbaled={
                        integration.isLoading ||
                        hasNotAccessBelow(currentUserRole, "EDITOR")
                      }
                      tooltip={t("integration:integrationList.delete")}
                      children={<TrashIcon className="w-5 h-5" />}
                    />
                  </div>
                </div>
                {integration.lastRefreshed ? (
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        <CalendarIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {t("integration:integrationList.lastRefreshed")}{" "}
                        {integration.lastRefreshed}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
