import { FirestoreService, logAnalyticsEvent, storage } from "../firebase";
import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "@firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  createServiceHooks,
  createSnapshotDocumentHook,
  createUpdateHook,
} from "../firebase/firestoreService";
import { useAuth } from "../authentification";
import { useState } from "react";
import { useOverlay } from "../overlay";
import { useTranslation } from "react-i18next";
import { currentEnvironment } from "../config";

export default class UserService extends FirestoreService<Shared.IUser> {
  constructor(public path?: string) {
    super("users", path);
  }

  getModelConverter() {
    const serverTimestamp = this.serverTimestamp;
    return {
      toFirestore(data: Shared.IUser): DocumentData {
        return {
          createdAt: serverTimestamp,
          email: data.email,
          displayName: data.displayName,
          prefferedLanguage: data.prefferedLanguage,
          photoUrl: data.photoUrl,
          personae: data.personae,
        };
      },
      fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
      ): Shared.IUser {
        const data = snapshot.data(options);
        return {
          id: snapshot.id,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          email: data.email,
          photoUrl: data.photoUrl,
          displayName: data.displayName,
          prefferedLanguage: data.prefferedLanguage,
          personae: data.personae,
        };
      },
    };
  }
}

export const {
  snapshot: useUsersSnapshot,
  snapshotDocument: useUserSnapshot,
  get: useGetUser,
  list: useListUsers,
  add: useAddUser,
  update: useUpdateUser,
  softDelete: useSoftDeleteUser,
} = createServiceHooks(new UserService());

export const useCurrentUser = () => {
  const { t, i18n } = useTranslation(["common", "user"]);
  const { pushNotification } = useOverlay();
  const { authState, setAuthLanguage } = useAuth();
  const userService = new UserService();
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const { updateProfile } = useAuth();

  if (authState.user?.uid) {
    const userId = authState.user?.uid;

    // Serve current user as a snapshot
    const { isLoading: isLoadingUser, document: currentUser } =
      createSnapshotDocumentHook(userService)(userId);

    const { isLoading: isLoadingUpdate, update } =
      createUpdateHook(userService)();

    const uploadPhoto = (photo: File) => {
      setUploadingPhoto(true);
      const fileRef = ref(storage, `profilePictures/${userId}`);
      uploadBytes(fileRef, photo)
        .then((uploadResult) => {
          setUploadingPhoto(false);
          pushNotification({
            severity: "success",
            title: "Photo uploaded",
          });
          logAnalyticsEvent("upload_photo");
          return getDownloadURL(uploadResult.ref);
        })
        .then((url) => {
          return updateUser({ photoUrl: url });
        })
        .catch((error) =>
          pushNotification({
            severity: "error",
            title: "Error",
            body:
              currentEnvironment !== "production"
                ? error.message
                : "Your photo was not uploaded",
          })
        );
    };

    const updateUser = (data: Shared.IUser) => {
      update(userId, { ...data })
        .then(() => {
          logAnalyticsEvent("update_user");
          pushNotification({
            severity: "success",
            title: "User details updated",
          });
        })
        .catch((error) =>
          pushNotification({
            severity: "error",
            title: "Error",
            body:
              currentEnvironment !== "production"
                ? error.message
                : t("user:error.updateDetailsError"),
          })
        );

      // Update firebase authentification user
      if (data.displayName || data.photoUrl) {
        updateProfile({
          displayName: data.displayName,
          photoUrl: data.photoUrl,
        });
      }

      // Change app and auth language
      if (data.prefferedLanguage) {
        i18n.changeLanguage(data.prefferedLanguage);
        setAuthLanguage(data.prefferedLanguage);
      }
    };

    return {
      isLoadingUser,
      currentUser,
      updateUser,
      isLoadingUpdate,
      uploadPhoto,
      uploadingPhoto,
    };
  } else {
    return { isLoadingUser: true };
  }
};
