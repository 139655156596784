import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  useCurrentUserPendingAndAcceptedInvitations,
  useUpdateInvitation,
} from ".";
import { useAuth } from "../authentification";
import { useOverlay } from "../overlay";
import { InvitationPage } from "./InvitationPage";

export const Invitation: FC = () => {
  const history = useHistory();

  const {
    authState: { favOrganisationId, user },
  } = useAuth();

  const { t } = useTranslation(["common"]);

  const { pushNotification } = useOverlay();

  const { isLoadingPendingInvitation, pendingInvitations } =
    useCurrentUserPendingAndAcceptedInvitations();

  const { isLoading, update } = useUpdateInvitation();

  const handleAcceptInvitation = (invitationId?: string) => {
    if (invitationId && update) {
      update(invitationId, {
        status: "ACCEPTED",
        acceptedBy: {
          userId: user?.uid,
          email: user?.email || undefined,
          displayName: user?.displayName || undefined,
          photoUrl: user?.photoURL || undefined,
        },
      }).then(() => {
        /* pushNotification({
          severity: "info",
          title: "please wait",
        }); */
        history.push("/");
      });
    } else {
      pushNotification({
        severity: "warning",
        title: t("common:errors.serviceNotInitialized"),
      });
    }
  };

  return (
    <InvitationPage
      favOrganisationId={favOrganisationId}
      isLoadingPendingInvitation={isLoadingPendingInvitation}
      isLoading={isLoading}
      pendingInvitations={pendingInvitations}
      acceptInvitation={handleAcceptInvitation}
    />
  );
};
