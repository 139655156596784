import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Row, Table } from "../../ui/list/Table";

type LeadContactsProps = { contacts: Shared.ILead["contacts"] };

export const LeadContacts: FC<LeadContactsProps> = ({ contacts }) => {
  // UI
  const { t } = useTranslation(["common", "lead"]);
  const heads = [
    { name: t("lead:leadPage.leadContacts.firstname") },
    { name: t("lead:leadPage.leadContacts.lastName") },
    { name: t("lead:leadPage.leadContacts.title") },
    { name: t("lead:leadPage.leadContacts.createdAt") },
    { name: t("lead:leadPage.leadContacts.email") },
  ];

  return (
    <Table
      heads={heads}
      rows={
        contacts &&
        Object.values(contacts).map(
          (contact): Row => [
            { type: "text", value: contact.firstName || "" },
            { type: "text", value: contact.lastName || "" },
            { type: "text", value: contact.title || "" },
            {
              type: "text",
              value:
                t("lead:leadPage.leadContacts.createdAtDate", {
                  date: contact.createdAt?.toDate(),
                }) || "",
            },
            { type: "text", value: contact.email || "" },
          ]
        )
      }
    />
  );
};
