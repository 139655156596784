import { extensionId, endpoint } from "../components/config";

export type WithSuccess = (payload: any) => void;
export type WithFailure = (
  errorType:
    | "EXTENSION_NOT_FOUND"
    | "UNKNOWN"
    | "UNKNOWN_EXTENSION_ERROR"
    | "NOT_ON_CHROME"
    | "NO_EXTENSION_INSTALLED"
    | "COOKIE_NOT_FOUND"
) => void;

/**
 * Send a message to an extension with the given payload
 * Call withSucces or withFailure callback depending on the resposne
 * @param payload The payload to send
 * @param withSuccess The callback in case of success
 * @param withFailure The callback in case of failure
 */
const sendMessage = (
  payload: Record<string, any>,
  withSuccess: WithSuccess,
  withFailure: WithFailure
) => {
  try {
    //@ts-ignore
    if (!!chrome) {
      //@ts-ignore
      if (!!chrome.runtime) {
        //@ts-ignore
        chrome?.runtime?.sendMessage(extensionId, payload, (response: any) => {
          //@ts-ignore
          if (chrome.runtime.lastError) {
            //@ts-ignore
            switch (chrome.runtime.lastError.message) {
              case "Could not establish connection. Receiving end does not exist.":
                withFailure("EXTENSION_NOT_FOUND");
                break;

              default:
                withFailure("UNKNOWN");
            }
          } else if (response.success) {
            withSuccess(response);
          } else if (response.error) {
            switch (response.error) {
              case "Cookies not found":
                withFailure("COOKIE_NOT_FOUND");
                break;

              default:
                withFailure("UNKNOWN");
                break;
            }
          } else {
            withFailure("UNKNOWN");
          }
        });
      } else {
        withFailure("NO_EXTENSION_INSTALLED");
      }
    } else {
      withFailure("NOT_ON_CHROME");
    }
  } catch (error) {
    withFailure("UNKNOWN");
  }
};

/**
 * Send the user information to the extension
 * @param token The user token (not decoded)
 * @param withSuccess The callback in case of success
 * @param withFailure The callback in case of failure
 */
export const sendUserInfoToExtension = (
  token: string,
  withSucces: WithSuccess,
  withFailure: WithFailure
) => {
  sendMessage(
    {
      action: "coolsales_set_user",
      payload: {
        user: {
          token: token,
          endpoint: `${endpoint}/tasksQueue-api`,
        },
      },
    },
    withSucces,
    withFailure
  );
};

/**
 * Send an integration info to the extension
 * @param integrationUid The integration Firestore UID
 * @param domain The domain of the desired cookies
 * @param keys The key of the desired cookies
 * @param withSuccess The callback in case of success
 * @param withFailure The callback in case of failure
 */
export const sendIntegrationInfoToExtension = (
  integrationUid: string,
  domain: string,
  keys: string[],
  withSucces: WithSuccess,
  withFailure: WithFailure
) => {
  sendMessage(
    {
      action: "coolsales_set_integration",
      payload: {
        integrationUid,
        domain,
        keys,
      },
    },
    withSucces,
    withFailure
  );
};

/**
 * Send a refresh request of cookies for existing integrations
 * @param withSuccess The callback in case of success
 * @param withFailure The callback in case of failure
 */
export const sendRefreshCookieRequestToExtension = (
  withSucces: WithSuccess,
  withFailure: WithFailure
) => {
  sendMessage(
    {
      action: "coolsales_refresh_cookies",
    },
    withSucces,
    withFailure
  );
};
