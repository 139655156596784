export const countries = [
  {
    code: "01",
    libelle: "France",
  },
  {
    code: "04",
    libelle: "Belgique",
  },
];
/* export const countries = [
  {
    code: "65",
    libelle: "Chili",
  },
  {
    code: "5S",
    libelle: "Somalie",
  },
  {
    code: "3K",
    libelle: "Djibouti",
  },
  {
    code: "4C",
    libelle: "Guyana",
  },
  {
    code: "6I",
    libelle: "Zimbabwe",
  },
  {
    code: "5C",
    libelle: "Papouasie Nouvelle Guinée",
  },
  {
    code: "1O",
    libelle: "Guam Mariannes Nord et Samoa ",
  },
  {
    code: "1X",
    libelle: "Tuvalu",
  },
  {
    code: "59",
    libelle: "Ouzbékistan",
  },
  {
    code: "5R",
    libelle: "Sierra Leone",
  },
  {
    code: "70",
    libelle: "Arabie Saoudite",
  },
  {
    code: "1A",
    libelle: "Akrotiri et Dekhelia",
  },
  {
    code: "3S",
    libelle: "Gambie",
  },
  {
    code: "11",
    libelle: "Grèce",
  },
  {
    code: "8A",
    libelle: "Corée du Sud",
  },
  {
    code: "3L",
    libelle: "Dominique",
  },
  {
    code: "1F",
    libelle: "Montserrat",
  },
  {
    code: "38",
    libelle: "Egypte",
  },
  {
    code: "1K",
    libelle: "Palestine",
  },
  {
    code: "1B",
    libelle: "Groenland",
  },
  {
    code: "57",
    libelle: "Oman",
  },
  {
    code: "49",
    libelle: "Madagascar",
  },
  {
    code: "1N",
    libelle: "Cook, Niue et Tokelau",
  },
  {
    code: "5L",
    libelle: "St-Vincent Grenadines",
  },
  {
    code: "45",
    libelle: "Tchad",
  },
  {
    code: "1I",
    libelle: "Antilles Néerland.",
  },
  {
    code: "4L",
    libelle: "Kazakhstan",
  },
  {
    code: "05",
    libelle: "Belgique (Frontalier)",
  },
  {
    code: "86",
    libelle: "Singapour",
  },
  {
    code: "6Q",
    libelle: "Timor Oriental",
  },
  {
    code: "09",
    libelle: "Royaume-Uni",
  },
  {
    code: "4G",
    libelle: "Maurice",
  },
  {
    code: "5H",
    libelle: "Qatar",
  },
  {
    code: "37",
    libelle: "Côte d'Ivoire",
  },
  {
    code: "6E",
    libelle: "Uruguay",
  },
  {
    code: "5J",
    libelle: "Rwanda",
  },
  {
    code: "4Q",
    libelle: "Lesotho",
  },
  {
    code: "3J",
    libelle: "Cuba",
  },
  {
    code: "23",
    libelle: "Suisse",
  },
  {
    code: "34",
    libelle: "Cameroun",
  },
  {
    code: "66",
    libelle: "Colombie",
  },
  {
    code: "2G",
    libelle: "Albanie",
  },
  {
    code: "13",
    libelle: "Irlande",
  },
  {
    code: "4B",
    libelle: "Afrique du Sud",
  },
  {
    code: "6G",
    libelle: "Yémen",
  },
  {
    code: "5U",
    libelle: "Sri Lanka",
  },
  {
    code: "6C",
    libelle: "Trinité Tobago",
  },
  {
    code: "3P",
    libelle: "Estonie",
  },
  {
    code: "36",
    libelle: "Congo",
  },
  {
    code: "25",
    libelle: "Bulgarie",
  },
  {
    code: "4J",
    libelle: "Jersey",
  },
  {
    code: "44",
    libelle: "Sénégal",
  },
  {
    code: "9Z",
    libelle: "Antarctique",
  },
  {
    code: "4H",
    libelle: "Fidji",
  },
  {
    code: "35",
    libelle: "Centrafrique",
  },
  {
    code: "3R",
    libelle: "Iles Féroé",
  },
  {
    code: "80",
    libelle: "Cambodge",
  },
  {
    code: "40",
    libelle: "Libye",
  },
  {
    code: "3E",
    libelle: "Ukraine",
  },
  {
    code: "1R",
    libelle: "Micronésie",
  },
  {
    code: "2B",
    libelle: "Slovaquie",
  },
  {
    code: "89",
    libelle: "Thaïlande",
  },
  {
    code: "2A",
    libelle: "République Tchèque",
  },
  {
    code: "03",
    libelle: "Allemagne (Frontalier)",
  },
  {
    code: "3Q",
    libelle: "Ethiopie",
  },
  {
    code: "3U",
    libelle: "Ghana",
  },
  {
    code: "82",
    libelle: "Inde",
  },
  {
    code: "28",
    libelle: "Roumanie",
  },
  {
    code: "3B",
    libelle: "Guinée-Bissau",
  },
  {
    code: "93",
    libelle: "Islande",
  },
  {
    code: "39",
    libelle: "Gabon",
  },
  {
    code: "61",
    libelle: "Etats-Unis",
  },
  {
    code: "33",
    libelle: "Algérie",
  },
  {
    code: "5A",
    libelle: "Pakistan",
  },
  {
    code: "81",
    libelle: "Chine",
  },
  {
    code: "5B",
    libelle: "Panama",
  },
  {
    code: "10",
    libelle: "Royaume-Uni (Frontalier)",
  },
  {
    code: "55",
    libelle: "Niger",
  },
  {
    code: "1U",
    libelle: "Pitcairn",
  },
  {
    code: "2C",
    libelle: "Croatie",
  },
  {
    code: "6D",
    libelle: "Turkménistan",
  },
  {
    code: "3W",
    libelle: "Grenade",
  },
  {
    code: "19",
    libelle: "Autriche",
  },
  {
    code: "16",
    libelle: "Luxembourg",
  },
  {
    code: "4M",
    libelle: "Kenya",
  },
  {
    code: "06",
    libelle: "Danemark",
  },
  {
    code: "27",
    libelle: "Pologne",
  },
  {
    code: "30",
    libelle: "Monténégro Serbie",
  },
  {
    code: "15",
    libelle: "Italie (Frontalier)",
  },
  {
    code: "60",
    libelle: "Canada",
  },
  {
    code: "47",
    libelle: "Rép. Démocratique du Congo",
  },
  {
    code: "4O",
    libelle: "Koweït",
  },
  {
    code: "4I",
    libelle: "Jamaïque",
  },
  {
    code: "5I",
    libelle: "République Dominicaine",
  },
  {
    code: "54",
    libelle: "Nicaragua",
  },
  {
    code: "4N",
    libelle: "Kirghizistan",
  },
  {
    code: "2O",
    libelle: "Bangladesh",
  },
  {
    code: "67",
    libelle: "Vénézuela",
  },
  {
    code: "6K",
    libelle: "Cap-Vert",
  },
  {
    code: "5Y",
    libelle: "Swaziland",
  },
  {
    code: "4P",
    libelle: "Laos",
  },
  {
    code: "2I",
    libelle: "Principauté d'Andorre",
  },
  {
    code: "72",
    libelle: "Israël",
  },
  {
    code: "1S",
    libelle: "Nauru",
  },
  {
    code: "51",
    libelle: "Mozambique",
  },
  {
    code: "88",
    libelle: "Indonésie",
  },
  {
    code: "42",
    libelle: "Maroc",
  },
  {
    code: "2K",
    libelle: "Antigua-Barbuda",
  },
  {
    code: "58",
    libelle: "Ouganda",
  },
  {
    code: "4Y",
    libelle: "Moldavie",
  },
  {
    code: "6B",
    libelle: "Togo",
  },
  {
    code: "4U",
    libelle: "Malawi",
  },
  {
    code: "04",
    libelle: "Belgique",
  },
  {
    code: "3M",
    libelle: "Emirats Arabes Unis",
  },
  {
    code: "4R",
    libelle: "Libéria",
  },
  {
    code: "6O",
    libelle: "Mongolie",
  },
  {
    code: "3A",
    libelle: "Lituanie",
  },
  {
    code: "2R",
    libelle: "Bénin",
  },
  {
    code: "2D",
    libelle: "Slovénie",
  },
  {
    code: "1Q",
    libelle: "Marshall",
  },
  {
    code: "4A",
    libelle: "Nigéria",
  },
  {
    code: "3V",
    libelle: "Gibraltar",
  },
  {
    code: "41",
    libelle: "Mali",
  },
  {
    code: "21",
    libelle: "Norvège",
  },
  {
    code: "5D",
    libelle: "Paraguay",
  },
  {
    code: "2F",
    libelle: "Macédoine",
  },
  {
    code: "6N",
    libelle: "Guinée Equatoriale",
  },
  {
    code: "4D",
    libelle: "Haïti",
  },
  {
    code: "17",
    libelle: "Luxembourg (Frontalier)",
  },
  {
    code: "3I",
    libelle: "Costa Rica",
  },
  {
    code: "2M",
    libelle: "Bahamas",
  },
  {
    code: "5W",
    libelle: "Surinam",
  },
  {
    code: "1V",
    libelle: "Salomon",
  },
  {
    code: "5O",
    libelle: "Samoa Occident.",
  },
  {
    code: "3N",
    libelle: "Equateur",
  },
  {
    code: "1J",
    libelle: "Malouines",
  },
  {
    code: "4W",
    libelle: "Malte",
  },
  {
    code: "43",
    libelle: "Mauritanie",
  },
  {
    code: "1T",
    libelle: "Palau",
  },
  {
    code: "1G",
    libelle: "Iles Turques et Caïques",
  },
  {
    code: "87",
    libelle: "Taïwan",
  },
  {
    code: "3D",
    libelle: "Russie",
  },
  {
    code: "4T",
    libelle: "Malaisie",
  },
  {
    code: "1W",
    libelle: "Tonga",
  },
  {
    code: "1C",
    libelle: "Anguilla",
  },
  {
    code: "5E",
    libelle: "Pérou",
  },
  {
    code: "2U",
    libelle: "Myanmar (Birmanie)",
  },
  {
    code: "4Z",
    libelle: "Principauté de Monaco",
  },
  {
    code: "1H",
    libelle: "Iles Vierges Britann.",
  },
  {
    code: "5Z",
    libelle: "Tadjikistan",
  },
  {
    code: "2X",
    libelle: "Brunei Darussalam",
  },
  {
    code: "52",
    libelle: "Namibie",
  },
  {
    code: "3Y",
    libelle: "Guernesey",
  },
  {
    code: "14",
    libelle: "Italie",
  },
  {
    code: "6P",
    libelle: "Tanzanie",
  },
  {
    code: "6H",
    libelle: "Zambie",
  },
  {
    code: "6L",
    libelle: "Chypre",
  },
  {
    code: "71",
    libelle: "Iran",
  },
  {
    code: "3X",
    libelle: "Guatemala",
  },
  {
    code: "3F",
    libelle: "Comores",
  },
  {
    code: "26",
    libelle: "Hongrie",
  },
  {
    code: "2H",
    libelle: "Arménie",
  },
  {
    code: "63",
    libelle: "Argentine",
  },
  {
    code: "18",
    libelle: "Portugal",
  },
  {
    code: "6M",
    libelle: "Guinée",
  },
  {
    code: "74",
    libelle: "Irak",
  },
  {
    code: "84",
    libelle: "Viêt-Nam",
  },
  {
    code: "2Z",
    libelle: "Burundi",
  },
  {
    code: "5F",
    libelle: "Philippines",
  },
  {
    code: "62",
    libelle: "Mexique",
  },
  {
    code: "1E",
    libelle: "Iles Cayman",
  },
  {
    code: "75",
    libelle: "Syrie",
  },
  {
    code: "5V",
    libelle: "Saint-Marin",
  },
  {
    code: "6F",
    libelle: "Vanuatu",
  },
  {
    code: "56",
    libelle: "Nouvelle-Zélande",
  },
  {
    code: "4X",
    libelle: "Ile de Man",
  },
  {
    code: "1M",
    libelle: "Christmas, Cocos et Norfolk",
  },
  {
    code: "12",
    libelle: "Pays-Bas",
  },
  {
    code: "5Q",
    libelle: "Seychelles",
  },
  {
    code: "92",
    libelle: "Liechtenstein",
  },
  {
    code: "5T",
    libelle: "Soudan",
  },
  {
    code: "2L",
    libelle: "Azerbaïdjan",
  },
  {
    code: "2E",
    libelle: "Bosnie-Herzégovine",
  },
  {
    code: "6J",
    libelle: "Afghanistan",
  },
  {
    code: "2Q",
    libelle: "Bélize",
  },
  {
    code: "2J",
    libelle: "Angola",
  },
  {
    code: "1L",
    libelle: "Sainte-Hélène",
  },
  {
    code: "2P",
    libelle: "Barbade",
  },
  {
    code: "46",
    libelle: "Tunisie",
  },
  {
    code: "08",
    libelle: "Espagne (Frontalier)",
  },
  {
    code: "02",
    libelle: "Allemagne",
  },
  {
    code: "20",
    libelle: "Finlande",
  },
  {
    code: "2W",
    libelle: "Botswana",
  },
  {
    code: "5G",
    libelle: "Porto Rico",
  },
  {
    code: "4K",
    libelle: "Jordanie",
  },
  {
    code: "5N",
    libelle: "Salvador",
  },
  {
    code: "64",
    libelle: "Brésil",
  },
  {
    code: "4V",
    libelle: "Maldives",
  },
  {
    code: "3O",
    libelle: "Erythrée",
  },
  {
    code: "73",
    libelle: "Liban",
  },
  {
    code: "3T",
    libelle: "Géorgie",
  },
  {
    code: "3C",
    libelle: "Lettonie",
  },
  {
    code: "76",
    libelle: "Turquie",
  },
  {
    code: "5K",
    libelle: "St-Christophe, Niévès",
  },
  {
    code: "2T",
    libelle: "Biélorussie",
  },
  {
    code: "2Y",
    libelle: "Burkina-Faso",
  },
  {
    code: "22",
    libelle: "Suède",
  },
  {
    code: "5M",
    libelle: "Sainte-Lucie",
  },
  {
    code: "4E",
    libelle: "Honduras",
  },
  {
    code: "1D",
    libelle: "Bermudes",
  },
  {
    code: "2V",
    libelle: "Bolivie",
  },
  {
    code: "1P",
    libelle: "Kiribati",
  },
  {
    code: "2N",
    libelle: "Bahreïn",
  },
  {
    code: "2S",
    libelle: "Bhoutan",
  },
  {
    code: "01",
    libelle: "France",
  },
  {
    code: "90",
    libelle: "Australie",
  },
  {
    code: "53",
    libelle: "Népal",
  },
  {
    code: "3H",
    libelle: "Corée du Nord",
  },
  {
    code: "07",
    libelle: "Espagne",
  },
  {
    code: "5P",
    libelle: "São Tomé et Principe",
  },
  {
    code: "83",
    libelle: "Japon",
  },
]; */

export const areas = [
  {
    code: "01",
    libelle: "Guadeloupe",
  },
  {
    code: "02",
    libelle: "Martinique",
  },
  {
    code: "03",
    libelle: "Guyane",
  },
  {
    code: "04",
    libelle: "La Réunion",
  },
  {
    code: "06",
    libelle: "Mayotte",
  },
  {
    code: "11",
    libelle: "Ile-de-France",
  },
  {
    code: "24",
    libelle: "Centre-Val de Loire",
  },
  {
    code: "27",
    libelle: "Bourgogne-Franche-Comté",
  },
  {
    code: "28",
    libelle: "Normandie",
  },
  {
    code: "32",
    libelle: "Hauts-de-France",
  },
  {
    code: "44",
    libelle: "Grand Est",
  },
  {
    code: "52",
    libelle: "Pays de la Loire",
  },
  {
    code: "53",
    libelle: "Bretagne",
  },
  {
    code: "75",
    libelle: "Nouvelle-Aquitaine",
  },
  {
    code: "76",
    libelle: "Occitanie",
  },
  {
    code: "84",
    libelle: "Auvergne-Rhône-Alpes",
  },
  {
    code: "93",
    libelle: "Provence-Alpes-Côte d'Azur",
  },
  {
    code: "94",
    libelle: "Corse",
  },
];

export const departments = [
  {
    code: "01",
    libelle: "Ain",
    region: {
      code: "84",
      libelle: "Auvergne-Rhône-Alpes",
    },
  },
  {
    code: "02",
    libelle: "Aisne",
    region: {
      code: "32",
      libelle: "Hauts-de-France",
    },
  },
  {
    code: "03",
    libelle: "Allier",
    region: {
      code: "84",
      libelle: "Auvergne-Rhône-Alpes",
    },
  },
  {
    code: "04",
    libelle: "Alpes-de-Haute-Provence",
    region: {
      code: "93",
      libelle: "Provence-Alpes-Côte d'Azur",
    },
  },
  {
    code: "05",
    libelle: "Hautes-Alpes",
    region: {
      code: "93",
      libelle: "Provence-Alpes-Côte d'Azur",
    },
  },
  {
    code: "06",
    libelle: "Alpes-Maritimes",
    region: {
      code: "93",
      libelle: "Provence-Alpes-Côte d'Azur",
    },
  },
  {
    code: "07",
    libelle: "Ardèche",
    region: {
      code: "84",
      libelle: "Auvergne-Rhône-Alpes",
    },
  },
  {
    code: "08",
    libelle: "Ardennes",
    region: {
      code: "44",
      libelle: "Grand Est",
    },
  },
  {
    code: "09",
    libelle: "Ariège",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "10",
    libelle: "Aube",
    region: {
      code: "44",
      libelle: "Grand Est",
    },
  },
  {
    code: "11",
    libelle: "Aude",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "12",
    libelle: "Aveyron",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "13",
    libelle: "Bouches-du-Rhône",
    region: {
      code: "93",
      libelle: "Provence-Alpes-Côte d'Azur",
    },
  },
  {
    code: "14",
    libelle: "Calvados",
    region: {
      code: "28",
      libelle: "Normandie",
    },
  },
  {
    code: "15",
    libelle: "Cantal",
    region: {
      code: "84",
      libelle: "Auvergne-Rhône-Alpes",
    },
  },
  {
    code: "16",
    libelle: "Charente",
    region: {
      code: "75",
      libelle: "Nouvelle-Aquitaine",
    },
  },
  {
    code: "17",
    libelle: "Charente-Maritime",
    region: {
      code: "75",
      libelle: "Nouvelle-Aquitaine",
    },
  },
  {
    code: "18",
    libelle: "Cher",
    region: {
      code: "24",
      libelle: "Centre-Val de Loire",
    },
  },
  {
    code: "19",
    libelle: "Corrèze",
    region: {
      code: "75",
      libelle: "Nouvelle-Aquitaine",
    },
  },
  {
    code: "2A",
    libelle: "Corse-du-Sud",
    region: {
      code: "94",
      libelle: "Corse",
    },
  },
  {
    code: "2B",
    libelle: "Haute-Corse",
    region: {
      code: "94",
      libelle: "Corse",
    },
  },
  {
    code: "21",
    libelle: "Côte-d'Or",
    region: {
      code: "27",
      libelle: "Bourgogne-Franche-Comté",
    },
  },
  {
    code: "22",
    libelle: "Côtes-d'Armor",
    region: {
      code: "53",
      libelle: "Bretagne",
    },
  },
  {
    code: "23",
    libelle: "Creuse",
    region: {
      code: "75",
      libelle: "Nouvelle-Aquitaine",
    },
  },
  {
    code: "24",
    libelle: "Dordogne",
    region: {
      code: "75",
      libelle: "Nouvelle-Aquitaine",
    },
  },
  {
    code: "25",
    libelle: "Doubs",
    region: {
      code: "27",
      libelle: "Bourgogne-Franche-Comté",
    },
  },
  {
    code: "26",
    libelle: "Drôme",
    region: {
      code: "84",
      libelle: "Auvergne-Rhône-Alpes",
    },
  },
  {
    code: "27",
    libelle: "Eure",
    region: {
      code: "28",
      libelle: "Normandie",
    },
  },
  {
    code: "28",
    libelle: "Eure-et-Loir",
    region: {
      code: "24",
      libelle: "Centre-Val de Loire",
    },
  },
  {
    code: "29",
    libelle: "Finistère",
    region: {
      code: "53",
      libelle: "Bretagne",
    },
  },
  {
    code: "30",
    libelle: "Gard",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "31",
    libelle: "Haute-Garonne",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "32",
    libelle: "Gers",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "33",
    libelle: "Gironde",
    region: {
      code: "75",
      libelle: "Nouvelle-Aquitaine",
    },
  },
  {
    code: "34",
    libelle: "Hérault",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "35",
    libelle: "Ille-et-Vilaine",
    region: {
      code: "53",
      libelle: "Bretagne",
    },
  },
  {
    code: "36",
    libelle: "Indre",
    region: {
      code: "24",
      libelle: "Centre-Val de Loire",
    },
  },
  {
    code: "37",
    libelle: "Indre-et-Loire",
    region: {
      code: "24",
      libelle: "Centre-Val de Loire",
    },
  },
  {
    code: "38",
    libelle: "Isère",
    region: {
      code: "84",
      libelle: "Auvergne-Rhône-Alpes",
    },
  },
  {
    code: "39",
    libelle: "Jura",
    region: {
      code: "27",
      libelle: "Bourgogne-Franche-Comté",
    },
  },
  {
    code: "40",
    libelle: "Landes",
    region: {
      code: "75",
      libelle: "Nouvelle-Aquitaine",
    },
  },
  {
    code: "41",
    libelle: "Loir-et-Cher",
    region: {
      code: "24",
      libelle: "Centre-Val de Loire",
    },
  },
  {
    code: "42",
    libelle: "Loire",
    region: {
      code: "84",
      libelle: "Auvergne-Rhône-Alpes",
    },
  },
  {
    code: "43",
    libelle: "Haute-Loire",
    region: {
      code: "84",
      libelle: "Auvergne-Rhône-Alpes",
    },
  },
  {
    code: "44",
    libelle: "Loire-Atlantique",
    region: {
      code: "52",
      libelle: "Pays de la Loire",
    },
  },
  {
    code: "45",
    libelle: "Loiret",
    region: {
      code: "24",
      libelle: "Centre-Val de Loire",
    },
  },
  {
    code: "46",
    libelle: "Lot",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "47",
    libelle: "Lot-et-Garonne",
    region: {
      code: "75",
      libelle: "Nouvelle-Aquitaine",
    },
  },
  {
    code: "48",
    libelle: "Lozère",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "49",
    libelle: "Maine-et-Loire",
    region: {
      code: "52",
      libelle: "Pays de la Loire",
    },
  },
  {
    code: "50",
    libelle: "Manche",
    region: {
      code: "28",
      libelle: "Normandie",
    },
  },
  {
    code: "51",
    libelle: "Marne",
    region: {
      code: "44",
      libelle: "Grand Est",
    },
  },
  {
    code: "52",
    libelle: "Haute-Marne",
    region: {
      code: "44",
      libelle: "Grand Est",
    },
  },
  {
    code: "53",
    libelle: "Mayenne",
    region: {
      code: "52",
      libelle: "Pays de la Loire",
    },
  },
  {
    code: "54",
    libelle: "Meurthe-et-Moselle",
    region: {
      code: "44",
      libelle: "Grand Est",
    },
  },
  {
    code: "55",
    libelle: "Meuse",
    region: {
      code: "44",
      libelle: "Grand Est",
    },
  },
  {
    code: "56",
    libelle: "Morbihan",
    region: {
      code: "53",
      libelle: "Bretagne",
    },
  },
  {
    code: "57",
    libelle: "Moselle",
    region: {
      code: "44",
      libelle: "Grand Est",
    },
  },
  {
    code: "58",
    libelle: "Nièvre",
    region: {
      code: "27",
      libelle: "Bourgogne-Franche-Comté",
    },
  },
  {
    code: "59",
    libelle: "Nord",
    region: {
      code: "32",
      libelle: "Hauts-de-France",
    },
  },
  {
    code: "60",
    libelle: "Oise",
    region: {
      code: "32",
      libelle: "Hauts-de-France",
    },
  },
  {
    code: "61",
    libelle: "Orne",
    region: {
      code: "28",
      libelle: "Normandie",
    },
  },
  {
    code: "62",
    libelle: "Pas-de-Calais",
    region: {
      code: "32",
      libelle: "Hauts-de-France",
    },
  },
  {
    code: "63",
    libelle: "Puy-de-Dôme",
    region: {
      code: "84",
      libelle: "Auvergne-Rhône-Alpes",
    },
  },
  {
    code: "64",
    libelle: "Pyrénées-Atlantiques",
    region: {
      code: "75",
      libelle: "Nouvelle-Aquitaine",
    },
  },
  {
    code: "65",
    libelle: "Hautes-Pyrénées",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "66",
    libelle: "Pyrénées-Orientales",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "67",
    libelle: "Bas-Rhin",
    region: {
      code: "44",
      libelle: "Grand Est",
    },
  },
  {
    code: "68",
    libelle: "Haut-Rhin",
    region: {
      code: "44",
      libelle: "Grand Est",
    },
  },
  {
    code: "69",
    libelle: "Rhône",
    region: {
      code: "84",
      libelle: "Auvergne-Rhône-Alpes",
    },
  },
  {
    code: "70",
    libelle: "Haute-Saône",
    region: {
      code: "27",
      libelle: "Bourgogne-Franche-Comté",
    },
  },
  {
    code: "71",
    libelle: "Saône-et-Loire",
    region: {
      code: "27",
      libelle: "Bourgogne-Franche-Comté",
    },
  },
  {
    code: "72",
    libelle: "Sarthe",
    region: {
      code: "52",
      libelle: "Pays de la Loire",
    },
  },
  {
    code: "73",
    libelle: "Savoie",
    region: {
      code: "84",
      libelle: "Auvergne-Rhône-Alpes",
    },
  },
  {
    code: "74",
    libelle: "Haute-Savoie",
    region: {
      code: "84",
      libelle: "Auvergne-Rhône-Alpes",
    },
  },
  {
    code: "75",
    libelle: "Paris",
    region: {
      code: "11",
      libelle: "Île-de-France",
    },
  },
  {
    code: "76",
    libelle: "Seine-Maritime",
    region: {
      code: "28",
      libelle: "Normandie",
    },
  },
  {
    code: "77",
    libelle: "Seine-et-Marne",
    region: {
      code: "11",
      libelle: "Île-de-France",
    },
  },
  {
    code: "78",
    libelle: "Yvelines",
    region: {
      code: "11",
      libelle: "Île-de-France",
    },
  },
  {
    code: "79",
    libelle: "Deux-Sèvres",
    region: {
      code: "75",
      libelle: "Nouvelle-Aquitaine",
    },
  },
  {
    code: "80",
    libelle: "Somme",
    region: {
      code: "32",
      libelle: "Hauts-de-France",
    },
  },
  {
    code: "81",
    libelle: "Tarn",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "82",
    libelle: "Tarn-et-Garonne",
    region: {
      code: "76",
      libelle: "Occitanie",
    },
  },
  {
    code: "83",
    libelle: "Var",
    region: {
      code: "93",
      libelle: "Provence-Alpes-Côte d'Azur",
    },
  },
  {
    code: "84",
    libelle: "Vaucluse",
    region: {
      code: "93",
      libelle: "Provence-Alpes-Côte d'Azur",
    },
  },
  {
    code: "85",
    libelle: "Vendée",
    region: {
      code: "52",
      libelle: "Pays de la Loire",
    },
  },
  {
    code: "86",
    libelle: "Vienne",
    region: {
      code: "75",
      libelle: "Nouvelle-Aquitaine",
    },
  },
  {
    code: "87",
    libelle: "Haute-Vienne",
    region: {
      code: "75",
      libelle: "Nouvelle-Aquitaine",
    },
  },
  {
    code: "88",
    libelle: "Vosges",
    region: {
      code: "44",
      libelle: "Grand Est",
    },
  },
  {
    code: "89",
    libelle: "Yonne",
    region: {
      code: "27",
      libelle: "Bourgogne-Franche-Comté",
    },
  },
  {
    code: "90",
    libelle: "Territoire de Belfort",
    region: {
      code: "27",
      libelle: "Bourgogne-Franche-Comté",
    },
  },
  {
    code: "91",
    libelle: "Essonne",
    region: {
      code: "11",
      libelle: "Île-de-France",
    },
  },
  {
    code: "92",
    libelle: "Hauts-de-Seine",
    region: {
      code: "11",
      libelle: "Île-de-France",
    },
  },
  {
    code: "93",
    libelle: "Seine-Saint-Denis",
    region: {
      code: "11",
      libelle: "Île-de-France",
    },
  },
  {
    code: "94",
    libelle: "Val-de-Marne",
    region: {
      code: "11",
      libelle: "Île-de-France",
    },
  },
  {
    code: "95",
    libelle: "Val-d'Oise",
    region: {
      code: "11",
      libelle: "Île-de-France",
    },
  },
  {
    code: "971",
    libelle: "Guadeloupe",
    region: {
      code: "01",
      libelle: "Guadeloupe",
    },
  },
  {
    code: "972",
    libelle: "Martinique",
    region: {
      code: "02",
      libelle: "Martinique",
    },
  },
  {
    code: "973",
    libelle: "Guyane",
    region: {
      code: "03",
      libelle: "Guyane",
    },
  },
  {
    code: "974",
    libelle: "La Réunion",
    region: {
      code: "04",
      libelle: "La Réunion",
    },
  },
  {
    code: "976",
    libelle: "Mayotte",
    region: {
      code: "06",
      libelle: "Mayotte",
    },
  },
];
