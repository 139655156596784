import { FC, useState } from "react";
import i18n from "../../i18n";
import { getSupportedLanguageCode } from "../../utils/getLanguageCode";
import { CustomSelect } from "../ui/inputs";
import { CustomSelectOption } from "../ui/inputs/CustomSelect";

const locales: Record<string, string> = {
  en: "EN",
  fr: "FR",
};

/* const locales: CustomSelectOption[] = [
  { id: "en-GB", value: "En" },
  { id: "fr-FR", value: "Fr" },
]; */

export const LocaleSwitcher: FC = () => {
  const currentLanguageCode = getSupportedLanguageCode(i18n.language);

  const [locale, setLocale] = useState<CustomSelectOption>({
    id: currentLanguageCode,
    value: locales[currentLanguageCode],
  });

  const options = Object.entries(locales).map(([id, value]) => ({ id, value }));

  const onChange = (option: CustomSelectOption) => {
    i18n.changeLanguage(option.id as string);
    setLocale(option);
  };
  return (
    <CustomSelect options={options} selected={locale} onChange={onChange} />
  );
};
