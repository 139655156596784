import { FC } from "react";
import { UseFormRegister, RegisterOptions, FieldErrors } from "react-hook-form";
import { Button } from "../../ui/buttons";
import { TextField } from "../../ui/inputs";
import { RouterButton, RouterLink } from "../../ui/links";
import { ResetPasswordFormData } from "../ResetPassword";
import { CheckIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

export const ResetPasswordPage: FC<{
  register: UseFormRegister<ResetPasswordFormData>;
  registerOptions: Record<keyof ResetPasswordFormData, RegisterOptions>;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  errors: FieldErrors<ResetPasswordFormData>;
  isSubmitSuccessful: boolean;
}> = ({ register, registerOptions, onSubmit, errors, isSubmitSuccessful }) => {
  const { t } = useTranslation("authentification");

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src="logo512.png" alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {t("resetPasswordPage.title")}
        </h2>
        {!isSubmitSuccessful && (
          <p className="mt-2 text-center text-sm text-gray-600">
            {t("resetPasswordPage.notForgot.text")}{" "}
            <RouterLink
              to="/signin"
              text={t("resetPasswordPage.notForgot.action")}
            />
          </p>
        )}
      </div>
      {!isSubmitSuccessful ? (
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={onSubmit} noValidate>
              <TextField
                register={register}
                registerOptions={registerOptions.email}
                error={errors.email?.message}
                label={t("formLabel.email")}
                name="email"
                type="email"
                autocomplete="email"
              />

              <div>
                <Button
                  type="submit"
                  children={t("formSubmit.resetPassword")}
                  size="sm"
                  fullwidth
                />
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="sm:mx-auto sm:w-full sm:max-w-md align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <CheckIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {t("resetPasswordPage.success.title")}
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t("resetPasswordPage.success.message")}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <RouterButton
              to="/signin"
              text={t("resetPasswordPage.success.action")}
              fullwidth
              size="sm"
            />
          </div>
        </div>
      )}
    </div>
  );
};
