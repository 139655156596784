import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch, useHistory } from "react-router";
import { useUpdateWorkflow } from "..";
import { useOverlay } from "../../overlay";
import { Alert } from "../../ui/alerts";
import { Button } from "../../ui/buttons";
import { Spinner } from "../../ui/icons";

export const StepWorkflowReady: FC<{
  isLoadingWorkflow: boolean;
  workflow?: Shared.IWorkflow<Shared.JobboardParams>;
}> = ({ isLoadingWorkflow, workflow }) => {
  // Routing
  let {
    params: { workflowId, organisationId },
    url,
  } = useRouteMatch<{ workflowId: string; organisationId: string }>();
  const history = useHistory();

  // UI
  const { t } = useTranslation(["common", "workflow"]);
  const { pushNotification } = useOverlay();

  // Worklfow
  const { isLoading: isLoadingUpdateWorkflow, update } =
    useUpdateWorkflow(organisationId)();
  const workflowsListUrl = url.substring(0, url.lastIndexOf("/"));
  const canBeEnabled =
    workflow?.params?.job &&
    workflow?.params?.job.status === "complete" &&
    workflow?.params?.target &&
    workflow?.params?.target.status === "complete" &&
    workflow?.params?.output &&
    workflow?.params?.output.status === "complete" &&
    workflow?.params?.settings &&
    workflow?.params?.settings.status === "complete";
  const isReady = workflow?.enabled !== undefined;

  const handleEnableWorkflow = (enabled: boolean) => {
    if (canBeEnabled) {
      update(workflowId, {
        enabled: enabled,
      }).then(() => history.push(workflowsListUrl));
    } else {
      pushNotification({
        severity: "warning",
        title: t(
          "workflow:addJobboardWorkflowPage.readyStep.cannotBeEnabled.title"
        ),
        body: t(
          "workflow:addJobboardWorkflowPage.readyStep.cannotBeEnabled.body"
        ),
      });
    }
  };

  const handleBackToWorkflowList = () => history.push(workflowsListUrl);

  const handleBackToEdit = () => history.push(url);

  return (
    <section aria-labelledby="job-details">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 sm:p-6">
          <div>
            <h2
              id="payment-details-heading"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              {t("workflow:addJobboardWorkflowPage.readyStep.title")}
            </h2>
            <p className="max-w-2xl text-sm text-gray-500">
              {t("workflow:addJobboardWorkflowPage.readyStep.description")}
            </p>
          </div>
          {isLoadingWorkflow ? (
            <div className="my-10 place-content-center">
              <Spinner size={20} />
            </div>
          ) : (
            <div className="my-12 grid grid-flow-col grid-col-2 grid-rows-2 gap-6">
              <div className="col-span-2 text-center px-12">
                {isReady ? (
                  <Alert
                    variant="Success"
                    title={t(
                      "workflow:addJobboardWorkflowPage.readyStep.isReady.title"
                    )}
                    body={t(
                      "workflow:addJobboardWorkflowPage.readyStep.isReady.body"
                    )}
                  />
                ) : canBeEnabled ? (
                  <Alert
                    variant="Info"
                    title={t(
                      "workflow:addJobboardWorkflowPage.readyStep.canBeEnabled.title"
                    )}
                    body={t(
                      "workflow:addJobboardWorkflowPage.readyStep.canBeEnabled.body"
                    )}
                  />
                ) : (
                  <Alert
                    variant="Warning"
                    title={t(
                      "workflow:addJobboardWorkflowPage.readyStep.cannotBeEnabled.title"
                    )}
                    body={t(
                      "workflow:addJobboardWorkflowPage.readyStep.cannotBeEnabled.body"
                    )}
                  />
                )}
              </div>
              {isReady ? (
                <div className="flex justify-center align-middle col-span-2">
                  <Button
                    type="button"
                    onClick={handleBackToWorkflowList}
                    variant="white"
                    children={t(
                      "workflow:addJobboardWorkflowPage.readyStep.backToWorkflow"
                    )}
                    isLoading={isLoadingWorkflow || isLoadingUpdateWorkflow}
                    disbaled={!canBeEnabled}
                  />
                </div>
              ) : (
                <>
                  <div className="flex justify-center align-middle">
                    <Button
                      type="button"
                      onClick={() => handleEnableWorkflow(false)}
                      variant="white"
                      children={t(
                        "workflow:addJobboardWorkflowPage.readyStep.disabledWorkflow"
                      )}
                      isLoading={isLoadingWorkflow || isLoadingUpdateWorkflow}
                      disbaled={!canBeEnabled}
                    />
                  </div>
                  <div className="flex justify-center align-middle">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => handleEnableWorkflow(true)}
                      children={t(
                        "workflow:addJobboardWorkflowPage.readyStep.enabledWorkflow"
                      )}
                      isLoading={isLoadingWorkflow || isLoadingUpdateWorkflow}
                      disbaled={!canBeEnabled}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <Button
            type="button"
            size="sm"
            onClick={handleBackToEdit}
            children={t("common:action.back")}
            isLoading={isLoadingWorkflow || isLoadingUpdateWorkflow}
          />
        </div>
      </div>
    </section>
  );
};
