import { FirestoreService } from "../firebase";
import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "@firebase/firestore";
import { createServiceHooks } from "../firebase/firestoreService";

export default class UserClaimsService extends FirestoreService<Shared.IUserClaims> {
  constructor(public path?: string) {
    super("userclaims", path);
  }

  getModelConverter() {
    const serverTimestamp = this.serverTimestamp;
    return {
      toFirestore(data: Shared.IUserClaims): DocumentData {
        return {
          createdAt: serverTimestamp,
          claims: data.claims,
          status: data.status,
          error: data.error,
        };
      },
      fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
      ): Shared.IUserClaims {
        const data = snapshot.data(options);
        return {
          id: snapshot.id,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          claims: data.claims,
          status: data.status,
          error: data.error,
        };
      },
    };
  }
}

export const {
  snapshot: useUserClaimsSnapshot,
  snapshotDocument: useUserClaimSnapshot,
  get: useGetUserClaim,
  list: useListUserClaims,
  add: useAddUserClaim,
  update: useUpdateUserClaim,
  softDelete: useSoftDeleteUserClaim,
} = createServiceHooks(new UserClaimsService());
