import React, { useState, useEffect, FC } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import "../firebase";
import {
  useAuth,
  SignUp,
  SignIn,
  ResetPassword,
  AuthState,
} from "../authentification";
import {
  HomeIcon,
  OfficeBuildingIcon,
  /* CalendarIcon, */
  BeakerIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";
import { Register, UserSettings } from "../user";
import { SplashPage } from "../ui/page";
import { AppLayout } from "../ui/layout";
import { Dashboard } from "../dashboard";
import { Leads } from "../lead";
import { Events } from "../event";
import { Workflows } from "../workflow/";
import { Integrations } from "../integration";
import { useOverlay } from "../overlay";
import { NotFoundPage } from "../ui/page/NotFoundPage";
import {
  ChangeOrganisationModalContent,
  OrganisationSettings,
} from "../organisation";
import { Invitation } from "../invitation/Invitation";
import { useCurrentUserPendingAndAcceptedInvitations } from "../invitation";
import { Subscription } from "../subscription";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation(["common", "authentification"]);

  const { showModal, closeModal } = useOverlay();

  const { authState, changeCurrentOrganisation, signOut } = useAuth();

  const [splashMessage, setSplashMessage] = useState("");

  useEffect(() => {
    setTimeout(
      () =>
        setSplashMessage(
          "Looks like internet is slow, please bear with us ..."
        ),
      5000
    );
  });

  if (authState.status === null) {
    return <SplashPage message={splashMessage} />;
  } else if (
    authState.status === "AUTHENTICATED" &&
    authState.user &&
    authState.user.uid
  ) {
    const currentOrganisationPath = `/organisations/${authState.currentOrganisationId}`;

    const navigation = [
      {
        name: t("common:appNavigation.dashboard"),
        path: `${currentOrganisationPath}/dashboard`,
        icon: HomeIcon,
        current: true,
      },
      {
        name: t("common:appNavigation.leads"),
        path: `${currentOrganisationPath}/leads`,
        icon: OfficeBuildingIcon,
        current: false,
      },
      /* {
        name: "Events",
        path: `${currentOrganisationPath}/events`,
        icon: CalendarIcon,
        current: false,
      }, */
      {
        name: t("common:appNavigation.workflows"),
        path: `${currentOrganisationPath}/workflows`,
        icon: BeakerIcon,
        current: false,
      },
      {
        name: t("common:appNavigation.integrations"),
        path: `${currentOrganisationPath}/integrations`,
        icon: ViewGridAddIcon,
        current: false,
      },
    ];
    const userNavigation: {
      name: string;
      path?: string;
      onClick?: () => void;
    }[] = [
      {
        name: t("common:userNavigation.profil"),
        path: `/userprofile/${authState.user?.uid}`,
      },
      {
        name: t("common:userNavigation.organisationSettings"),
        path: `${currentOrganisationPath}/settings`,
      },
      { name: "Sign out", onClick: signOut },
    ];

    if (
      authState.organisations &&
      Object.keys(authState.organisations).length > 1
    ) {
      const userOrganisations = authState.organisations;
      userNavigation.splice(2, 0, {
        name: "Change organisation",
        onClick: () =>
          showModal({
            title: "Select organisation",
            content: (
              <ChangeOrganisationModalContent
                organisations={Object.entries(userOrganisations).map(
                  ([id, { name, role }]) => ({ id, name, role })
                )}
                changeCurrentOrganisation={changeCurrentOrganisation}
                closeModal={closeModal}
              />
            ),
          }),
      });
    }

    return (
      <Router>
        <Switch>
          <Route path="/organisations/:organisationId">
            <AppLayout
              sidebar={{
                navigation: navigation,
                userNavigation: userNavigation,
                subscriptionPath: `${currentOrganisationPath}/subscription`,
                organisationName:
                  authState.organisations && authState.currentOrganisationId
                    ? authState.organisations[authState.currentOrganisationId]
                        .name
                    : "",
                displayName: authState.user?.displayName,
                photoUrl: authState.user?.photoURL,
              }}
            >
              <AppRouter />
            </AppLayout>
          </Route>
          <Route path="/userprofile/:userId">
            <AppLayout
              sidebar={{
                navigation: navigation,
                userNavigation: userNavigation,
                subscriptionPath: `${currentOrganisationPath}/subscription`,
                organisationName:
                  authState.organisations && authState.currentOrganisationId
                    ? authState.organisations[authState.currentOrganisationId]
                        .name
                    : "",
                displayName: authState.user?.displayName,
                photoUrl: authState.user?.photoURL,
              }}
            >
              <UserSettings />
            </AppLayout>
          </Route>
          <Route path="/register">
            <Register userId={authState.user.uid} />
          </Route>
          <Route path="/invitations">
            <Invitation />
          </Route>
          <Route path="*">
            <RootPath authState={authState} />
          </Route>
        </Switch>
      </Router>
    );
  } else {
    return (
      <Router>
        <Switch>
          <Route path="/signup" component={SignUp} />
          <Route path="/signin" component={SignIn} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="*">
            <Redirect to="/signin" />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;

const RootPath: FC<{ authState: AuthState }> = ({ authState }) => {
  const { isLoadingPendingInvitation, pendingInvitations } =
    useCurrentUserPendingAndAcceptedInvitations();

  if (authState.favOrganisationId) {
    // At least one organisation is set on the user custom claims
    return (
      <Redirect
        to={`/organisations/${authState.favOrganisationId}/dashboard`}
      />
    );
  } else if (isLoadingPendingInvitation) {
    // Wait for any invitation pending for a new user
    return <SplashPage />;
  } else if (pendingInvitations && pendingInvitations?.length > 0) {
    // If an invitation is in ACCEPTED status, wait until it pass in completed and the userClaim update with a favOrganisationId
    if (
      pendingInvitations.filter(
        (invitation) => invitation.status === "ACCEPTED"
      ).length > 0
    ) {
      return <SplashPage message="Loading your invitation ..." />;
    } else {
      return <Redirect to="/invitations" />;
    }
  } else {
    return <Redirect to="/register" />;
  }
};

const AppRouter: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/dashboard`}>
        <Dashboard />
      </Route>
      <Route path={`${path}/leads`}>
        <Leads />
      </Route>
      <Route path={`${path}/events`}>
        <Events />
      </Route>
      <Route path={`${path}/workflows`}>
        <Workflows />
      </Route>
      <Route path={`${path}/integrations`}>
        <Integrations />
      </Route>
      <Route path={`${path}/settings`}>
        <OrganisationSettings />
      </Route>
      <Route path={`${path}/subscription`}>
        <Subscription />
      </Route>
      <Route
        path="*"
        children={<NotFoundPage backToPath={`${path}/dashboard`} />}
      />
    </Switch>
  );
};
