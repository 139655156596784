/**
 * Return a boolean depending on the user role and required minimu role
 * @remarks
 * Role are order this way SUPERADMIN > ADMIN > EDITOR > VIEWER
 * @param currentRole The user current role
 * @param accessibleFrom The minimym role to have for access
 * @returns True if the user has the required role
 */
export const hasAccessFrom = (
  currentRole: Shared.IUserRoles,
  accessibleFrom: Shared.IUserRoles = "ADMIN"
): boolean => {
  let hasAccess = false;

  switch (accessibleFrom) {
    case "SUPERADMIN":
      hasAccess = currentRole === "SUPERADMIN";
      break;

    case "ADMIN":
      hasAccess = ["SUPERADMIN", "ADMIN"].includes(currentRole);
      break;

    case "EDITOR":
      hasAccess = ["SUPERADMIN", "ADMIN", "EDITOR"].includes(currentRole);
      break;

    case "VIEWER":
    default:
      hasAccess = ["SUPERADMIN", "ADMIN", "EDITOR", "VIEWER"].includes(
        currentRole
      );
      break;
  }

  return hasAccess;
};

export const hasNotAccessBelow = (
  currentRole: Shared.IUserRoles,
  accessibleFrom?: Shared.IUserRoles
): boolean => !hasAccessFrom(currentRole, accessibleFrom);
