import { FC } from "react";
import { useQuery } from "../../utils";
import { PageLayout } from "../ui/layout/PageLayout";
import { Tabs, TabsProps } from "../ui/navigation";
import { LeadContacts } from "./components/LeadContacts";
import { LeadInfo } from "./components/LeadInfo";

type LeadPageProps = {
  isLoading: boolean;
  name: string;
  menu: TabsProps["menu"];
  lead?: Shared.ILead;
};

export const LeadPage: FC<LeadPageProps> = ({
  isLoading,
  name,
  menu,
  lead,
}) => {
  const query = useQuery();
  const current = query.get("current") || "info";

  const getCurrentItem = () => {
    switch (current) {
      case "contacts":
        return (
          lead && lead.contacts && <LeadContacts contacts={lead.contacts} />
        );

      case "events":
        return <p>Events</p>;

      case "info":
      default:
        return lead && <LeadInfo {...lead.companyData} />;
    }
  };
  return (
    <PageLayout
      title={name || "No name"}
      isLoading={isLoading}
      maxWidthDesktop="max-w-5xl"
    >
      <Tabs menu={menu} />
      {getCurrentItem()}
    </PageLayout>
  );
};
