import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LocaleSwitcher } from "../common";
import { Button } from "../ui/buttons";
import { Spinner, UserAvatar } from "../ui/icons";

type InvitatinoPageProps = {
  favOrganisationId?: string;
  isLoadingPendingInvitation: boolean;
  isLoading: boolean;
  pendingInvitations: Shared.IInvitation[] | undefined;
  acceptInvitation: (invitationId?: string) => void;
};

export const InvitationPage: FC<InvitatinoPageProps> = ({
  favOrganisationId,
  isLoadingPendingInvitation,
  isLoading,
  pendingInvitations,
  acceptInvitation,
}) => {
  const { t } = useTranslation(["common", "user"]);
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src="logo512.png" alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {t("user:invitationPage.title")}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-transparent py-8 px-4 sm:rounded-lg sm:px-10 sm:bg-white sm:shadow">
          {isLoadingPendingInvitation ? (
            <Spinner />
          ) : pendingInvitations && pendingInvitations.length > 0 ? (
            <ul className="-my-5 divide-y divide-gray-200">
              {pendingInvitations.map((invitation) => (
                <li key={invitation.id} className="py-4">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <UserAvatar displayName={invitation.organisationName} />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {invitation.organisationName}
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        {t("common:conjunction.asSomethingOf")}{" "}
                        {t(
                          `common:userRole.${invitation.userRole || "VIEWER"}`
                        )}
                      </p>
                    </div>
                    <div>
                      <Button
                        type="button"
                        variant="textPrimary"
                        size="sm"
                        isLoading={isLoading}
                        onClick={() => acceptInvitation(invitation.id)}
                        children={t("user:invitationPage.acceptInvitation")}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>{t("user:invitationPage.noInvitationFound")}</p>
          )}
        </div>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md mt-4 flex justify-between">
        <LocaleSwitcher />
        {favOrganisationId ? (
          <Link
            to={`/organisations/${favOrganisationId}/dashboard`}
            children={
              <Button
                type="button"
                size="sm"
                isLoading={isLoading}
                children={t("common:action.goBack")}
              />
            }
          />
        ) : (
          <Link
            to="/register"
            children={
              <Button
                type="button"
                size="sm"
                isLoading={isLoading}
                children={t("user:invitationPage.createOrganisation")}
              />
            }
          />
        )}
      </div>
    </div>
  );
};
