import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../ui/buttons";
import { Spinner } from "../../ui/icons";

export type OrganisationInvitationsListProps = {
  currentUserRole: Shared.IUserRoles;
  isLoadingPendingInvitation: boolean;
  pendingInvitations?: Shared.IInvitation[];
  handleResendInvitation: (invitationId?: string) => void;
  handleDeleteInvitation: (invitationId?: string) => void;
};

export const OrganisationInvitationsList: FC<OrganisationInvitationsListProps> =
  ({
    currentUserRole,
    isLoadingPendingInvitation,
    pendingInvitations,
    handleResendInvitation,
    handleDeleteInvitation,
  }) => {
    const { t } = useTranslation(["common", "organisation"]);

    return (
      <section aria-labelledby="user-invitations">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2
                id="payment-details-heading"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {t("organisation:invitationsList.title")}
              </h2>
              <p className="max-w-2xl text-sm text-gray-500">
                {t("organisation:invitationsList.description")}
              </p>
            </div>
            {isLoadingPendingInvitation ? (
              <div className="my-10 place-content-center">
                <Spinner size={20} />
              </div>
            ) : (
              <ul className="mt-2 sm:mt-4 divide-y divide-gray-200">
                {pendingInvitations &&
                  pendingInvitations.map((invitation, index) => (
                    <li key={index} className="py-4">
                      <div className="flex items-center justify-between space-x-4">
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {invitation.userEmail}
                          </p>
                          <p className="text-sm text-gray-500 truncate">
                            {t(
                              `common:userRole.${
                                invitation.userRole || "VIEWER"
                              }`
                            )}
                          </p>
                        </div>
                        {currentUserRole === "ADMIN" ? (
                          <div className="flex flex-row space-x-2 sm:space-x-4">
                            <Button
                              type="button"
                              variant="textRed"
                              size="sm"
                              onClick={() =>
                                handleDeleteInvitation(invitation.id)
                              }
                              children={t("common:action.delete")}
                            />
                            <Button
                              type="button"
                              variant="textPrimary"
                              size="sm"
                              onClick={() =>
                                handleResendInvitation(invitation.id)
                              }
                              children={t(
                                "organisation:invitationsList.action"
                              )}
                            />
                          </div>
                        ) : null}
                      </div>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </section>
    );
  };
