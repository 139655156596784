import { FC } from "react";
import { StarIcon } from "@heroicons/react/solid";
import { StarIcon as StarIconOutlined } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/buttons";

export type UserOrganisationsProps = {
  favOrganisationId?: string;
  organisationsList?: {
    id?: string;
    name?: string;
    userRole?: "SUPERADMIN" | "ADMIN" | "EDITOR" | "VIEWER";
  }[];
  handleOpenOrganisation: (organisationId: string) => void;
  updateFavOrganisation: (organisationId: string) => void;
};

export const UserOrganisations: FC<UserOrganisationsProps> = ({
  favOrganisationId,
  organisationsList,
  handleOpenOrganisation,
  updateFavOrganisation,
}) => {
  const { t } = useTranslation(["common", "user"]);

  return (
    <section aria-labelledby="user-details">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 sm:p-6">
          <div>
            <h2
              id="payment-details-heading"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              {t("user:UserSettingsPage.userOrganisation.title")}
            </h2>
            <p className="max-w-2xl text-sm text-gray-500">
              {t("user:UserSettingsPage.userOrganisation.description")}
            </p>
          </div>
          <dl className="divide-y divide-gray-200">
            {organisationsList &&
              organisationsList.map((organisation) => (
                <div
                  key={organisation.id}
                  className="py-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4"
                >
                  <dt className="text-sm font-medium">
                    {favOrganisationId === organisation.id ? (
                      <StarIcon className="h-9 w-9 text-primary" />
                    ) : (
                      <StarIconOutlined
                        className="h-8 w-8 text-gray-500 cursor-pointer"
                        onClick={() =>
                          updateFavOrganisation(organisation.id as string)
                        }
                      />
                    )}
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                    <span className="flex-grow my-auto">
                      {organisation.name}
                    </span>
                    <span className="ml-4 flex-shrink-0">
                      {organisation.id && (
                        <Button
                          type="button"
                          variant="textPrimary"
                          size="xs"
                          onClick={() => {
                            organisation.id &&
                              handleOpenOrganisation(organisation.id);
                          }}
                          children={t("common:action.open")}
                        />
                      )}
                    </span>
                  </dd>
                </div>
              ))}
          </dl>
        </div>
      </div>
    </section>
  );
};
