import { FC } from "react";
import { UseFormRegister, RegisterOptions } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

type TextFieldProps = {
  className?: string;
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
  error?: string;
  label: string;
  name: string;
  type: "text" | "email" | "password" | "number";
  defaultValue?: string | number;
  placeholder?: string;
  hidden?: boolean;
  autocomplete?:
    | "off"
    | "email"
    | "username"
    | "new-password"
    | "current-password"
    | "tel"
    | "text"
    | "name"
    | "organization"
    | "organization-title"
    | "street-address"
    | "url";
};

export const TextField: FC<TextFieldProps> = ({
  className,
  register,
  registerOptions,
  error,
  label,
  name,
  type,
  defaultValue,
  placeholder,
  hidden = false,
  autocomplete,
}) => (
  <div className={className}>
    <label
      htmlFor={name}
      className="block text-sm text-left font-medium text-gray-700"
    >
      {label}
      {registerOptions && registerOptions.required && " *"}
    </label>
    <div className="mt-1 relative rounded-md shadow-sm">
      <input
        id={name}
        type={type}
        onWheel={(e) => e.currentTarget.blur()}
        defaultValue={defaultValue}
        placeholder={placeholder}
        autoComplete={autocomplete}
        hidden={hidden}
        {...register(name, registerOptions)}
        className={`"appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none ${
          error
            ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
            : "border-gray-300 placeholder-gray-400 focus:ring-primary-light focus:border-primary-light"
        }  sm:text-sm"`}
      />
      {error && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
      )}
    </div>
    {error && <p className="mt-2 text-sm text-left text-red-600">{error}</p>}
  </div>
);
