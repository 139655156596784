import { FC, useEffect, useState } from "react";
import {
  UseFormRegister,
  RegisterOptions,
  FieldErrors,
  Control,
  Controller,
  UseFormReset,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../ui/buttons";
import { CustomSelect, TextField } from "../../ui/inputs";
import { Modal } from "../../ui/overlay";
import type { EditUserFormData } from "../../organisation/OrganisationSettings";
import { useParams } from "react-router";
import type { CustomSelectOption } from "../../ui/inputs";

export const EditUserModal: FC<{
  roleList: CustomSelectOption[];
  currentRole: CustomSelectOption;
  show: boolean;
  onClose: () => void;
  control: Control<EditUserFormData, object>;
  register: UseFormRegister<EditUserFormData>;
  registerOptions: Record<keyof EditUserFormData, RegisterOptions>;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  errors: FieldErrors<EditUserFormData>;
  reset: UseFormReset<EditUserFormData>;
}> = ({
  roleList,
  currentRole,
  show,
  onClose,
  control,
  register,
  registerOptions,
  onSubmit,
  errors,
  reset,
}) => {
  const { t } = useTranslation(["common", "organisation"]);
  const [selectedRole, setSelectedRole] = useState(currentRole);
  const { userId } = useParams<{ userId: string }>();

  useEffect(() => {
    reset({ userId });
  }, [userId, reset]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={t("organisation:editUserModal.title")}
      size="sm"
    >
      <form className="space-y-6 text-left" onSubmit={onSubmit} noValidate>
        <div className="hidden">
          <TextField
            register={register}
            defaultValue={userId}
            registerOptions={registerOptions.userId}
            error={errors.userId?.message}
            label=""
            hidden={true}
            name="userId"
            type="text"
          />
        </div>
        <Controller
          control={control}
          name="newRole"
          //defaultValue={currentUser?.prefferedLanguage}
          render={({ field: { onChange } }) => {
            return (
              <CustomSelect
                className="col-span3 sm:col-span-2"
                label={t("organisation:formLabel.newUserRole")}
                options={roleList}
                selected={selectedRole}
                onChange={(option) => {
                  onChange(option.id);
                  setSelectedRole(option);
                }}
              />
            );
          }}
        />
        <div className="h-12 invisible"></div>
        <div>
          <Button
            type="submit"
            children={t("organisation:editUserModal.action")}
            size="sm"
            fullwidth
          />
        </div>
      </form>
    </Modal>
  );
};
