import { FC } from "react";

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export type SideStepperProps = {
  steps: {
    name: string;
    to: string;
    status: "upcoming" | "current" | "complete" | "error";
  }[];
};

export const SideStepper: FC<SideStepperProps> = ({ steps }) => {
  return (
    <aside className="py-12 px-4 sm:px-6 lg:px-8">
      <nav className="flex justify-center" aria-label="Progress">
        <ol className="space-y-6">
          {steps.map((step) => (
            <li key={step.name}>
              {step.status === "complete" ? (
                <Link to={step.to} className="group">
                  <span className="flex items-start">
                    <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                      <CheckCircleIcon
                        className="h-full w-full text-primary group-hover:text-primary-dark"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </span>
                  </span>
                </Link>
              ) : step.status === "current" ? (
                <Link
                  to={step.to}
                  className="flex items-start"
                  aria-current="step"
                >
                  <span
                    className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                    aria-hidden="true"
                  >
                    <span className="absolute h-4 w-4 rounded-full bg-primary-light" />
                    <span className="relative block w-2 h-2 bg-primaryrounded-full" />
                  </span>
                  <span className="ml-3 text-sm font-medium text-primary">
                    {step.name}
                  </span>
                </Link>
              ) : step.status === "error" ? (
                <Link to={step.to} className="group">
                  <span className="flex items-start">
                    <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                      <XCircleIcon
                        className="h-full w-full text-red-500 group-hover:text-red-800"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </span>
                  </span>
                </Link>
              ) : (
                <Link to={step.to} className="group">
                  <div className="flex items-start">
                    <div
                      className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                      aria-hidden="true"
                    >
                      <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                    </div>
                    <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </p>
                  </div>
                </Link>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </aside>
  );
};
