import { FC, useEffect, useState } from "react";
import { functions, logAnalyticsEvent, perf } from "../firebase";
import { trace } from "@firebase/performance";
import { httpsCallable } from "firebase/functions";
import { useCurrentOrganisation } from "../organisation";
import { SubscriptionPage } from "./SubscriptionPage";
import { useRouteMatch } from "react-router";
import { useOverlay } from "../overlay";
import { appBaseUrl } from "../config";
import { useQuery } from "../../utils";
import { useAuth } from "../authentification";
import { useTranslation } from "react-i18next";

export const Subscription: FC = () => {
  const { t } = useTranslation(["common", "subscription"]);
  let { url } = useRouteMatch();
  const query = useQuery();
  const sessionCanceled = query.get("canceled") === "true" ? true : false;
  const sessionSuccess = query.get("success") === "true" ? true : false;
  const { authState } = useAuth();
  const { pushNotification } = useOverlay();
  const { currentOrganisation, isLoadingCurrentOrganisation } =
    useCurrentOrganisation();
  const [isLoadingCheckoutSession, setIsLoadingCheckoutSession] =
    useState(false);
  const [isLoadingPortalSession, setisLoadingPortalSession] = useState(false);

  const production = process.env.REACT_APP_DEPLOY_IN_PRODUCTION;

  const prices = {
    discovery: [
      {
        id: production
          ? "price_1Kah6JIx0qrEhp6H4vFDR5hP"
          : "price_1KanB9Ix0qrEhp6HwbN2GWNP",
        product: {
          name: "Discovery",
          description: `10 ${t("subscription:planSelection.leadByMonth")}`,
        },
        unit_amount: 30,
        action: t("subscription:planSelection.subscribe"),
      },
    ],
    hobby: [
      {
        id: production
          ? "price_1JrUeJIx0qrEhp6HyCbsFs1e"
          : "price_1JeltpIx0qrEhp6HPTcAYAW9",
        product: {
          name: "Hobby 25",
          description: `25 ${t("subscription:planSelection.leadByMonth")}`,
        },
        unit_amount: 125,
        action: t("subscription:planSelection.subscribe"),
      },
      {
        id: production
          ? "price_1JrUeFIx0qrEhp6H0Z9u4jLz"
          : "price_1JgmqXIx0qrEhp6HAKg5vzEq",
        product: {
          name: "Hobby 50",
          description: `50 ${t("subscription:planSelection.leadByMonth")}`,
        },
        unit_amount: 200,
        action: t("subscription:planSelection.subscribe"),
      },
    ],
    starter: [
      {
        id: production
          ? "price_1JrUe6Ix0qrEhp6HUgmEqL85"
          : "price_1Jgms7Ix0qrEhp6HNaQUSfY2",
        product: {
          name: "Starter 150",
          description: `150 ${t("subscription:planSelection.leadByMonth")}`,
        },
        unit_amount: 450,
        action: t("subscription:planSelection.subscribe"),
      },
      {
        id: production
          ? "price_1JrUe0Ix0qrEhp6HGd4VJp5T"
          : "price_1JgmtpIx0qrEhp6HUjj23j8x",
        product: {
          name: "Starter 300",
          description: `300 ${t("subscription:planSelection.leadByMonth")}`,
        },
        unit_amount: 750,
        action: t("subscription:planSelection.subscribe"),
      },
    ],
    /* growth: [
      {
        id: production
          ? "price_1JrUdoIx0qrEhp6HyNGgXAWR"
          : "price_1JgmudIx0qrEhp6H5nHJwJCW",
        product: {
          name: "Growth 500",
          description: `500 ${t("subscription:planSelection.leadByMonth")}`,
        },
        unit_amount: 1000,
        action: t("subscription:planSelection.subscribe"),
      },
      {
        id: production
          ? "price_1JrUdjIx0qrEhp6HuvCfWNaK"
          : "price_1JgmvHIx0qrEhp6H0YCmYuOi",
        product: {
          name: "Growth 750",
          description: `750 ${t("subscription:planSelection.leadByMonth")}`,
        },
        unit_amount: 1350,
        action: t("subscription:planSelection.subscribe"),
      },
    ], */
  };

  const createCheckoutSession = httpsCallable<
    CoolsalesCallable.Stripe.CreateCheckoutSessionData,
    CoolsalesCallable.Stripe.CreateCheckoutSessionResponse
  >(functions, "stripe-createCheckoutSession");

  const createPortalBillingSession = httpsCallable<
    CoolsalesCallable.Stripe.CreateBillingPortalSessionData,
    CoolsalesCallable.Stripe.CreateBillingPortalSessionResponse
  >(functions, "stripe-createPortalBillingSession");

  const handlePlanSelected = async (priceId: string) => {
    const perfTrace = trace(perf, "Stripe Create Checkout Session");
    perfTrace.start();
    setIsLoadingCheckoutSession(true);
    const redirectUrl = appBaseUrl + url;
    createCheckoutSession({
      priceId,
      redirectUrl,
      client_reference_id: currentOrganisation?.id as string,
      customer_email: authState.user?.email as string | undefined,
      stripeId: currentOrganisation?.stripeId,
    })
      .then((response) => {
        pushNotification({
          severity: "info",
          title: t("subscription:subscriptionPage.redirectionToPaymentPage"),
        });
        logAnalyticsEvent("open_checkout_session");
        perfTrace.putAttribute("Status", "success");
        perfTrace.stop();
        window.location.replace(response.data.sessionUrl);
      })
      .catch((error) => {
        setIsLoadingCheckoutSession(false);
        pushNotification({
          severity: "error",
          title: t("common:errors.errorOccured"),
          body: error.message,
        });
        perfTrace.putAttribute("Status", "failed");
        perfTrace.stop();
      });
  };

  const handleOpenBillingPortal = () => {
    setisLoadingPortalSession(true);
    const return_url = appBaseUrl + url;
    if (currentOrganisation?.stripeId) {
      createPortalBillingSession({
        stripeId: currentOrganisation?.stripeId,
        return_url,
      })
        .then((response) => {
          pushNotification({
            severity: "info",
            title: t("subscription:subscriptionPage.redirectionToBillingPage"),
          });
          logAnalyticsEvent("open_billing_portal");
          window.location.replace(response.data.portalSessionUrl);
        })
        .catch((error) => {
          setisLoadingPortalSession(false);
          pushNotification({
            severity: "error",
            title: t("common:errors.errorOccured"),
            body: error.message,
          });
        });
    } else {
      setisLoadingPortalSession(false);
      pushNotification({
        severity: "warning",
        title: t("common:errors.errorOccured"),
        body: t("subscription:subscriptionPage.noStripeIdFound"),
      });
    }
  };

  useEffect(() => {
    if (sessionCanceled) {
      pushNotification({
        severity: "warning",
        title: t("subscription:subscriptionPage.paymentCanceled.title"),
        body: t("subscription:subscriptionPage.paymentCanceled.body"),
      });
      logAnalyticsEvent("checkout_session_canceled");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionCanceled]);

  useEffect(() => {
    if (sessionSuccess) {
      logAnalyticsEvent("checkout_session_success");
    }
  }, [sessionSuccess]);

  return (
    <SubscriptionPage
      currentOrganisation={currentOrganisation}
      sessionSuccess={sessionSuccess}
      isLoadingCurrentOrganisation={isLoadingCurrentOrganisation}
      planSelectionProps={{
        prices,
        handlePlanSelected,
        isLoadingCheckoutSession,
      }}
      isLoadingPortalSession={isLoadingPortalSession}
      handleOpenBillingPortal={handleOpenBillingPortal}
    />
  );
};
