import { FC } from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../utils";
import { UrlLink } from "../../ui/links";

type LeadInfoProps = Shared.ILead["companyData"];

export const LeadInfo: FC<LeadInfoProps> = ({
  url,
  linkedinUrl,
  foundedOn,
  employeeLinkedin,
  descriptionLinkedin,
}) => {
  const { t } = useTranslation(["common", "lead"]);
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <DescriptionListItem
            col="1"
            title={t("lead:leadPage.leadInfo.companyUrl")}
            children={url && <UrlLink to={url} />}
          />
          <DescriptionListItem
            col="1"
            title={t("lead:leadPage.leadInfo.linkedinUrl")}
            children={linkedinUrl && <UrlLink to={linkedinUrl} />}
          />
          <DescriptionListItem
            col="1"
            title={t("lead:leadPage.leadInfo.foundedOn")}
            children={foundedOn}
          />
          <DescriptionListItem
            col="1"
            title={t("lead:leadPage.leadInfo.numberOfEmployee")}
            children={employeeLinkedin}
          />
          <DescriptionListItem
            col="2"
            title={t("lead:leadPage.leadInfo.about")}
            children={descriptionLinkedin}
          />
        </dl>
      </div>
    </div>
  );
};

const DescriptionListItem: FC<{ title: string; col?: "1" | "2" }> = ({
  title,
  children,
  col = "2",
}) => {
  return (
    <div
      className={classNames(col === "1" ? "sm:col-span-1" : "sm:col-span-2")}
    >
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className="mt-1 text-sm text-gray-900">{children}</dd>
    </div>
  );
};
