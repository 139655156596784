import { FC } from "react";

export const UrlLink: FC<{ to: string; text?: string }> = ({ text, to }) => (
  <a
    href={to}
    target="_blank"
    rel="noreferrer"
    className="font-medium text-primary hover:text-primary-dark"
  >
    {text || to}
  </a>
);
