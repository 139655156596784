import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PageLayout } from "../ui/layout/PageLayout";
import {
  OrganisationInvitationsList,
  OrganisationInvitationsListProps,
} from "./components/OrganisationInvitationsList";
import {
  OrganisationUsersList,
  OrganisationUsersListProps,
} from "./components/OrganisationUsersList";
import {
  SubscriptionDetails,
  SubscriptionDetailsProps,
} from "./components/SubscriptionDetails";

type OrganisationSettingsPageProps = {
  organisationUsersListProps: OrganisationUsersListProps;
  organisationInvitationsListProps: OrganisationInvitationsListProps;
  subscriptionDetailsProps: SubscriptionDetailsProps;
};

export const OrganisationSettingsPage: FC<OrganisationSettingsPageProps> = ({
  organisationUsersListProps,
  organisationInvitationsListProps,
  subscriptionDetailsProps,
}) => {
  const { t } = useTranslation(["common", "organisation"]);
  return (
    <PageLayout title={t("organisation:organisationSettingsPage.title")}>
      <OrganisationUsersList {...organisationUsersListProps} />
      {organisationInvitationsListProps.pendingInvitations &&
        organisationInvitationsListProps.pendingInvitations.length > 0 && (
          <OrganisationInvitationsList {...organisationInvitationsListProps} />
        )}
      <SubscriptionDetails {...subscriptionDetailsProps} />
    </PageLayout>
  );
};
