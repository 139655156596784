import { FC, useState } from "react";
import { Controller, RegisterOptions, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { supportedLanguages } from "../config";
import { Button } from "../ui/buttons";
import { Spinner } from "../ui/icons";
import {
  TextField,
  UploadAvatar,
  CustomSelect,
  CustomSelectOption,
} from "../ui/inputs";
import { useCurrentUser } from "./userService";

export type UserDetailFormData = {
  displayName: string;
  photo?: File[];
  prefferedLanguage?: Shared.IAppLanguage;
};

export const UserDetails: FC = () => {
  const { t } = useTranslation(["common", "user"]);

  const {
    currentUser,
    isLoadingUser,
    updateUser,
    isLoadingUpdate,
    uploadPhoto,
    uploadingPhoto,
  } = useCurrentUser();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    control,
  } = useForm<UserDetailFormData>();

  const selectedFiles = watch("photo");

  const supportedLanguagesOptions: CustomSelectOption[] =
    supportedLanguages.map((language) => ({
      id: language,
      value: t(`common:supportedLanguages.${language}`),
    }));

  const [languageSelected, setLanguageSeleted] = useState(
    supportedLanguagesOptions.find(
      (supportedLanguage) =>
        supportedLanguage.id === currentUser?.prefferedLanguage
    ) || supportedLanguagesOptions[0]
  );

  const registerOptions: Record<keyof UserDetailFormData, RegisterOptions> = {
    displayName: {
      required: t("user:formValidation.displayNameRequired"),
      maxLength: {
        value: 60,
        message: t("common:formValidation.max60Characters"),
      },
    },
    photo: {
      validate: {
        onlyOneFile: (files) =>
          (files !== null && files.length <= 1) ||
          t("user:formValidation.profilePhotoOneFile"),
        maxSizeFile: (files) => {
          if (files === null || files.length === 0) {
            return true;
          } else {
            return (
              files[0].size <= 1048576 ||
              t("user:formValidation.profilePhotoMaxSize")
            );
          }
        },
      },
    },
    prefferedLanguage: {
      validate: {
        isSupportedLanguage: (value) =>
          supportedLanguages.includes(value) ||
          t("user:formValidation.unsuporttedLanguage"),
      },
    },
  };

  const onSubmit = handleSubmit((data) => {
    if (updateUser && uploadPhoto) {
      if (data.photo && data.photo.length > 0) {
        uploadPhoto(data.photo[0]);
        reset({ photo: undefined });
      }
      updateUser({
        displayName: data.displayName,
        prefferedLanguage: data.prefferedLanguage,
      });
    }
  });

  return (
    <section aria-labelledby="user-details">
      <form onSubmit={onSubmit} noValidate>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2
                id="payment-details-heading"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {t("user:UserSettingsPage.userDetails.title")}
              </h2>
              <p className="max-w-2xl text-sm text-gray-500">
                {t("user:UserSettingsPage.userDetails.description")}
              </p>
            </div>
            {isLoadingUser ? (
              <div className="my-10 place-content-center">
                <Spinner size={20} />
              </div>
            ) : (
              <div className="mt-6 grid grid-cols-4 gap-6">
                <TextField
                  className="col-span-4 sm:col-span-2"
                  type="text"
                  label={t("user:formLabel.displayName")}
                  name="displayName"
                  defaultValue={currentUser?.displayName}
                  register={register}
                  registerOptions={registerOptions.displayName}
                  error={errors.displayName?.message}
                />
                <div className="xs:hidden sm:invisible sm:col-span-1">
                  <p>&nbsp;</p>
                </div>
                <UploadAvatar
                  className="col-span-3 sm:col-span-1"
                  name="photo"
                  photoUrl={currentUser?.photoUrl}
                  register={register}
                  registerOptions={registerOptions.photo}
                  errors={errors.photo}
                  selectedFiles={selectedFiles}
                />
                <Controller
                  control={control}
                  name="prefferedLanguage"
                  defaultValue={currentUser?.prefferedLanguage}
                  render={({ field: { onChange } }) => {
                    return (
                      <CustomSelect
                        className="col-span3 sm:col-span-2"
                        label={t("user:formLabel.preferedLanguage")}
                        options={supportedLanguagesOptions}
                        selected={languageSelected}
                        onChange={(option) => {
                          onChange(option.id);
                          setLanguageSeleted(option);
                        }}
                      />
                    );
                  }}
                />
              </div>
            )}
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <Button
              type="submit"
              size="sm"
              isLoading={isLoadingUser || uploadingPhoto || isLoadingUpdate}
              children={t("common:action.save")}
            />
          </div>
        </div>
      </form>
    </section>
  );
};
