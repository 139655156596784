import { KeyIcon, UserRemoveIcon } from "@heroicons/react/outline";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { hasAccessFrom } from "../../../utils";
import { Button } from "../../ui/buttons";
import { Spinner, UserAvatar } from "../../ui/icons";

export type OrganisationUsersListProps = {
  currentUserRole: Shared.IUserRoles;
  currentUserId?: string;
  pathToInviteUserModal: string;
  isLoadingUsersList: boolean;
  usersList?: Shared.IOrganisation["users"];
  pathToEditUserRoleModal: string;
  pathToRemoveUserModal: string;
};

export const OrganisationUsersList: FC<OrganisationUsersListProps> = ({
  currentUserRole,
  currentUserId,
  pathToInviteUserModal,
  isLoadingUsersList,
  usersList,
  pathToEditUserRoleModal,
  pathToRemoveUserModal,
}) => {
  const { t } = useTranslation(["common", "organisation"]);

  return (
    <section aria-labelledby="user-details">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 sm:p-6">
          <div>
            <h2
              id="payment-details-heading"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              {t("organisation:usersList.title")}
            </h2>
            <p className="max-w-2xl text-sm text-gray-500">
              {t("organisation:usersList.description")}
            </p>
          </div>
          {isLoadingUsersList ? (
            <div className="my-10 place-content-center">
              <Spinner size={20} />
            </div>
          ) : (
            <ul className="mt-2 sm:mt-4 divide-y divide-gray-200">
              {usersList &&
                Object.entries(usersList).map(([userId, user]) => (
                  <li key={userId} className="py-4">
                    <div className="flex items-center justify-between space-x-4">
                      <div className="flex-shrink-0">
                        <UserAvatar
                          size="sm"
                          displayName={user.displayName}
                          photoUrl={user.photoUrl}
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          {user.displayName || user.email}
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                          {t(`common:userRole.${user.role || "VIEWER"}`)}
                        </p>
                      </div>
                      {hasAccessFrom(currentUserRole, "ADMIN") &&
                      currentUserId !== userId ? (
                        <div className="flex flex-row space-x-2 sm:space-x-4">
                          <div>
                            <Link
                              to={{
                                pathname: `${pathToEditUserRoleModal}/${userId}`,
                                state: { modal: true },
                              }}
                              children={
                                <KeyIcon className="h-5 sm:h-7 text-gray-600" />
                              }
                            />
                          </div>
                          <div>
                            <Link
                              to={{
                                pathname: `${pathToRemoveUserModal}/${userId}`,
                                state: { modal: true },
                              }}
                              children={
                                <UserRemoveIcon className="h-5 sm:h-7 text-red-700" />
                              }
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </li>
                ))}
            </ul>
          )}
        </div>
        {hasAccessFrom(currentUserRole, "ADMIN") && (
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <Link
              to={{
                pathname: pathToInviteUserModal,
                state: { modal: true },
              }}
              children={
                <Button
                  type="button"
                  variant="textPrimary"
                  size="xs"
                  children={t("organisation:inviteUserModal.action")}
                />
              }
            />
          </div>
        )}
      </div>
    </section>
  );
};
