import { FC } from "react";
import { useForm, RegisterOptions } from "react-hook-form";
import { ResetPasswordPage } from "./components/ResetPasswordPage";
import { useAuth } from "./AuthProvider";
import { useTranslation } from "react-i18next";
import { AuthErrorCodes } from "firebase/auth";

export type ResetPasswordFormData = {
  email: string;
};

export const ResetPassword: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    setError,
  } = useForm<ResetPasswordFormData>();

  const { t } = useTranslation("authentification");

  const { sendPasswordResetEmail } = useAuth();

  const registerOptions: Record<keyof ResetPasswordFormData, RegisterOptions> =
    {
      email: {
        required: t("fromValidation.emailRequired"),
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: t("fromValidation.emailPattern"),
        },
      },
    };

  const onSubmit = handleSubmit((data) => {
    sendPasswordResetEmail(data.email)
      .then(() => {
        // TODO : snackbar
      })
      .catch((e) => {
        console.error(e);
        switch (e.message) {
          case AuthErrorCodes.USER_DELETED:
            setError("email", {
              type: "manual",
              message: t("errors.auth/USER_DELETED"),
            });
            break;

          case AuthErrorCodes.USER_DISABLED:
            setError("email", {
              type: "manual",
              message: t("errors.auth/USER_DISABLED"),
            });
            break;
        }
      });
  });

  return (
    <ResetPasswordPage
      register={register}
      registerOptions={registerOptions}
      onSubmit={onSubmit}
      errors={errors}
      isSubmitSuccessful={isSubmitSuccessful}
    />
  );
};
