import { FC } from "react";
import {
  useRouteMatch,
  useLocation,
  useHistory,
  Switch,
  Route,
} from "react-router";
import {
  OfficeBuildingIcon,
  CurrencyDollarIcon,
  BriefcaseIcon,
  TerminalIcon,
} from "@heroicons/react/outline";
import { ChooseWorkflowPage } from "./components/ChooseWorkflowPage";
import { WorkflowsPage } from "./WorkflowsPage";
import { JobboardWorkflow } from "./jobboard/JobboadWorkflow";
import {
  useAddWorkflow,
  useSoftDeleteWorkflow,
  useUpdateWorkflow,
  useWorkflowsSnapshot,
} from ".";
import { useOverlay } from "../overlay";
import { workflowItem } from "./components/WorkflowsList";
import { useTranslation } from "react-i18next";
import { ConfirmationModal } from "../ui/overlay/ConfirmationModal";
import { useAuth } from "../authentification";
import { currentEnvironment } from "../config";
import { useCurrentOrganisation } from "../organisation";

export const Workflows: FC = () => {
  // Routing
  let {
    path,
    url,
    params: { organisationId },
  } = useRouteMatch<{ organisationId: string }>();
  let location = useLocation<{ modal: boolean }>();
  const history = useHistory();
  let displayModal = (location.state && location.state.modal) || false;
  const onClose = () => history.push(url);

  // Auth
  const {
    authState: { currentRole },
  } = useAuth();

  // UI
  const { pushNotification } = useOverlay();
  const { t } = useTranslation(["common", "workflow"]);

  // Organisation
  const { currentOrganisation } = useCurrentOrganisation();

  // Workflow
  const { add } = useAddWorkflow(organisationId)();
  const { list: workflows, isLoading: isLoadingList } =
    useWorkflowsSnapshot(organisationId)();
  const { update, isLoading: isLoadingUpdate } =
    useUpdateWorkflow(organisationId)();
  const { softDelete, isLoading: isLoadingDelete } =
    useSoftDeleteWorkflow(organisationId)();

  const hasLinkedinIntegration =
    (currentOrganisation &&
      currentOrganisation.linkedinIntegrations &&
      Object.keys(currentOrganisation.linkedinIntegrations).length > 0) ||
    false;
  const canAddWorkflow = currentOrganisation?.product?.limited_to_one_workflow
    ? workflows.length < 1 && hasLinkedinIntegration
    : hasLinkedinIntegration;

  // Create a worklfow list upated when workflows are loaded
  const workflowsList: workflowItem[] = [];
  if (workflows) {
    workflows.forEach((workflow) => {
      workflowsList.push({
        id: workflow.id as string,
        pathToWorkflowEdit: `${url}/${workflow.id}`,
        name:
          workflow.name ||
          t(
            `workflow:workflowList.jobboardNameInProgress.${
              workflow.type || "jobboard"
            }`
          ),
        enabled: workflow.enabled,
        error: workflow.error,
        stats: [
          {
            name: t("workflow:workflowList.lastRun"),
            stat:
              workflow.metrics && workflow.metrics.lastRun
                ? workflow.metrics.lastRun.toString()
                : "0",
          },
          {
            name: t("workflow:workflowList.lastWeek"),
            stat:
              workflow.metrics && workflow.metrics.lastWeek
                ? workflow.metrics.lastWeek.toString()
                : "0",
          },
          {
            name: t("workflow:workflowList.totalLeads"),
            stat:
              workflow.metrics && workflow.metrics.total
                ? workflow.metrics.total.toString()
                : "0",
          },
        ],
        toggleEnabled: (value: boolean) =>
          update(workflow.id as string, {
            enabled: value,
          })
            .then(() =>
              pushNotification({
                severity: "success",
                title: value
                  ? t("workflow:workflowList.enabledSuccess")
                  : t("workflow:workflowList.disabledSuccess"),
              })
            )
            .catch((error) =>
              pushNotification({
                severity: "error",
                title: "An error occured",
                body: currentEnvironment !== "production" && error,
              })
            ),
        deleteWorkflow: () =>
          history.push(`${url}/deleteWorkflow/${workflow.id}`, {
            modal: true,
          }),
      });
    });
  }

  // handle create workflow request
  const createWorkflow = (
    workflowType: "jobboard" | "fundraising" | "registration" | "saas"
  ) => {
    switch (workflowType) {
      case "jobboard":
        add({
          type: "jobboard",
        }).then((workflowId) => {
          if (workflowId) {
            history.push(`${url}/${workflowId}`);
          } else {
            pushNotification({ severity: "error", title: "An error occured" });
          }
        });
        break;

      default:
        history.push(url);
        break;
    }
  };

  // Genereate the workflow choices list
  const workflowsChoice = getWorkflowsChoices(createWorkflow, {
    jobboard: {
      title: t("workflow:chooseWorkflowPage.jobboard.title"),
      description: t("workflow:chooseWorkflowPage.jobboard.description"),
    },
    fundraising: {
      title: t("workflow:chooseWorkflowPage.fundraising.title"),
      description: t("workflow:chooseWorkflowPage.fundraising.description"),
    },
    registration: {
      title: t("workflow:chooseWorkflowPage.registration.title"),
      description: t("workflow:chooseWorkflowPage.registration.description"),
    },
    saas: {
      title: t("workflow:chooseWorkflowPage.saas.title"),
      description: t("workflow:chooseWorkflowPage.saas.description"),
    },
  });

  // Handle delete workflow confirmation
  const onDeleteWorkflowConfirmation = (params: Record<string, string>) => {
    onClose();
    if (params.workflowId) {
      softDelete(params.workflowId as string).then(() =>
        pushNotification({
          severity: "success",
          title: t("workflow:workflowList.deletedSuccess"),
        })
      );
    } else {
      pushNotification({
        severity: "error",
        title: t("workflow:workflowsPage.deleteWorkflowModal.noWorkflowId"),
      });
    }
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}/chooseWorkflow`}>
          <ChooseWorkflowPage workflowsChoice={workflowsChoice} />
        </Route>
        <Route exact path={`${path}/:workflowId`}>
          <JobboardWorkflow />
        </Route>
        <Route path="*">
          {/* Path must no be exact to display modal from the next switch. This route must be the last */}
          <WorkflowsPage
            currentUserRole={currentRole || "VIEWER"}
            canAddWorkflow={canAddWorkflow}
            hasLinkedinIntegration={hasLinkedinIntegration}
            pathToChooseWorflow={`${url}/chooseWorkflow`}
            workflowListProps={{
              currentUserRole: currentRole || "VIEWER",
              isLoading: isLoadingList || isLoadingUpdate || isLoadingDelete,
              workflows: workflowsList.sort((wkPrev, wkNext) =>
                wkPrev.id > wkNext.id ? 1 : -1
              ),
            }}
          />
        </Route>
      </Switch>
      <Switch>
        <Route
          path={`${path}/deleteWorkflow/:workflowId`}
          children={
            <ConfirmationModal
              show={displayModal}
              title={t("workflow:workflowsPage.deleteWorkflowModal.title")}
              body={t("workflow:workflowsPage.deleteWorkflowModal.body")}
              action={t("workflow:workflowsPage.deleteWorkflowModal.action")}
              actionType="red"
              onConfirmation={onDeleteWorkflowConfirmation}
              onClose={onClose}
            />
          }
        />
      </Switch>
    </>
  );
};

/**
 * List of workflow choices
 * @remarks
 * By not providing on onClick key to a workflow it will be disable in the chooseWorkflow page
 * @param createWorkflow A method to handle create workflow request
 * @returns
 */
const getWorkflowsChoices = (
  createWorkflow: (
    workflowType: "jobboard" | "fundraising" | "registration" | "saas"
  ) => void,
  translation: {
    jobboard: {
      title: string;
      description: string;
    };
    fundraising: {
      title: string;
      description: string;
    };
    registration: {
      title: string;
      description: string;
    };
    saas: {
      title: string;
      description: string;
    };
  }
) => {
  return [
    {
      ...translation.jobboard,
      onClick: () => createWorkflow("jobboard"),
      Icon: BriefcaseIcon,
      iconForeground: "text-blue-700",
      iconBackground: "bg-blue-50",
    },
    {
      ...translation.fundraising,
      // onClick: () => createWorkflow("fundraising"),
      Icon: CurrencyDollarIcon,
      iconForeground: "text-gren-700",
      iconBackground: "bg-green-50",
    },
    {
      ...translation.registration,
      // onClick: () => createWorkflow("registration"),
      Icon: OfficeBuildingIcon,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
    },
    {
      ...translation.saas,
      // onClick: () => createWorkflow("saas"),
      Icon: TerminalIcon,
      iconForeground: "text-gray-700",
      iconBackground: "bg-gray-50",
    },
  ];
};
