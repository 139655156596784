import { FC } from "react";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import {
  InformationCircleIcon,
  CheckCircleIcon,
  ExclamationIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import type { NotificationProps as NotificationBase } from "../../overlay/OverlayProvider";

export type NotificationProps = {
  show: boolean;
  onClose: () => void;
} & NotificationBase;

export const Notification: FC<NotificationProps> = ({
  show,
  onClose,
  severity = "info",
  title,
  body,
  action,
}) => {
  const getIcon = () => {
    switch (severity) {
      case "error":
        return (
          <ExclamationIcon
            className="h-6 w-6 text-red-400"
            aria-hidden="true"
          />
        );

      case "warning":
        return (
          <XCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
        );

      case "success":
        return (
          <CheckCircleIcon
            className="h-6 w-6 text-green-400"
            aria-hidden="true"
          />
        );

      case "info":
      default:
        return (
          <InformationCircleIcon
            className="h-6 w-6 text-blue-400"
            aria-hidden="true"
          />
        );
    }
  };

  if (!show) return null;
  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">{getIcon()}</div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {title && (
                <p className="text-sm font-medium text-gray-900">{title}</p>
              )}
              {body && <p className="mt-1 text-sm text-gray-500">{body}</p>}
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
