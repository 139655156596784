import { FC } from "react";
import { useRouteMatch, Switch, Route } from "react-router";
import { Lead } from "./Lead";
import { LeadsPage } from "./LeadsPage";

export const Leads: FC = () => {
  let { path } = useRouteMatch<{ organisationId: string }>();

  return (
    <Switch>
      <Route exact path={`${path}/:leadId`}>
        <Lead />
      </Route>
      <Route exact path={path}>
        <LeadsPage />
      </Route>
    </Switch>
  );
};
