import { FC } from "react";
import { useRouteMatch } from "react-router";
import { useListLeads } from "../lead/LeadService";
import { StatsWithIconProps } from "../ui/stats";
import { DashboardPage } from "./DashboardPage";
import { OfficeBuildingIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

export const Dashboard: FC = () => {
  // Routing
  let {
    url,
    params: { organisationId },
  } = useRouteMatch<{ organisationId: string }>();

  //UX
  const { t } = useTranslation(["common", "dashboard"]);

  // Leads
  const { data: leads, isLoading: isLoadingLeads } =
    useListLeads(organisationId)();

  const sixtyDaysAgo = new Date();
  sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
  const thirthyDaysAgo = new Date();
  thirthyDaysAgo.setDate(thirthyDaysAgo.getDate() - 30);
  const twoDaysAgo = new Date();
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 30);

  const leadsLastSixtyDays = leads
    ? leads.filter((lead) =>
        lead.createdAt ? lead.createdAt?.toDate() < sixtyDaysAgo : false
      ).length
    : 0;

  const leadsLastThirdayDays = leads
    ? leads.filter((lead) =>
        lead.createdAt ? lead.createdAt?.toDate() < thirthyDaysAgo : false
      ).length
    : 0;

  const leadsTwoDaysAgo = leads
    ? leads.filter((lead) =>
        lead.createdAt ? lead.createdAt?.toDate() < twoDaysAgo : false
      ).length
    : 0;

  const leadsYesterday = leads
    ? leads.filter((lead) =>
        lead.createdAt ? lead.createdAt?.toDate() < yesterday : false
      ).length
    : 0;

  const stats: StatsWithIconProps[] = [
    {
      name: t("dashboard:dashboardPage.totalLead"),
      stat: leads ? leads.length.toString() : "0",
      loading: isLoadingLeads,
      Icon: OfficeBuildingIcon,
      linkPath: url.replace("dashboard", "leads"),
      linkText: "View All",
    },
    {
      name: t("dashboard:dashboardPage.lead30days"),
      stat: leadsLastThirdayDays.toString(),
      Icon: OfficeBuildingIcon,
      change:
        leadsLastSixtyDays - leadsLastThirdayDays > 0
          ? Math.round(
              leadsLastThirdayDays / (leadsLastSixtyDays - leadsLastThirdayDays)
            ).toString()
          : undefined,
      changeType:
        leadsLastThirdayDays > leadsLastSixtyDays - leadsLastThirdayDays
          ? "increase"
          : "decrease",
      linkPath: "/leads",
      linkText: "View All",
    },
    {
      name: t("dashboard:dashboardPage.yesterday"),
      stat: leadsYesterday.toString(),
      Icon: OfficeBuildingIcon,
      change:
        leadsTwoDaysAgo - leadsYesterday > 0
          ? Math.round(
              leadsYesterday / (leadsTwoDaysAgo - leadsYesterday)
            ).toString()
          : undefined,
      changeType:
        leadsYesterday > leadsTwoDaysAgo - leadsYesterday
          ? "increase"
          : "decrease",
      linkPath: "/leads",
      linkText: "View All",
    },
  ];

  return <DashboardPage stats={stats} />;
};
